import React, { useState, useEffect } from 'react';
import axios from 'axios';
import imageCompression from 'browser-image-compression'; // Import imageCompression

const AddOfficeForm = ({ onClose, onUpdate }) => {
    const [formData, setFormData] = useState({
        officeName: '',
        personName1: '',
        personName2: '',
        address: '',
        country: 'IN',
        city: "",
        state: "",
        pincode: '',
        email1: '',
        email2: '',
        mobile1: '',
        mobile2: '',
        mobile3: '',
        picture: null,
        remarks: '',
        ename: '',
        employee_id: '',
        username:localStorage.getItem('username'),
    });
    const [errors, setErrors] = useState({});
    const [employees, setEmployees] = useState([]);
    const [states, setStates] = useState([])


    useEffect(() => {
        // Fetch employees data from API or other source
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
                setEmployees(response.data);
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };
        const fetchStates = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/states`);
                setStates(response.data);
            } catch (error) {
                console.error("Error fetching inwards:", error);
            }
        };

        fetchEmployees();
        fetchStates();
    }, []);

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        try {
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);

            // Create a new File object with the compressed file and original file name
            const fileWithMetaData = new File([compressedFile], file.name, { type: compressedFile.type });

            setFormData({
                ...formData,
                picture: fileWithMetaData, // Set the compressed file with metadata
            });
        } catch (error) {
            console.error('Image compression error:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "ename") {
            const selectedEmployee = employees.find(employee => employee.ename === value);
            setFormData({
                ...formData,
                [name]: value,
                employee_id: selectedEmployee ? selectedEmployee.id : "",
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const validate = () => {
        let formErrors = {};
        if (!formData.officeName) formErrors.officeName = 'Office Name is required';
        if (!formData.address) formErrors.address = 'Adddress is required';
        if (!formData.city) formErrors.city = 'city is required';
        if (!formData.state) formErrors.state = 'State is required';
        if (!formData.email1) formErrors.email1 = 'Email Id is required';
        if (!formData.mobile1) formErrors.mobile1 = 'Mobile is required';
        if (!formData.ename) formErrors.ename = 'Employee Name  is required';

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        if (!validate()) {
            return;
        }

        // Prepare form data for submission
        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => {
            formDataToSend.append(key, formData[key]);
        });

        try {
            // Send form data to the server
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/officeData`, formDataToSend);
            console.log('Data uploaded successfully:', response.data);
            onClose();
            onUpdate();
        } catch (error) {
            console.error('Error uploading data:', error);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="addOffice" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off" noValidate>
                        <div className="modal-header">
                            <h5 className="modal-title">Add Office</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            <div>
                                <div className="form-group">
                                    <label>Office Name<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="officeName" type="text"
                                        className={`form-control ${errors.officeName ? 'is-invalid' : ''}`}
                                        required
                                        placeholder="Office Name"
                                        onChange={handleChange} />
                                    {errors.officeName && <small className="text-danger">{errors.officeName}</small>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="ename">Person Name <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="ename"
                                        id="ename"
                                        className={`form-control ${errors.ename ? 'is-invalid' : ''}`}
                                        value={formData.ename}
                                        onChange={handleChange}
                                        required
                                    >

                                        <option value="" disabled hidden>Select Employee</option>
                                        {employees.map((employee) => (
                                            <option key={employee.id} value={employee.ename}>
                                                {employee.ename}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.ename && <small className="text-danger">{errors.ename}</small>}
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label>Mobile 1<span style={{ color: "red" }}>*</span></label>
                                        <input name="mobile1" type="text"
                                            className={`form-control ${errors.mobile1 ? 'is-invalid' : ''}`}
                                            required placeholder="Mobile 1" onChange={handleChange} />
                                        {errors.mobile1 && <small className="text-danger">{errors.mobile1}</small>}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Mobile 2</label>
                                        <input name="mobile2" type="text"
                                            className="form-control"
                                            placeholder="Mobile 2" onChange={handleChange} />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Mobile 3</label>
                                        <input name="mobile3" type="text" className="form-control" placeholder="Mobile 3" onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Email 1<span style={{ color: "red" }}>*</span></label>
                                        <input name="email1" type="email"
                                            className={`form-control ${errors.email1 ? 'is-invalid' : ''}`}
                                            required placeholder="Email 1" onChange={handleChange} />
                                        {errors.email1 && <small className="text-danger">{errors.email1}</small>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Email 2</label>
                                        <input name="email2" type="email" className="form-control" placeholder="Email 2" onChange={handleChange} />
                                    </div>
                                </div>
                                <label htmlFor="">Address Details : -</label>
                                <div className="form-group">
                                    <label>Address<span style={{ color: "red" }}>*</span></label>
                                    <input name="address" type="text"
                                        className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                        required placeholder="Address" onChange={handleChange} />
                                    {errors.address && <small className="text-danger">{errors.address}</small>}
                                </div>
                                <div className='form-row'>
                                    <div className="form-group col-md-4">
                                        <label>State<span style={{ color: "red" }}>*</span></label>
                                        <select
                                            name="state"
                                            id="state"
                                            className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                                            value={formData.state}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" disabled hidden>Select State</option>
                                            {states.map(state => (
                                                <option key={state.id} value={`${state.statename}`}>{state.statename}</option>
                                            ))}
                                        </select>
                                        {errors.state && <small className="text-danger">{errors.state}</small>}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>City<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="city"
                                            type="text"
                                            className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                            value={formData.city}
                                            onChange={handleChange}
                                            required
                                            placeholder="City"
                                        />
                                        {errors.city && <small className="text-danger">{errors.city}</small>}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Pincode</label>
                                        <input name="pincode" type="text" className="form-control" required placeholder="Pincode" onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Remarks </label>
                                    <textarea name="remarks" className="form-control" placeholder="Remarks" onChange={handleChange}></textarea>
                                </div>
                                <div className="form-group">
                                    <label>Upload Picture</label>
                                    <input name="picture" type="file" className="form-control" required onChange={handleImageChange} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Save</button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddOfficeForm;
