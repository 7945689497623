import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Dashboard.css';
import Sidebar from '../components/sidebar/Sidebar';
import SearchBar from '../components/sidebar/SearchBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddInward from './Inword/AddInward';
import AddOutward from './Outward/AddOutWard';

function Dashboard({ handleLogout, username }) {
    // for Dashboard 
    const [clients, setClients] = useState(0);
    const [vendors, setvendors] = useState(0);
    const [inward, setInward] = useState(0);
    const [outward, setOutward] = useState(0);
    // services /Products
    const [services, setServices] = useState([]);
    const [products, setProducts] = useState([]);
    // addinward modal 
    const [isAddInwardModalopen, setIsAddInwardModalopen] = useState(false);
    // add outwardModal 
    const [isAddOutwardModalOpen, setIsAddOutwardModalOpen] = useState(false);

    useEffect(() => {
        fetchClients();
        fetchVendors();
        fetchInwards();
        fetchOutwards();
        fetchServices();
        fetchProducts();
    }, []);
    const fetchClients = async () => {
        try {
            const clientResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/clients`);
            console.log(clientResponse.data);
            if (Array.isArray(clientResponse.data)) {
                setClients(clientResponse.data.length);
            } else {
                console.error('Invalid response format for total asset count');
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };
    const fetchVendors = async () => {
        try {
            const vendorResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendors`);
            console.log(vendorResponse.data);
            if (Array.isArray(vendorResponse.data)) {
                setvendors(vendorResponse.data.length);
            } else {
                console.error('Invalid response format for total asset count');
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };
    const fetchInwards = async () => {
        try {
            const inwardResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/inwards`);
            console.log(inwardResponse.data);
            if (Array.isArray(inwardResponse.data)) {
                setInward(inwardResponse.data.length);
            } else {
                console.error('Invalid response format for total asset count');
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };
    const fetchOutwards = async () => {
        try {
            const inwardResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/outwards`);
            console.log(inwardResponse.data);
            if (Array.isArray(inwardResponse.data)) {
                setOutward(inwardResponse.data.length);
            } else {
                console.error('Invalid response format for total asset count');
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const fetchServices = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/service_product`);
            const services = response.data.filter(item => item.type === "Service");
            setServices(services);
        } catch (error) {
            console.error('Error fetching Services:', error);
        }
    };
    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/service_product`);
            const Product = response.data.filter(item => item.type === "Product");
            setProducts(Product);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };


    // Add Inward Modal 
    const handleAddInward = () => {
        setIsAddInwardModalopen(true);
    };

    const handleCloseInwardModal = () => {
        setIsAddInwardModalopen(false);
    };

    // Out Ward Modal 
    const handleAddOutWardModal = () => {
        setIsAddOutwardModalOpen(true);
    };

    const handleCloseOutWardModal = () => {
        setIsAddOutwardModalOpen(false);
    };

    const handleUpdate = () => {
        toast.success("successfully uploaded");
    }

    return (

        <div className='d-flex w-100 h-100 '>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} /> {/* Pass username and handleLogout props */}
                <div className="container-fluid">
                    <ToastContainer />
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                        <div className='d-flex gap-4'>
                            <a onClick={handleAddInward} className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">Add Inward <i
                                className="fa fa-arrow-left"></i> </a>
                            <a onClick={handleAddOutWardModal} className="d-none d-sm-inline-block btn btn-sm btn-danger shadow-sm">Add outward <i
                                className="fa fa-arrow-right"></i> </a>
                        </div>

                    </div>
                    {/*  <!-- Content Row --> */}
                    <div className="row">
                        {/*  <!-- Total Clients Card Example --> */}
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card  border-left-success shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                Total Clients</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{clients}</div> {/* Updated amount */}
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-users fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col-md-12">
                                            <hr className="my-2" /> {/* Line added here */}
                                            <div className="stats">
                                                <i className="fa fa-angle-double-right text-success"></i>
                                                <span><Link className="text-success" to="/clientlist">More info</Link></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*  <!-- Total Vendors Card Example --> */}
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                Total Vendors</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{vendors}</div> {/* Updated amount */}
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-users fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col-md-12">
                                            <hr className="my-2" /> {/* Line added here */}
                                            <div className="stats">
                                                <i className="fa fa-angle-double-right text-success"></i>
                                                <span><Link className="text-success" to="/vendorlist">More info</Link></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*  <!-- Total Inward Card Example --> */}
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                Total Inward Post</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{inward}</div> {/* Updated amount */}
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa fa-arrow-left fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col-md-12">
                                            <hr className="my-2" /> {/* Line added here */}
                                            <div className="stats">
                                                <i className="fa fa-angle-double-right text-primary"></i>
                                                <span><Link className="text-primary" to="/inwardlist">More info</Link></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*  <!-- Total outward Card Example --> */}
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-danger shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-danger text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                Total outward Post
                                            </div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{outward}</div> {/* Updated amount */}
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa fa-arrow-right fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                    <div className="row no-gutters align-items-center">
                                        <div className="col-md-12">
                                            <hr className="my-2" /> {/* Line added here */}
                                            <div className="stats">
                                                <i className="fa fa-angle-double-right text-danger"></i>
                                                <span><Link className="text-danger" to="/outwardlist">More info</Link></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*  <!-- Content Row --> */}
                    <div className="row mt-5 ">
                        {/*  <!-- Total Clients Card Example --> */}
                        <div className="col-xl-6 col-md-6 mb-4">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="table-responsive d-flex">
                                        <table className="table table-bordered" style={{ width: "100%", tableLayout: "fixed", border: "1px solid #ECECEC" }}>
                                            <thead>
                                                <tr>
                                                    <th className='text-primary'>Total Services - {services.length}</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ maxHeight: "110px", overflowY: "auto", overflowX: "hidden", display: "block", background: "green" }}>
                                                {services.length > 0 ? (
                                                    services.map(service => (
                                                        <tr key={service.id}>
                                                            <td style={{ width: "100vw" }}>{service.name}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td style={{ width: "100vw" }} colSpan="1">No services ..</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*  <!-- Total outward Card Example --> */}
                        <div className="col-xl-6 col-md-6 mb-4">
                            <div className="card border-left-danger shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="table-responsive d-flex">
                                        <table className="table table-bordered" style={{ width: "100%", tableLayout: "fixed", border: "1px solid #ECECEC" }}>
                                            <thead>
                                                <tr>
                                                    <th className='text-danger '>Total Products - {products.length}</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ maxHeight: "110px", overflowY: "auto", overflowX: "hidden", display: "block", background: "green" }}>
                                                {products.length > 0 ? (
                                                    products.map(product => (
                                                        <tr key={product.id}>
                                                            <td style={{ width: "100vw" }}>{product.name}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td style={{ width: "100vw" }} colSpan="1">No products ..</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isAddInwardModalopen && <AddInward onClose={handleCloseInwardModal} onUpdate={handleUpdate} />}
            {isAddOutwardModalOpen && <AddOutward onClose={handleCloseOutWardModal} onUpdate={handleUpdate} />}
        </div>
    )
}

export default Dashboard;



