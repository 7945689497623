import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from 'react-select'; // Import Select component
import imageCompression from 'browser-image-compression';

const AddVendor = ({ onClose, onUpdate }) => {
    const [formData, setFormData] = useState({
        vendorName: "",
        vendorCode: "",
        gstNo: "",
        panNo: "",
        contactPersonName1: "",
        contactPersonName2: "",
        mobileNo1: "",
        mobileNo2: "",
        mobileNo3: "",
        emailId1: "",
        emailId2: "",
        address: "",
        country: 'IN',
        city: '',
        state: '',
        pincode: "",
        isSupplier: false,
        selectedServices: [], // Now stores objects {id, name} instead of just names username
        remark: "",
        username:localStorage.getItem('username'),
        upload1: null,
        upload2: null,
        upload3: null,
        upload4: null,
        upload5: null,
    });

    const [services, setServices] = useState([]);
    const [errors, setErrors] = useState({});
    const [gstWarning, setGstWarning] = useState("");
    const [panWarning, setPanWarning] = useState("");
    const [states, setStates] = useState([])
    const [mobileWarning, setMobileWarning] = useState({ mobileNo1: "", mobileNo2: "", mobileNo3: "" });

    const [lastVendorId, setLastVendorId] = useState(0)



    useEffect(() => {
        fetchServices();
        fetchLastVendorId();
        fetchStates();
    }, []);

    const fetchServices = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/service_product`);
            const services = response.data;
            setServices(services);
        } catch (error) {
            console.error('Error fetching Services:', error);
        }
    };

    const fetchLastVendorId = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/lastVendorId`);
            setLastVendorId(parseInt(response.data.lastVendorId));
        } catch (error) {
            console.error('Error fetching last Client ID:', error);
        }
    };

    const fetchStates = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/states`);
            setStates(response.data);
        } catch (error) {
            console.error("Error fetching inwards:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "gstNo") {
            setGstWarning(value.length !== 15 ? "GST number must be 15 digits" : "");
        }

        if (name === "panNo") {
            setPanWarning(value.length !== 10 ? "PAN number must be 10 digits" : "");
        }

        if (name.startsWith("mobileNo")) {
            setMobileWarning({
                ...mobileWarning,
                [name]: value.length !== 10 ? "Mobile number must be 10 digits" : ""
            });
        }

        const currentYear = new Date().getFullYear();
        console.log(lastVendorId)
        const generatedCode = `VENDOR${currentYear}${(lastVendorId + 1).toString().padStart(3, '0')}`;
        console.log(generatedCode)
        setFormData({
            ...formData,
            [name]: value,
            vendorCode: generatedCode,
        });
    };

    const handleImageChange = async (e, uploadField) => {
        const file = e.target.files[0];
        try {
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);

            // Create a new File object with the compressed file and original file name
            const fileWithMetaData = new File([compressedFile], file.name, { type: compressedFile.type });

            // Update the state using computed property names
            setFormData(prevState => ({
                ...prevState,
                [uploadField]: fileWithMetaData, // Set the compressed file with metadata for the specific upload field
            }));
        } catch (error) {
            console.error('Image compression error:', error);
        }
    };


    const handleSupplierChange = (selectedOptions) => {
        setFormData({
            ...formData,
            selectedServices: selectedOptions.map(option => ({ id: option.value, name: option.label })),
        });
    };

    // check error 
    const validate = () => {
        let formErrors = {};
        if (!formData.vendorName) formErrors.vendorName = 'Vendor Name is required';
        if (!formData.vendorCode) formErrors.vendorCode = 'Vendor Code is required';
        if (!formData.contactPersonName1) formErrors.contactPersonName1 = 'Contact Person Name is required';
        if (!formData.mobileNo1) formErrors.mobileNo1 = 'Mobile No. 1 is required';
        if (!formData.emailId1) formErrors.emailId1 = 'Email Id 1 is required';
        if (!formData.address) formErrors.address = 'Address is required';
        if (!formData.state) formErrors.state = 'State is required';
        if (!formData.city) formErrors.city = 'City is required';
        if (!formData.selectedServices || formData.selectedServices.length === 0) {
            formErrors.selectedServices = 'Supplier of Service/Product is required';
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        console.log("formData", formData);

        try {
            const formDataToSend = new FormData();

            // Append vendor data fields
            for (const key in formData) {
                if (formData.hasOwnProperty(key)) {
                    if (key.startsWith("upload") && formData[key]) {
                        // If it's an upload field and not null, append the file to the FormData
                        formDataToSend.append(key, formData[key]);
                    } else if (key === "selectedServices") {
                        // Format selected services array and append to FormData as JSON string
                        formDataToSend.append(key, JSON.stringify(formData[key]));
                    } else {
                        // Otherwise, append the data as usual
                        formDataToSend.append(key, formData[key]);
                    }
                }
            }
            console.log("formData", formData);
            console.log("formDataToSend", formDataToSend);

            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/vendordata`, formDataToSend);
            console.log("Data uploaded successfully:", response.data);

            onClose();
            onUpdate();
        } catch (error) {
            console.error("Error uploading data:", error);
        }
    };



    const handleClose = () => {
        onClose();
    };

    return (
        <div
            id="addVendor"
            className="modal fade show"
            role="dialog"
            style={{ display: "block", paddingRight: "17px" }}
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form
                        action=""
                        id="formAddVendor"
                        autoComplete="off"
                        noValidate="novalidate"
                        onSubmit={handleSubmit}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Add Vendor</h5>
                            <button type="button" className="close" onClick={handleClose}>
                                &times;
                            </button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            <div className="row">
                                <div className="col-md-12">
                                    {/* Vendor details */}
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Vendor Name <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="vendorName"
                                                type="text"
                                                className={`form-control ${errors.vendorName ? 'is-invalid' : ''}`}
                                                value={formData.vendorName}
                                                onChange={handleChange}
                                                required
                                                placeholder="Vendor Name"
                                            />
                                            {errors.vendorName && <small className="text-danger">{errors.vendorName}</small>}
                                        </div>
                                        {/* Similar input fields as in the AddClientModal */}
                                        <div className="form-group col-md-6">
                                            <label>Vendor Code <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="vendorCode"
                                                type="text"
                                                className={`form-control ${errors.vendorCode ? 'is-invalid' : ''}`}
                                                value={formData.vendorCode}
                                                onChange={handleChange}
                                                required
                                                readOnly
                                                placeholder="Vendor Code"
                                            />
                                            {errors.vendorCode && <small className="text-danger">{errors.vendorCode}</small>}
                                        </div>

                                    </div>

                                    {/* Remaining input fields */}
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>GST No  </label>
                                            <input
                                                name="gstNo"
                                                type="text"
                                                className="form-control"
                                                value={formData.gstNo}
                                                onChange={handleChange}
                                                required
                                                placeholder="GST No."
                                            />
                                            {gstWarning && <small className="text-danger">{gstWarning}</small>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>PAN No </label>
                                            <input
                                                name="panNo"
                                                type="text"
                                                className="form-control"
                                                value={formData.panNo}
                                                onChange={handleChange}
                                                required
                                                placeholder="PAN No."
                                            />
                                            {panWarning && <small className="text-danger">{panWarning}</small>}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Name of the Contact Person 1 <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="contactPersonName1"
                                                type="text"
                                                className={`form-control ${errors.contactPersonName1 ? 'is-invalid' : ''}`}
                                                value={formData.contactPersonName1}
                                                onChange={handleChange}
                                                required
                                                placeholder="Name of the Contact Person"
                                            />
                                            {errors.contactPersonName1 && <small className="text-danger">{errors.contactPersonName1}</small>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Name of the Contact Person 2 </label>
                                            <input
                                                name="contactPersonName2"
                                                type="text"
                                                className="form-control"
                                                value={formData.contactPersonName2}
                                                onChange={handleChange}
                                                required
                                                placeholder="Name of the Contact Person"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Mobile No. 1 <span style={{ color: "red" }}> * </span></label>
                                            <input
                                                name="mobileNo1"
                                                type="number"
                                                className={`form-control ${errors.mobileNo1 ? 'is-invalid' : ''}`}
                                                value={formData.mobileNo1}
                                                onChange={handleChange}
                                                required
                                                placeholder="Mobile No. 1"
                                            />
                                            {errors.mobileNo1 && <small className="text-danger">{errors.mobileNo1}</small>}
                                            {mobileWarning.mobileNo1 && <small className="text-danger">{mobileWarning.mobileNo1}</small>}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label >Mobile No. 2</label>
                                            <input
                                                name="mobileNo2"
                                                type="number"
                                                className="form-control"
                                                value={formData.mobileNo2}
                                                onChange={handleChange}
                                                placeholder="Mobile No. 2"
                                            />
                                            {mobileWarning.mobileNo2 && <small className="text-danger">{mobileWarning.mobileNo2}</small>}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Mobile No. 3</label>
                                            <input
                                                name="mobileNo3"
                                                type="number"
                                                className="form-control"
                                                value={formData.mobileNo3}
                                                onChange={handleChange}
                                                placeholder="Mobile No. 3"
                                            />
                                            {mobileWarning.mobileNo3 && <small className="text-danger">{mobileWarning.mobileNo3}</small>}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Email Id 1 <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="emailId1"
                                                type="email"
                                                className={`form-control ${errors.emailId1 ? 'is-invalid' : ''}`}
                                                value={formData.emailId1}
                                                onChange={handleChange}
                                                required
                                                placeholder="Email Id 1"
                                            />
                                            {errors.emailId1 && <small className="text-danger">{errors.emailId1}</small>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Email Id 2</label>
                                            <input
                                                name="emailId2"
                                                type="email"
                                                className="form-control"
                                                value={formData.emailId2}
                                                onChange={handleChange}
                                                placeholder="Email Id 2"
                                            />
                                        </div>
                                    </div>
                                    <label htmlFor=""> Address details : -</label>
                                    <div className="form-group">
                                        <label>Address <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="address"
                                            type="text"
                                            className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                            value={formData.address}
                                            onChange={handleChange}
                                            required
                                            placeholder="Address"
                                        />
                                        {errors.address && <small className="text-danger">{errors.address}</small>}
                                    </div>
                                    <div className="form-row">

                                        <div className="form-group col-md-6">
                                            <label>State <span style={{ color: "red" }}>*</span></label>
                                            <select
                                                name="state"
                                                id="state"
                                                className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                                                value={formData.state}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="" disabled hidden>Select State</option>
                                                {states.map(state => (
                                                    <option key={state.id} value={`${state.statename}`}>{state.statename}</option>
                                                ))}
                                            </select>
                                            {errors.state && <small className="text-danger">{errors.state}</small>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>City <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="city"
                                                type="text"
                                                className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                                value={formData.city}
                                                onChange={handleChange}
                                                required
                                                placeholder="City"
                                            />
                                            {errors.city && <small className="text-danger">{errors.city}</small>}
                                        </div>

                                    </div>
                                    <div className="form-row">

                                        <div className="form-group col-md-6">
                                            <label>Pincode</label>
                                            <input
                                                name="pincode"
                                                type="number"
                                                className="form-control"
                                                value={formData.pincode}
                                                onChange={handleChange}
                                                required
                                                placeholder="Pincode"
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Supplier of Service/Product <span style={{ color: "red" }}>*</span></label>
                                            <Select
                                                isMulti
                                                options={services.map(service => ({ value: service.id, label: service.name }))}
                                                onChange={handleSupplierChange}
                                            />
                                             {errors.selectedServices && <small className="text-danger">{errors.selectedServices}</small>}
                                        </div>
                                    </div>
                                    {/* Remark */}
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea
                                            name="remark"
                                            className="form-control"
                                            rows="5"
                                            value={formData.remark}
                                            onChange={handleChange}
                                            placeholder="Remark"
                                        ></textarea>
                                    </div>
                                    {/* Upload fields */}
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <label>Upload Image / ID CArd </label>
                                            <input
                                                name="upload1"
                                                type="file"
                                                className="form-control-file"
                                                onChange={(e) => handleImageChange(e, "upload1")}
                                                required
                                            />
                                            <small>Max Size :200KB</small>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Upload Image 1 </label>
                                            <input
                                                name="upload2"
                                                type="file"
                                                className="form-control-file"
                                                onChange={(e) => handleImageChange(e, "upload2")}
                                                required
                                            />
                                            <small>Max Size :200KB</small>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Upload Image 2 </label>
                                            <input
                                                name="upload3"
                                                type="file"
                                                className="form-control-file"
                                                onChange={(e) => handleImageChange(e, "upload3")}
                                                required
                                            />
                                            <small>Max Size :200KB</small>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Upload Image 3 </label>
                                            <input
                                                name="upload4"
                                                type="file"
                                                className="form-control-file"
                                                onChange={(e) => handleImageChange(e, "upload4")}
                                                required
                                            />
                                            <small>Max Size :200KB</small>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Upload Image 4 </label>
                                            <input
                                                name="upload5"
                                                type="file"
                                                className="form-control-file"
                                                onChange={(e) => handleImageChange(e, "upload5")}
                                                required
                                            />
                                            <small>Max Size :200KB</small>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" id="save">
                                Save
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddVendor;








