import React, { useState, useEffect } from "react";
import axios from "axios";
import CompanyDesc from "./CompanyDesc";
import EditCompany from "./EditCompany";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddCompany from "./AddCompany";

function CompanyList({ handleLogout, username }) {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [showCompanyDetails, setShowCompanyDetails] = useState(false);
    const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editCompany, setEditCompany] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [deleteCompany, setDeleteCompany] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/companies`);
            setCompanies(response.data);
        } catch (error) {
            console.error("Error fetching companies:", error);
        }
    };

    const handleAddCompany = () => {
        setIsAddCompanyModalOpen(true);
    };

    const handleCloseCompanyModal = () => {
        setIsAddCompanyModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleCompanyDetails = (company) => {
        setSelectedCompany(company);
        setShowCompanyDetails(true);
    };

    const handleEditCompanyClick = (company) => {
        setEditCompany(company);
        setSelectedCompany(company);
        setIsEditModalOpen(true);
    };

    const handleEditCompanyClose = () => {
        setSelectedCompany(null);
    };

    const handleBackToTable = () => {
        setSelectedCompany(null);
        setShowCompanyDetails(false);
    };

    const handleDeleteCompany = (company) => {
        setDeleteCompany(company);
        setIsDeleteModalOpen(true);
    };

    const handleUpdateCompany = async (updatedCompany) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/companies/${updatedCompany.id}`, updatedCompany);
            console.log("Company updated:", response.data);
            const updatedCompanies = companies.map(company => (company.id === updatedCompany.id ? response.data : company));
            setCompanies(updatedCompanies);
        } catch (error) {
            console.error("Error updating company:", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/companies/${deleteCompany.id}`);
            const deletedCompany = { ...deleteCompany, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedCompany);
            setCompanies((prevCompanies) =>
                prevCompanies.filter((company) => company.id !== deleteCompany.id)
            );
            setIsDeleteModalOpen(false);
            console.log("Company deleted successfully");
        } catch (error) {
            console.error("Error deleting company:", error);
        }
    };

    const handleUpdateCompanies = () => {
        toast.success("Successfully uploaded");
        fetchCompanies();
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = companies.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100 '>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {!showCompanyDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            Company List
                                        </h6>
                                        <div className="d-flex gap-2">
                                            <button onClick={(e) => window.print()} className="btn btn-outline-success">
                                                <i className="fa fa-download"></i>
                                            </button>
                                            <button onClick={handleAddCompany} className="btn btn-outline-primary">
                                                Add New Company
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Company Name</th>
                                                        <th>Address</th>
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <style>
                                                        {`.hyperlink:hover {color: blue;}`}
                                                    </style>
                                                    {currentItems.map((company, index) => (
                                                        <tr key={index}>
                                                            <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleCompanyDetails(company)}>{company.companyName}</td>
                                                            <td>{company.companyAddress}</td>
                                                            <td>{company.companyEmail}</td>
                                                            <td>{company.companyPhone}</td>
                                                            <td>
                                                                <div className="btn-group">
                                                                    <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu actionmenu" x-placement="bottom-start">
                                                                        <a className="dropdown-item" href="#" onClick={() => handleCompanyDetails(company)}><i className="fa fa-file"></i> Detail</a>
                                                                        <a className="dropdown-item" href="#" onClick={() => handleEditCompanyClick(company)}><i className="fas fa-edit"></i> Edit</a>
                                                                        {/* <a className="dropdown-item" href="#" onClick={() => handleDeleteCompany(company)}><i className="fa fa-trash"></i> Delete</a> */}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(companies.length / itemsPerPage) }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(companies.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showCompanyDetails && selectedCompany && (
                        <CompanyDesc
                            company={selectedCompany}
                            onClose={handleBackToTable}
                        />
                    )}
                    {selectedCompany && !showCompanyDetails && (
                        <EditCompany company={selectedCompany} onClose={handleEditCompanyClose} onUpdate={handleUpdateCompanies} />
                    )}
                    {isAddCompanyModalOpen && <AddCompany onClose={handleCloseCompanyModal} onUpdate={handleUpdateCompanies} />}
                    <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        itemName={deleteCompany ? deleteCompany.companyName : ""}
                        onDelete={handleDeleteConfirmation}
                        onClose={() => setIsDeleteModalOpen(false)}
                        deleteReason={deleteReason}
                        setDeleteReason={setDeleteReason}
                    />
                </div>
            </div>
        </div>
    );
}

export default CompanyList;
