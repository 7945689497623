import React, { useState, useEffect } from "react";
import axios from "axios";
import EditEmployeeModal from "./EditEmployeeModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EmployeeDetails = ({ employee, onClose }) => {
    const [bankdetailsOutHistory, setbankdetailsOutHistory] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State to manage opening/closing edit modal
    const [siteDetails, setSiteDetails] = useState(null);
    const [activeInactiveHistory, setActiveInactiveHistory] = useState(null);
    const [activeInactivelastOccurence, setActiveInactivelastOccurence] = useState(null);
    // pagination 
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);


 
        const fetchbankdetailsOutHistory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/asset/history/employee/${employee.id}`
                );
                setbankdetailsOutHistory(response.data);
            } catch (error) {
                console.error("Error fetching check-in/out history:", error);
            }
        };

        const fetchSiteDetails = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/sites/${employee.id}`
                );
                setSiteDetails(response.data);
            } catch (error) {
                console.error("Error fetching site details:", error);
            }
        };

        const fetchActiveInactiveDetails = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/active_inactive_employee/${employee.id}`
                );
                // Assuming the response contains the required data
                // Adjust this based on the actual structure of your response
                setActiveInactiveHistory(response.data);
            } catch (error) {
                console.error("Error fetching active/inactive details:", error);
            }
        };

        const fetchActiveInactiveLastDetails = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/active_inactive_employee_last/${employee.id}`
                );
                // Assuming the response contains the required data
                // Adjust this based on the actual structure of your response
                setActiveInactivelastOccurence(response.data);
            } catch (error) {
                console.error("Error fetching active/inactive details:", error);
            }
        };
        useEffect(() => {
        fetchActiveInactiveLastDetails();
        fetchActiveInactiveDetails();
        fetchbankdetailsOutHistory();
        fetchSiteDetails();
    }, [employee]);

    console.log(siteDetails);
    // Function to format the date
    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };


    // Function to handle opening edit modal
    const handleEditEmployee = () => {
        setIsEditModalOpen(true);
    };
    const handleUpdateEmployees = () => {
        toast.success('Data uploaded successfully');
        fetchActiveInactiveLastDetails();
        fetchActiveInactiveDetails();
        fetchbankdetailsOutHistory();
        fetchSiteDetails();
      };
    

    // pagination logic
    // Logic to get current items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItemsforbankdetailscheckouthistory = bankdetailsOutHistory ? bankdetailsOutHistory.slice(indexOfFirstItem, indexOfLastItem) : [];
    const currentItemsforemployeehistory = activeInactiveHistory ? activeInactiveHistory.slice(indexOfFirstItem, indexOfLastItem) : [];

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    return (
        <div>
            <ToastContainer/>
            <div className="card-body p-4">
                <div className="row">
                    <div className="col-md-9 d-flex flex-column gap-2">
                        <h4 className="title-detail font-bold">
                            Employee Name - {employee.ename}
                        </h4>
                        <p className="assetdetail">
                            <span className="assettype"> Designation - {employee.designation}</span> -{" "}
                        </p>
                        <small>Created By - {employee.username || "-"}</small>
                    </div>

                    <div className="col-md-3">
                        <div className=" p-2 barcode-inner">
                            <div className="assetbarcode d-flex gap-2">
                                <button onClick={onClose} className="btn btn-outline-primary">
                                    Back to Employee List
                                </button>
                                <button onClick={handleEditEmployee} className="btn btn-outline-primary">
                                    Edit Employee
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Employee Details
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="bank-details"
                                    data-toggle="tab"
                                    href="#bankdetails"
                                    role="tab"
                                    aria-controls="bankdetails"
                                    aria-selected="false"
                                >
                                    Bank Details
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="emergency-details-tab"
                                    data-toggle="tab"
                                    href="#emergency-details"
                                    role="tab"
                                    aria-controls="emergency-details"
                                    aria-selected="false"
                                >
                                    Emergency Contact Details
                                </a>
                            </li>

                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade active show" id="details" role="tabpanel" aria-labelledby="details-tab">
                                <div className="row">
                                    <div className="col-md-9" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Employee Name:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assettype2">{employee.ename || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Employee Code:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{employee.ecode || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Date Of Birth:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{formatDate(employee.dateOfBirth) || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Blood Group:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.bloodGroup || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Department:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.department || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Mobile Number:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.mobileNumber || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Email:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.eemail || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">PAN Number:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.panNo || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Aadhar Card Number:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.aadharCardNumber || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Joining Date:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{formatDate(employee.joiningDate) || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Current Salary:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.currentSalary || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Father's Name:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.fatherName || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Mother's Name:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.motherName || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Marital Status:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.maritalStatus || '-'}</p>
                                                    </td>
                                                </tr>
                                                {employee.maritalStatus === "Yes" && (
                                                    <tr>
                                                        <td bgcolor="#f2f3f4" width="200">
                                                            <p className="mb-0 font-bold">Spouse Name:</p>
                                                        </td>
                                                        <td>
                                                            <p className="mb-0 assetserial">{employee.spouseName || '-'}</p>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Current Address:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.currentAddress || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Current City:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.currentCity || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Current State:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.currentState || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Current Pincode:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.currentPincode || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Permanent Address:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.permanentAddress || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Permanent City:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.permanentCity || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Permanent State:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.permanentState || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Permanent Pincode:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.permanentPincode || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Additional Information:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.additionalInformation || '-'}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-3 pt-2 text-center">
                                        <img
                                            src={`${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${employee.upload1}`}
                                            style={{ width: "200px" }}
                                            alt="Employee image "
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Bank Details Tab Content */}
                            <div className="tab-pane fade" id="bankdetails" role="tabpanel" aria-labelledby="bank-details">
                                <div className="row">
                                    <div className="col-md-9" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>

                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p class="mb-0 font-bold">Account Name:</p>
                                                    </td>
                                                    <td>
                                                        <p class="mb-0 assetserial">{employee.accountName || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p class="mb-0 font-bold">Account Relation:</p>
                                                    </td>
                                                    <td>
                                                        <p class="mb-0 assetserial">{employee.accountRelation || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p class="mb-0 font-bold">Bank Name:</p>
                                                    </td>
                                                    <td>
                                                        <p class="mb-0 assetserial">{employee.bankName || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p class="mb-0 font-bold">Account Number:</p>
                                                    </td>
                                                    <td>
                                                        <p class="mb-0 assetserial">{employee.accountNumber || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p class="mb-0 font-bold">IFSC Code:</p>
                                                    </td>
                                                    <td>
                                                        <p class="mb-0 assetserial">{employee.ifscCode || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p class="mb-0 font-bold">Branch Address:</p>
                                                    </td>
                                                    <td>
                                                        <p class="mb-0 assetserial">{employee.branch || '-'}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-3 pt-2 text-center">
                                        <img
                                            src={`${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${employee.upload1}`}
                                            style={{ width: "200px" }}
                                            alt="Employee image "
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Emergency Details Tab Content */}
                            <div className="tab-pane fade" id="emergency-details" role="tabpanel" aria-labelledby="emergency-details-tab">
                                <div className="row">
                                    <div className="col-md-9" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Name:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.emergencyContactName || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Number:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.emergencyContactNumber || '-'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Relation:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{employee.emergencyContactRelation || '-'}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-3 pt-2 text-center">
                                        <img
                                            src={`${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${employee.upload1}`}
                                            style={{ width: "200px" }}
                                            alt="Employee image"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isEditModalOpen && (
                <EditEmployeeModal
                    employee={employee}
                    onUpdate={handleUpdateEmployees}
                    onClose={() => setIsEditModalOpen(false)}
                />
            )}
        </div>
    );
};

export default EmployeeDetails;


