import React, { useState, useEffect } from "react";
import axios from "axios";
import EditPurchase from "./EditPurchase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PurchaseDesc = ({ purchase, onClose }) => {
    const [purchaseHistory, setPurchaseHistory] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        
        fetchPurchaseHistory();
    }, [purchase]);
        const fetchPurchaseHistory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/purchase/history/${purchase.id}`
                );
                setPurchaseHistory(response.data);
            } catch (error) {
                console.error("Error fetching purchase history:", error);
            }
        };


    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };
    const handleEditPurchase = () => {
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };
    const handleUpdatePurchaseList = () => {
        fetchPurchaseHistory();
        toast.success("Data saved Successfully")
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = purchaseHistory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <ToastContainer/>
            <div className="card-body p-4">
                <div className="row">
                    <div className="col-md-9 d-flex flex-column gap-2">
                        <h4 className="title-detail font-bold" style={{ color: "black" }}>
                            Vendor Name - {purchase.vendorName}
                        </h4>
                        <h5 className="assetdetail">
                            <span className="assettype" style={{ color: "black" }}> Vendor Code: {purchase.vendorCode}</span>
                        </h5>
                        <small>Created By - {purchase.username || "-"}</small>
                    </div>
                    <div className="col-md-3">
                        <div className="p-2 barcode-inner">
                            <div className="assetbarcode d-flex gap-4">
                                <button onClick={onClose} className="btn btn-outline-primary">
                                    <i className="fa fa-arrow-left"></i> Back
                                </button>
                                <button onClick={handleEditPurchase} className="btn btn-outline-primary">
                                    <i className="fas fa-edit"></i> Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Purchase Details
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade active show"
                                id="details"
                                role="tabpanel"
                                aria-labelledby="details-tab"
                            >
                                <div className="row">
                                    <div className="col-md-9" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Vendor Name:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{purchase.vendorName || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Vendor Code:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{purchase.vendorCode || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Address:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{purchase.vendorAddress || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Purchase Items:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{purchase.purchaseItems && JSON.parse(purchase.purchaseItems).map(product => product.name).join(', ')}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Created At:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{formatDate(purchase.createdAt) || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Invoice Number:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{purchase.invoiceNumber || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Payment Mode:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{purchase.paymentMode || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Purchase Amount:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{purchase.purchaseAmount || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Purchase Date:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{formatDate(purchase.purchaseDate) || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Purchase Description:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{purchase.purchaseDescription || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Purchased By:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{purchase.purchasedBy || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Purchased Approved By:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{purchase.purchasedApprovedBy || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Remark:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{purchase.remark || "-"}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-3 pt-2 text-center">
                                        <img
                                            src={`${process.env.REACT_APP_LOCAL_URL}/uploads/purchase_photo/${purchase.upload1}`}
                                            style={{ width: "200px" }}
                                            alt="Purchased Picture"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Purchase History */}
                            <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Event</th>
                                                    <th>Date</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((entry) => (
                                                    <tr key={entry.event_id}>
                                                        <td>{entry.event}</td>
                                                        <td>{formatDate(entry.date)}</td>
                                                        <td>{entry.description}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {/* Pagination */}
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(purchaseHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(purchaseHistory.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isEditModalOpen && <EditPurchase
                purchase={purchase}
                onUpdate={handleUpdatePurchaseList}
                onClose={handleCloseEditModal}
            />}
        </div>
    );
};

export default PurchaseDesc;

