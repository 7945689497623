import React, { useState, useEffect } from "react";
import axios from "axios";
import EditClientModal from "./EditClientModal";


const ClientDesc = ({ client, onClose }) => {
    const [inwardHistory, setInwardHistory] = useState([]);
    const [outwardHistory, setOutwardHistory] = useState([]);
    const [salesHistory, setSalesHistory] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedinwardEntry, setSelectedinwardEntry] = useState(null);
    const [selectedOutwardEntry, setSelectedOutwardEntry] = useState(null);
    const [selectedSalesEntry, setSelectedSalesEntry] = useState(null);
    const [salesModalOpen, setsalesModalOpen] = useState(false);
    const [inwardModalOpen, setinwardModalOpen] = useState(false);
    const [outwardModalOpen, setoutwardModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const salesResponse = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/sales/${client.id}`
                );
                setSalesHistory(salesResponse.data);


                const inwardResponse = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/inward/${client.id}`
                );
                setInwardHistory(inwardResponse.data);

                const outwardResponse = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/outward/${client.id}`
                );
                setOutwardHistory(outwardResponse.data);
            } catch (error) {
                console.error("Error fetching history:", error);
            }
        };

        fetchData();
    }, [client.id]);

    const handleViewoutwardClick = (entry) => {
        setSelectedOutwardEntry(entry);
        setoutwardModalOpen(true);
    };
    const handleViewClick = (entry) => {
        setSelectedinwardEntry(entry);
        setinwardModalOpen(true);
    };
    const handleViewSalesClick = (entry) => {
        setSelectedSalesEntry(entry)
        setsalesModalOpen(true);
    };

    const closeModal = () => {
        setsalesModalOpen(false);
        setinwardModalOpen(false);
        setoutwardModalOpen(false);
        setSelectedinwardEntry(null);
        setSelectedOutwardEntry(null);
        setSelectedSalesEntry(null);
    };

    const handleEditClient = () => {
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
    };



    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItemsInward = inwardHistory.slice(indexOfFirstItem, indexOfLastItem);
    const currentItemsOutward = outwardHistory.slice(indexOfFirstItem, indexOfLastItem);
    const currentItemsSales = salesHistory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total sales amount
    const totalSalesAmount = salesHistory.reduce((total, sale) => total + sale.totalSalesAmount, 0);

    console.log("ada", totalSalesAmount);

    // Download Button 
    const handleDownload = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/generate-pdf/client/${client.id}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${client.clientName}-details.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading PDF:", error);
        }
    };

    return (
        <div>
            <div className="card-body p-4">
                <div className="row">
                    <div className="col-md-9 d-flex flex-column gap-2">
                        <h4 className="title-detail font-bold" style={{ color: "black" }}>
                            Client Name - {client.clientName}
                        </h4>
                        <h5 className="assetdetail">
                            <span className="assettype" style={{ color: "black" }}> Client Code: {client.clientCode}</span>
                        </h5>
                        <small>Created By - {client.username || "-"}</small>
                    </div>
                    <div className="col-md-3">
                        <div className=" p-2 barcode-inner">
                            <div className="assetbarcode d-flex gap-2">
                                <button onClick={onClose} className="btn btn-outline-primary">
                                    <i className="fa fa-arrow-left"></i> Back
                                </button>
                                <button onClick={handleDownload} className="btn btn-outline-success">
                                    <i className="fa fa-download"></i> Download PDF
                                </button>
                                {/* <button onClick={handleEditClient} className="btn btn-primary"> 
                                    Edit Client
                                </button> */}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Client Details
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="sales-tab"
                                    data-toggle="tab"
                                    href="#sales"
                                    role="tab"
                                    aria-controls="sales"
                                    aria-selected="false"
                                >
                                    Sales
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="inward-tab"
                                    data-toggle="tab"
                                    href="#inward"
                                    role="tab"
                                    aria-controls="inward"
                                    aria-selected="false"
                                >
                                    Inward
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="outward-tab"
                                    data-toggle="tab"
                                    href="#outward"
                                    role="tab"
                                    aria-controls="outward"
                                    aria-selected="false"
                                >
                                    Outward
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade active show" id="details" role="tabpanel" aria-labelledby="details-tab">
                                <div className="row">
                                    <div className="col-md-9" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Client Name:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assettype2">{client.clientName || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Client Code:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{client.clientCode || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">GST No:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{client.gstNo || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">PAN No:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{client.panNo || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Mobile No1:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{client.mobileNo1 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Mobile No2:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{client.mobileNo2 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Mobile No3:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{client.mobileNo3 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Email Id 1:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{client.emailId1 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Email Id 2:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{client.emailId2 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Contact Person:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{client.contactPersonName || "-"}</p>
                                                    </td>
                                                </tr>
                                                {/* Address 1 */}
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Address 1:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{client.address1 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">City 1:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{client.clientCity1 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">State 1:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{client.clientState1 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Pincode:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{client.clientPincode1 || "-"}</p>
                                                    </td>
                                                </tr>
                                                {/* Address 2 */}
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Address 2:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{client.address2 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">City 2:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{client.clientCity2 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">State 2:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{client.clientState2 || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Pincode 2:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{client.clientPincode2 || "-"}</p>
                                                    </td>
                                                </tr>
                                                {/* Remark */}
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Remark:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{client.remark || "-"}</p>
                                                    </td>
                                                </tr>
                                                {/* Bank Details */}
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Bank Name:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{client.bankName || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Account No:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{client.accountNo || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">IFSC Code:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{client.ifscCode || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Branch:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{client.branch || "-"}</p>
                                                    </td>
                                                </tr>

                                                {/* Add other client details here */}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Image Section */}
                                    <div class="col-md-3 pt-2 text-center">
                                        <img
                                            src={`${process.env.REACT_APP_LOCAL_URL}/uploads/clients/${client.upload}`}
                                            style={{ width: "200px" }}
                                            alt="Client Picture"
                                        />
                                        <div className=" p-5">
                                            Total Sales Amount :- {totalSalesAmount}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* Sales history */}
                            <div className="tab-pane fade" id="sales" role="tabpanel" aria-labelledby="sales-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Client Name</th>
                                                    <th>company Name</th>
                                                    <th>Sales Executive Name</th>
                                                    <th>Total Amount</th>
                                                    <th>sales Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsSales.map((entry) => (
                                                    <tr key={entry.id}>
                                                        <td>{entry.clientName}</td>
                                                        <td>{entry.companyName}</td>
                                                        <td>{entry.salesExecutiveName}</td>
                                                        <td>{entry.totalSalesAmount}</td>
                                                        <td>{formatDate(entry.salesDate)}</td>
                                                        <td><button className="btn btn-outline-info" onClick={() => handleViewSalesClick(entry)}>View</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(salesHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(salesHistory.length / itemsPerPage) ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {selectedSalesEntry && (
                                <div
                                    isOpen={salesModalOpen}
                                    onRequestClose={closeModal}
                                    className="modal-body custom-modal"
                                    style={{ position: "absolute" }}
                                >
                                    {/* Content for Inward Modal */}
                                    <div className="container-fluid incustom-modal" style={{ overflowY: "auto" }}>
                                        <div className="main-container" style={{ height: "100%" }}>
                                            <div className="modalnav">
                                                <h2>Sales Details</h2>
                                            </div>
                                            <div className="modalmain">
                                                <div>
                                                    <p><strong>Client Name:</strong> {selectedSalesEntry.clientName}</p>
                                                    <p><strong>Client Code:</strong> {selectedSalesEntry.clientCode}</p>
                                                    <p><strong>Address:</strong> {selectedSalesEntry.address || "-"}</p>
                                                    <p><strong>Comapny  Name:</strong> {selectedSalesEntry.companyName || "-"}</p>
                                                    <p><strong>Email:</strong> {selectedSalesEntry.emailId1 || "-"}</p>
                                                    <p><strong>Mobile Number:</strong> {selectedSalesEntry.mobileNo1 || "-"}</p>
                                                    <p><strong>sales Date:</strong> {selectedSalesEntry.salesDate || "-"}</p>
                                                    <p><strong>sales Description:</strong> {selectedSalesEntry.salesDescription || "-"}</p>
                                                    <p><strong>sales Executive Name:</strong> {selectedSalesEntry.salesExecutiveName}</p>
                                                    <p><strong>Selected Service Products:</strong> {selectedSalesEntry.selectedServiceProducts && JSON.parse(selectedSalesEntry.selectedServiceProducts).map(product => product.name).join(', ')}</p>
                                                    <p><strong>Total Sales Amount:</strong> {selectedSalesEntry.totalSalesAmount}</p>
                                                </div>
                                                <div style={{ width: "15vw", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={`${process.env.REACT_APP_LOCAL_URL}/uploads/sales_photo/${selectedSalesEntry.uploadPicture}`} alt="Sales Upload" />
                                                </div>
                                            </div>
                                            <div className="modalfooter">
                                                <button className="btn btn-secondary" onClick={closeModal}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Inward history */}
                            <div className="tab-pane fade" id="inward" role="tabpanel" aria-labelledby="inward-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Client Name</th>
                                                    <th>Consignment Date</th>
                                                    <th>Courier Company</th>
                                                    <th>Received By </th>
                                                    <th>Received Date</th>
                                                    <th>Consignment Subject</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsInward.map((entry) => (
                                                    <tr key={entry.id}>
                                                        <td>{entry.entityName}</td>
                                                        <td>{formatDate(entry.consignmentdate)}</td>
                                                        <td>{entry.courierCompany}</td>
                                                        <td>{entry.ename}</td>
                                                        <td>{formatDate(entry.receiptDate)}</td>
                                                        <td>{entry.consignmentType}</td>
                                                        <td><button className="btn btn-outline-info" onClick={() => handleViewClick(entry)}>View</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(inwardHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(inwardHistory.length / itemsPerPage) ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {selectedinwardEntry && (
                                <div
                                    isOpen={inwardModalOpen}
                                    onRequestClose={closeModal} className="modal-body custom-modal"
                                    style={{ position: "absolute" }}
                                >
                                    {/* Content for Inward Modal */}
                                    <div className="container-fluid incustom-modal" style={{ overflowY: "auto" }}>
                                        <div className="main-container" style={{ height: "100%" }}>
                                            <div className="modalnav">
                                                <h2>Inward Details</h2>
                                            </div>
                                            <div className="modalmain">
                                                <div>
                                                    <p><strong>Client Name:</strong> {selectedinwardEntry.entityName}</p>
                                                    <p><strong>Client Code:</strong> {selectedinwardEntry.code}</p>
                                                    <p><strong>Address:</strong> {selectedinwardEntry.address}</p>
                                                    <p><strong>Sate:</strong> {selectedinwardEntry.state}</p>
                                                    <p><strong>City:</strong> {selectedinwardEntry.city}</p>
                                                    <p><strong>Pincode:</strong> {selectedinwardEntry.pincode}</p>
                                                    <p><strong>Courier Company:</strong> {selectedinwardEntry.courierCompany}</p>
                                                    <p><strong>Consignment No:</strong> {selectedinwardEntry.consignmentNo}</p>
                                                    <p><strong>Consignment Date:</strong> {formatDate(selectedinwardEntry.consignmentdate)}</p>
                                                    <p><strong>Ship From:</strong> {selectedinwardEntry.shipfromaddress}</p>
                                                    <p><strong>Ship To:</strong> {selectedinwardEntry.shipTo}</p>
                                                    <p><strong>Received Date:</strong> {formatDate(selectedinwardEntry.receiptDate)}</p>
                                                    <p><strong>Received By:</strong> {selectedinwardEntry.ename}</p>
                                                    <p><strong>Consignment Type:</strong> {selectedinwardEntry.consignmentType}</p>
                                                    <p><strong>Remark:</strong> {selectedinwardEntry.remark}</p>
                                                    <p><strong>Invoice:</strong> {selectedinwardEntry.invoice || "-"}</p>
                                                </div>
                                                <div style={{ width: "15vw", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={`${process.env.REACT_APP_LOCAL_URL}/uploads/inward/${selectedinwardEntry.photo}`} alt="inward Upload" />
                                                </div>
                                            </div>
                                            <div className="modalfooter">
                                                <button className="btn btn-secondary" onClick={closeModal}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Outward History */}
                            <div className="tab-pane fade" id="outward" role="tabpanel" aria-labelledby="outward-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Client Name</th>
                                                    <th>Consignment Date</th>
                                                    <th>Courier Company</th>
                                                    <th>Dispatched By </th>
                                                    <th>Consignment Subject</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItemsOutward.map((entry) => (
                                                    <tr key={entry.id}>
                                                        <td>{entry.entityName}</td>
                                                        <td>{formatDate(entry.consignmentdate)}</td>
                                                        <td>{entry.courierCompany}</td>
                                                        <td>{entry.ename}</td>

                                                        <td>{entry.consignmentType}</td>
                                                        <td><button className="btn btn-outline-info" onClick={() => handleViewoutwardClick(entry)}>View</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(outwardHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(outwardHistory.length / itemsPerPage) ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {selectedOutwardEntry && (
                                <div
                                    isOpen={outwardModalOpen}
                                    onRequestClose={closeModal} className="modal-body custom-modal"
                                    style={{ position: "absolute" }}
                                >
                                    <div className="container-fluid incustom-modal" style={{ overflowY: "auto" }} >
                                        <div className="main-container" style={{ height: "100%" }}>
                                            <div className="modalnav">
                                                <h2>Outward Details</h2>
                                            </div>
                                            <div className="modalmain">
                                                <div>
                                                    <p><strong>Client Name:</strong> {selectedOutwardEntry.entityName}</p>
                                                    <p><strong>Client Code:</strong> {selectedOutwardEntry.code}</p>
                                                    <p><strong>Address:</strong> {selectedOutwardEntry.address}</p>
                                                    <p><strong>Sate:</strong> {selectedOutwardEntry.state}</p>
                                                    <p><strong>City:</strong> {selectedOutwardEntry.city}</p>
                                                    <p><strong>Pincode:</strong> {selectedOutwardEntry.pincode}</p>
                                                    <p><strong>Courier Company:</strong> {selectedOutwardEntry.courierCompany}</p>
                                                    <p><strong>Consignment No:</strong> {selectedOutwardEntry.consignmentNo}</p>
                                                    <p><strong>Consignment Date:</strong> {formatDate(selectedOutwardEntry.consignmentdate)}</p>
                                                    <p><strong>Ship From:</strong> {selectedOutwardEntry.shipFrom}</p>
                                                    <p><strong>Ship To:</strong> {selectedOutwardEntry.shiptoaddress}</p>
                                                    <p><strong>Dispatched By:</strong> {selectedOutwardEntry.ename}</p>
                                                    <p><strong>Consignment Type:</strong> {selectedOutwardEntry.consignmentType}</p>
                                                    <p><strong>Remark:</strong> {selectedOutwardEntry.remark}</p>
                                                    <p><strong>Invoice:</strong> {selectedOutwardEntry.invoice || "-"}</p>
                                                </div>
                                                <div style={{ width: "15vw", height: "30vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={`${process.env.REACT_APP_LOCAL_URL}/uploads/outward/${selectedOutwardEntry.photo}`} alt="outward Upload" />
                                                </div>
                                            </div>
                                            <div className="modalfooter">
                                                <button className="btn btn-secondary" onClick={closeModal}>Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isEditModalOpen && <EditClientModal
                client={client}
                onClose={handleCloseEditModal}
            />} {/* Pass onUpdate function */}
        </div>
    );
};

export default ClientDesc;














