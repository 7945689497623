import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Correct named import
import Login from './authentication/Login';
import Signup from './authentication/Signup';
import Dashboard from './pages/Dashboard';
import ResetPassword from './authentication/ResetPassword';
import ForgotPassword from './authentication/ForgotPassword';
import AssetMaintenance from './pages/AssetMaster/AssetMaintenance';
import Assetlist from './pages/AssetMaster/Assetlist';
import FinishedMaintenance from './pages/AssetMaster/FinishedMaintenance';
import UnfinishedMaintenance from './pages/AssetMaster/UnfinishedMaintenance';
import AssetInsurance from './pages/AssetMaster/AssetInsurance';
import AssetLostList from './pages/AssetMaster/AssetLostList';
import Employeelist from './pages/EmployeeMaster/Employeelist';
import Sitelist from './pages/SiteMaster/Sitelist';
import ClientList from './pages/ClientMaster/ClientList';
import Vendorlist from './pages/VendorMaster/Vendorlist';
import ApplicationSetting from './pages/SettingMaster/ApplicationSetting';
import AssetTransferList from './pages/AssetMaster/AssetTransferList';
import TransporterList from './pages/AssetMaster/TransporterList';
import AssetMasterList from './pages/AssetMaster/AssetMasterList';
import InwardList from './pages/Inword/InwardList';
import OutwardList from './pages/Outward/OutwardList';
import CourierList from './pages/CourierMaster/CourierList';
import Officelist from './pages/OurOfficeMaster/Officelist';
import Servicelist from './pages/service_master/Servicelist';
import Productlist from './pages/ProductMaster/Productlist';
import Profile from './pages/SettingMaster/Profile';
import SalesList from './pages/SalesMaster/SalesList';
import PurchaseList from './pages/PurchaseMaster/PurchaseList';
import CompanyList from './pages/Company Master/CompanyList';
import InquiryList from './pages/Salesfunal/InquiryList';
import ConvertedList from './pages/Salesfunal/ConvertedList';
import UnconvertedList from './pages/Salesfunal/UnconvertedList';

const App = () => {
  // State to track authentication status
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(true); // State to track loading status

  // Function to check if user is authenticated based on token in localStorage
  // const checkAuth = () => {
  //   return localStorage.getItem('token') !== null;
  // };
  const checkAuth = () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 < Date.now()) {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          localStorage.setItem('time');

          // console.log(time)
          return false;
        }
        return true;
      } catch (error) {
        return false;
      }
    }
    return false;
  };

  // Update authentication status on component mount
  useEffect(() => {
    const isAuthenticated = checkAuth();
    setIsAuthenticated(isAuthenticated);
    setLoading(false); // Set loading to false once authentication check is 
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    if (token) {
      setIsAuthenticated(true);
      setUsername(username);
    }
  }, []);

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setIsAuthenticated(false);
    setUsername('');
  };

  const handleLogin = ({ token, username }) => {
    console.log("Received token:", token);
    console.log("Received username:", username);
  
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
    
    console.log("Username stored in localStorage:", localStorage.getItem('username'));
  
    setIsAuthenticated(true);
    setUsername(username);
  };
  

  if (loading) {
    // Loading state, render loading indicator or component
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={isAuthenticated ? (<Navigate to="/dashboard" replace />) : (<Login handleLogin={handleLogin} />)} />
        <Route path="/signup" element={<Signup/>} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/dashboard" element={isAuthenticated ? (<Dashboard username={username} handleLogout={handleLogout} />) : (<Navigate to="/" replace />)} />
        {/* inward list  */}
        <Route path="/inwardlist" element={isAuthenticated ? <InwardList username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        {/* OutWard List */}
        <Route path="/outwardlist" element={isAuthenticated ? <OutwardList username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        {/* Courier List */}
        <Route path="/courierlist" element={isAuthenticated ? <CourierList username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        {/* Employeelist */}
        <Route path="/employeelist" element={isAuthenticated ? <Employeelist username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        {/* office List  */}
        <Route path="/officelist" element={isAuthenticated ? <Officelist username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        {/* service List  */}
        <Route path="/servicelist" element={isAuthenticated ? <Servicelist username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        {/* Product List  */}
        <Route path="/productlist" element={isAuthenticated ? <Productlist username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        {/* profile  */}
        <Route path="/profile" element={isAuthenticated ? <Profile username={username} handleLogout={handleLogout}/> : <Navigate to="/" replace />} />
        {/* Sales List */}
        <Route path="/saleslist" element={isAuthenticated ? <SalesList username={username} handleLogout={handleLogout}/> : <Navigate to="/" replace />} />
        {/* Purchase List  */}
        <Route path="/purchaselist" element={isAuthenticated ? <PurchaseList username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        {/* Company List  */}
        <Route path="/companylist" element={isAuthenticated ? <CompanyList username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        {/* InquiryList  */}
        <Route path="/InquiryList" element={isAuthenticated ? <InquiryList username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        {/* converter inquery List  */}
        <Route path="/convertedinquirylist" element={isAuthenticated ? <ConvertedList username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        {/* unconverter inquery List  */}
        <Route path="/unconvertedinquirylist" element={isAuthenticated ? <UnconvertedList username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />

        <Route path="/finishedmaintenance" element={isAuthenticated ? <FinishedMaintenance username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        <Route path="/unfinishedmaintenance" element={isAuthenticated ? <UnfinishedMaintenance username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        <Route path="/clientlist" element={isAuthenticated ? <ClientList username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        <Route path="/sitelist" element={isAuthenticated ? <Sitelist username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        <Route path="/transporterlist" element={isAuthenticated ? <TransporterList username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        {/* Pass username and handleLogout props to Assetlist */}
        <Route path="/assetlist" element={isAuthenticated ? <Assetlist username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        <Route path="/assetmasterlist" element={isAuthenticated ? <AssetMasterList username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        <Route path="/employeelist" element={isAuthenticated ? <Employeelist username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        <Route path="/vendorlist" element={isAuthenticated ? <Vendorlist username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
        <Route path="/applicationsetting" element={isAuthenticated ? <ApplicationSetting username={username} handleLogout={handleLogout}/> : <Navigate to="/" replace />} />
        <Route path="/assettransfer" element={isAuthenticated ? <AssetTransferList username={username} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;

