import React, { useState, useEffect } from "react";
import axios from "axios";
import NextInquiry from "./NextInquiry";
import { toast, ToastContainer } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css';

const InquiryDesc = ({ inquiry, onClose }) => {
    const [inquiryHistory, setInquiryHistory] = useState([]);
    const [isNextModalOpen, setIsNextModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        const fetchInquiryHistory = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_LOCAL_URL}/api/inquiries/history/${inquiry.id}`
                );
                setInquiryHistory(response.data);
            } catch (error) {
                console.error("Error fetching inquiry history:", error);
            }
        };

        fetchInquiryHistory();
    }, [inquiry]);

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const handleNextInquiry = () => {
        setIsNextModalOpen(true);
    };

    const handleCloseNextModal = () => {
        setIsNextModalOpen(false);
    };

    const updateStatus = async (status) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/inquiry_status/${inquiry.id}`, { status });
            console.log('Status data updated:', response.data);
            toast.success('updated successfully'); // Display toast notification
            // Optionally, you can update the local state or fetch the inquiry details again to reflect the changes
        } catch (error) {
            toast.error('Error updating status');
            console.error('Error updating status:', error);
        }
    };

    const handleConvertedClick = () => {
        updateStatus('converted');
    };

    const handleUnconvertedClick = () => {
        updateStatus('unconverted');
    };
    const handleupdatedesc = () => {
        toast.success('updated successfully');
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = inquiryHistory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <ToastContainer />
            <div className="card-body p-4">
                <div className="row">
                    <div className="col-md-9 d-flex flex-column gap-2">
                        <h4 className="title-detail font-bold" style={{ color: "black" }}>
                            Customer Name - {inquiry.customerName}
                        </h4>
                        <h5 className="assetdetail">
                            <span className="assettype" style={{ color: "black" }}> Call Attend Executive Name: {inquiry.callAttendExecutiveName}</span>
                        </h5>
                        <small>Created By - {inquiry.username || "-"}</small>
                    </div>
                    <div className="col-md-3">
                        <div className="p-2 barcode-inner">
                            <div className="assetbarcode d-flex flex-column gap-3">
                                <div className="d-flex gap-2">
                                    <button onClick={onClose} className="btn btn-outline-primary">
                                        <i className="fa fa-arrow-left"></i> Back
                                    </button>
                                    <button onClick={handleNextInquiry} className="btn btn-outline-success">
                                        Next Inquiry <i className="fa fa-arrow-right"></i>
                                    </button>
                                </div>
                                <div className="d-flex gap-2">
                                    <button onClick={handleConvertedClick} className="btn btn-success">
                                        Converted
                                    </button>
                                    <button onClick={handleUnconvertedClick} className="btn btn-danger">
                                        Unconverted
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active show"
                                    id="details-tab"
                                    data-toggle="tab"
                                    href="#details"
                                    role="tab"
                                    aria-controls="details"
                                    aria-selected="true"
                                >
                                    Inquiry Details
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="history-tab"
                                    data-toggle="tab"
                                    href="#history"
                                    role="tab"
                                    aria-controls="history"
                                    aria-selected="true"
                                >
                                    Inquiry history
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade active show"
                                id="details"
                                role="tabpanel"
                                aria-labelledby="details-tab"
                            >
                                <div className="row">
                                    <div className="col-md-12" style={{ maxHeight: "calc(100vh - 300px)", overflowY: "auto", overflowX: "hidden" }}>
                                        <table className="table table-hover" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Customer Name:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{inquiry.customerName || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Call Attend Executive Name:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetstatus">{inquiry.callAttendExecutiveName || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Email:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{inquiry.emailId || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Mobile No:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{inquiry.mobileNo || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Call Date:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{formatDate(inquiry.callDate) || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Next Call Date:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{formatDate(inquiry.nextCallDate) || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Inquiry Description:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{inquiry.description || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Not Listed Product/Service:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{inquiry.notListedProductService || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Selected Service Products:</p>
                                                    </td>
                                                    <td>
                                                        {inquiry.inquiryProductService ? (
                                                            <ul className="mb-0">
                                                                {JSON.parse(inquiry.inquiryProductService).map(product => (
                                                                    <li key={product.id}>{product.name}</li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <p className="mb-0 assetserial">-</p>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Total Inquiry Amount:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{inquiry.amount || "-"}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td bgcolor="#f2f3f4" width="200">
                                                        <p className="mb-0 font-bold">Status:</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 assetserial">{inquiry.status || "-"}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            {/* Inquiry History */}
                            <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Calling Date</th>
                                                    <th>Description</th>
                                                    <th>Next Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((entry) => (
                                                    <tr key={entry.event_id}>
                                                        <td>{formatDate(entry.callingDate)}</td>
                                                        <td>{entry.description}</td>
                                                        <td>{formatDate(entry.nextDate)} </td> 
                                                                                              
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {/* Pagination */}
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(inquiryHistory.length / itemsPerPage) || 1 }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(inquiryHistory.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isNextModalOpen && <NextInquiry
                onupdate={handleupdatedesc}
                inquiry={inquiry}
                onClose={handleCloseNextModal}
            />}
        </div>
    );
};

export default InquiryDesc;
