import React, { useState, useEffect } from "react";
import imageCompression from 'browser-image-compression';
import axios from "axios";

const EditClientModal = ({ onClose, onUpdate, client }) => {
  const [formData, setFormData] = useState(client);
  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);
  const apiUrl = process.env.REACT_APP_LOCAL_URL;
  const [gstWarning, setGstWarning] = useState("");
  const [panWarning, setPanWarning] = useState("");
  const [ifscWarning, setIfscWarning] = useState("");
  const [mobileWarning, setMobileWarning] = useState({ mobileNo1: "", mobileNo2: "", mobileNo3: "" });

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      const response = await axios.get(`${apiUrl}/states`);
      setStates(response.data);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "gstNo") {
      setGstWarning(value.length !== 15 ? "GST number must be 15 digits" : "");
    }

    if (name === "panNo") {
      setPanWarning(value.length !== 10 ? "PAN number must be 10 digits" : "");
    }

    if (name === "ifscCode") {
      setIfscWarning(value.length !== 11 ? "IFSC number must be 11 digits" : "");
    }

    if (name.startsWith("mobileNo")) {
      setMobileWarning({
        ...mobileWarning,
        [name]: value.length !== 10 ? "Mobile number must be 10 digits" : ""
      });
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value === "yes",
    });
  };

  const handleImageChange = async (e,fieldName) => {
    const file = e.target.files[0];
    try {
      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);

      // Create a new File object with the compressed file and original file name
      const fileWithMetaData = new File([compressedFile], file.name, { type: compressedFile.type });
      setFormData({
        ...formData,
        [fieldName]: fileWithMetaData, // Set the compressed file with metadata
      });
    } catch (error) {
      console.error('Image compression error:', error);
    }
  };

  const validate = () => {
    let formErrors = {};
    if (!formData.clientName) formErrors.clientName = 'Client Name is required';
    // Add more validation rules as needed
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validate()) {
      return;
    }
  
    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (key !== 'upload' || formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }
  
      const response = await axios.put(`${apiUrl}/clientdata/${client.id}`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      console.log("Data updated successfully:", response.data);
      onClose();
      onUpdate();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  
  

  const handleClose = () => {
    onClose();
  };

  return (
    <div
      id="editClientModal"
      className="modal fade show"
      role="dialog"
      style={{ display: "block", paddingRight: "17px" }}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <form
            action=""
            id="formEditClient"
            autoComplete="off"
            noValidate="novalidate"
            onSubmit={handleSubmit}
          >
            <div className="modal-header">
              <h5 className="modal-title">Edit Client</h5>
              <button type="button" className="close" onClick={handleClose}>
                &times;
              </button>
            </div>
            <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
              {/* Form fields... */}
              <div className="row">
                <div className="col-md-12">
                  {/* Left Side */}
                  <div className="form-row">
                    <div className="form-group col-md-8">
                      <label>Client Name <span style={{ color: "red" }}>*</span></label>
                      <input
                        name="clientName"
                        type="text"
                        className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                        value={formData.clientName}
                        onChange={handleChange}
                        required
                        placeholder="Client Name"
                        readOnly
                      />
                      {errors.clientName && <small className="text-danger">{errors.clientName}</small>}
                    </div>
                    <div className="form-group col-md-4">
                      <label>Client Code <span style={{ color: "red" }}>*</span></label>
                      <input
                        name="clientCode"
                        type="text"
                        className={`form-control ${errors.clientCode ? 'is-invalid' : ''}`}
                        value={formData.clientCode}
                        onChange={handleChange}
                        required
                        readOnly
                        placeholder="Client Code"
                      />
                       {errors.clientCode && <small className="text-danger">{errors.clientCode}</small>}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>GST No</label>
                      <input
                        name="gstNo"
                        type="text"
                        className="form-control"
                        value={formData.gstNo}
                        onChange={handleChange}
                        required
                        placeholder="GST No."
                      />
                      {gstWarning && <small className="text-danger">{gstWarning}</small>}
                    </div>
                    <div className="form-group col-md-6">
                      <label>PAN No </label>
                      <input
                        name="panNo"
                        type="text"
                        className="form-control"
                        value={formData.panNo}
                        onChange={handleChange}
                        required
                        placeholder="PAN No."
                      />
                      {panWarning && <small className="text-danger">{panWarning}</small>}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Name of the Contact Person <span style={{ color: "red" }}>*</span></label>
                    <input
                      name="contactPersonName"
                      type="text"
                      className={`form-control ${errors.contactPersonName ? 'is-invalid' : ''}`}
                      value={formData.contactPersonName}
                      onChange={handleChange}
                      required
                      placeholder="Name of the Contact Person"
                    />
                    {errors.contactPersonName && <small className="text-danger">{errors.contactPersonName}</small>}
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label>Mobile No. 1 <span style={{ color: "red" }}>*</span></label>
                      <input
                        name="mobileNo1"
                        type="number"
                        className={`form-control ${errors.mobileNo1 ? 'is-invalid' : ''}`}
                        value={formData.mobileNo1}
                        onChange={handleChange}
                        required
                        placeholder="Mobile No. 1"
                      />
                      {errors.mobileNo1 && <small className="text-danger">{errors.mobileNo1}</small>}
                      {mobileWarning.mobileNo1 && <small className="text-danger">{mobileWarning.mobileNo1}</small>}                      
                    </div>
                    <div className="form-group col-md-4">
                      <label>Mobile No. 2</label>
                      <input
                        name="mobileNo2"
                        type="number"
                        className="form-control"
                        value={formData.mobileNo2}
                        onChange={handleChange}
                        placeholder="Mobile No. 2"
                      />
                      {mobileWarning.mobileNo2 && <small className="text-danger">{mobileWarning.mobileNo2}</small>}
                    </div>
                    <div className="form-group col-md-4">
                      <label>Mobile No. 3</label>
                      <input
                        name="mobileNo3"
                        type="number"
                        className="form-control"
                        value={formData.mobileNo3}
                        onChange={handleChange}
                        placeholder="Mobile No. 3"
                      />
                      {mobileWarning.mobileNo3 && <small className="text-danger">{mobileWarning.mobileNo3}</small>}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>Email Id 1 <span style={{ color: "red" }}>*</span></label>
                      <input
                        name="emailId1"
                        type="email"
                        className={`form-control ${errors.emailId1 ? 'is-invalid' : ''}`}
                        value={formData.emailId1}
                        onChange={handleChange}
                        required
                        placeholder="Email Id 1"
                      />
                       {errors.emailId1 && <small className="text-danger">{errors.emailId1}</small>}
                    </div>
                    <div className="form-group col-md-6">
                      <label>Email Id 2</label>
                      <input
                        name="emailId2"
                        type="email"
                        className="form-control"
                        value={formData.emailId2}
                        onChange={handleChange}
                        placeholder="Email Id 2"
                      />
                    </div>
                  </div>
                  <label htmlFor="">Principle Address: -</label>
                  <div className="form-group">
                    <label>Address :<span style={{ color: "red" }}>*</span></label>
                    <input
                      name="address1"
                      type="text"
                      className={`form-control ${errors.address1 ? 'is-invalid' : ''}`}
                      value={formData.address1}
                      onChange={handleChange}
                      required
                      placeholder="Address"
                    />
                     {errors.address1 && <small className="text-danger">{errors.address1}</small>}
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label>state <span style={{ color: "red" }}>*</span></label>
                      <select 
                      name="clientState1"
                      id="clientState1"
                      className={`form-control ${errors.clientState1 ? 'is-invalid' : ''}`}
                      value={formData.clientState1}
                      onChange={handleChange}
                      required
                      >
                        <option value="" disabled hidden>Select State</option>
                        {states.map(state => (
                          <option key={state.id} value={`${state.statename}`}>{state.statename}</option>
                        ))}
                      </select>
                      {errors.clientState1 && <small className="text-danger">{errors.clientState1}</small>}
                    </div>
                    <div className="form-group col-md-4">
                      <label>city <span style={{ color: "red" }}>*</span></label>
                      <input
                        name="clientCity1"
                        type="text"
                        className={`form-control ${errors.clientCity1 ? 'is-invalid' : ''}`}
                        value={formData.clientCity1}
                        onChange={handleChange}
                        required
                        placeholder="City"
                      />
                       {errors.clientCity1 && <small className="text-danger">{errors.clientCity1}</small>}
                    </div>
                    <div className="form-group col-md-4">
                      <label>Pincode </label>
                      <input
                        name="clientPincode1"
                        type="number"
                        className="form-control"
                        value={formData.clientPincode1}
                        onChange={handleChange}
                        required
                        placeholder="Pincode"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Address 2:</label>
                    <div>
                      <label className="radio-inline mr-2">
                        <input
                          type="radio"
                          name="showAddress2"
                          value="yes"
                          checked={formData.showAddress2}
                          onChange={handleRadioChange}
                        /> Yes
                      </label>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          name="showAddress2"
                          value="no"
                          checked={!formData.showAddress2}
                          onChange={handleRadioChange}
                        /> No
                      </label>
                    </div>
                  </div>
                  {/* Conditional Address 2 fields */}
                  {formData.showAddress2 && (
                    <div>
                      {/* Address 2 fields... */}
                      <div className="form-group">
                        <label>Address 2 :<span style={{ color: "red" }}>*</span></label>
                        <input
                          name="address2"
                          type="text"
                          className="form-control"
                          value={formData.address2}
                          onChange={handleChange}
                          required
                          placeholder="Address"
                        />
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label>state <span style={{ color: "red" }}>*</span></label>
                          <select 
                          name="clientState2"
                          id="clientState2"
                          className="form-control"
                          value={formData.clientState2}
                          onChange={handleChange}
                          required
                          >
                            <option value="" disabled hidden>Select State</option>
                            {states.map(state => (
                              <option key={state.id} value={`${state.statename}`}>{state.statename}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label>city <span style={{ color: "red" }}>*</span></label>
                          <input
                            name="clientCity2"
                            type="text"
                            className="form-control"
                            value={formData.clientCity2}
                            onChange={handleChange}
                            required
                            placeholder="City"
                          />
                        </div>
                        <div className="form-group col-md-4 ">
                          <label>Pincode </label>
                          <input
                            name="clientPincode2"
                            type="number"
                            className="form-control"
                            value={formData.clientPincode2}
                            onChange={handleChange}
                            required
                            placeholder="Pincode"
                          />
                        </div>
                      </div>
                    </div>
                  )}


                  {/* Bank Details Radio Button */}
                  <div className="form-group">
                    <label>Bank Details:</label>
                    <div>
                      <label className="radio-inline mr-2">
                        <input
                          type="radio"
                          name="showBankDetails"
                          value="yes"
                          checked={formData.showBankDetails}
                          onChange={handleRadioChange}
                        /> Yes
                      </label>
                      <label className="radio-inline">
                        <input
                          type="radio"
                          name="showBankDetails"
                          value="no"
                          checked={!formData.showBankDetails}
                          onChange={handleRadioChange}
                        /> No
                      </label>
                    </div>
                  </div>
                  {/* Conditional Bank Details fields */}
                  {formData.showBankDetails && (
                    <div>
                      {/* Bank Details fields... */}
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>Bank Name <span style={{ color: "red" }}>*</span></label>
                          <input
                            name="bankName"
                            type="text"
                            className="form-control"
                            value={formData.bankName}
                            onChange={handleChange}
                            required
                            placeholder="Bank Name"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label>Account No. <span style={{ color: "red" }}>*</span></label>
                          <input
                            name="accountNo"
                            type="number"
                            className="form-control"
                            value={formData.accountNo}
                            onChange={handleChange}
                            required
                            placeholder="Account No."
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label>IFSC Code <span style={{ color: "red" }}>*</span></label>
                          <input
                            name="ifscCode"
                            type="text"
                            className="form-control"
                            value={formData.ifscCode}
                            onChange={handleChange}
                            required
                            placeholder="IFSC Code"
                          />
                          {ifscWarning && <small className="text-danger">{ifscWarning}</small>}
                        </div>
                        <div className="form-group col-md-6">
                          <label>Branch <span style={{ color: "red" }}>*</span></label>
                          <input
                            name="branch"
                            type="text"
                            className="form-control"
                            value={formData.branch}
                            onChange={handleChange}
                            required
                            placeholder="Branch"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <label>Remark</label>
                    <textarea
                      name="remark"
                      className="form-control"
                      rows="5"
                      value={formData.remark}
                      onChange={handleChange}
                      placeholder="Remark"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label>Upload Document</label>
                    <input
                      name="upload"
                      type="file"
                      className="form-control-file"
                      onChange={(e) => handleImageChange(e,"upload")}
                    />
                    <small>Max - 200KB</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary" id="save">
                Save
              </button>
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditClientModal;












