import React, { useState, useEffect } from "react";
import axios from "axios";
import AddOutward from "./AddOutWard";
import OutwardDesc from "./OutwardDesc";
import EditOutward from './EditOutward';
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function OutwardList({ handleLogout, username }) {
    const [outwards, setOutwards] = useState([]);
    const [selectedOutward, setSelectedOutward] = useState(null);
    const [showOutwardDetails, setShowOutwardDetails] = useState(false);
    const [isAddOutwardModalOpen, setIsAddOutwardModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editOutward, setEditOutward] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteOutward, setDeleteOutward] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    useEffect(() => {
        fetchOutwards();
    }, []);

    const fetchOutwards = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/outwards`);
            setOutwards(response.data);
        } catch (error) {
            console.error("Error fetching outwards:", error);
        }
    };

    const handleAddOutward = () => {
        setIsAddOutwardModalOpen(true);
    };

    const handleCloseOutwardModal = () => {
        setIsAddOutwardModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleOutwardDetails = (outward) => {
        setSelectedOutward(outward);
        setShowOutwardDetails(true);
    };

    const handleEditOutwardClick = (outward) => {
        setEditOutward(outward);
        setSelectedOutward(outward);
        setIsEditModalOpen(true);
    };

    const handleEditOutwardClose = () => {
        setSelectedOutward(null);
    };

    const handleBackToTable = () => {
        setSelectedOutward(null);
        setShowOutwardDetails(false);
    };

    const handleDeleteOutward = (outward) => {
        setDeleteOutward(outward);
        setIsDeleteModalOpen(true);
    };

    const handleUpdateOutward = async (updatedOutward) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/outwards/${updatedOutward.id}`, updatedOutward);
            console.log("Outward updated:", response.data);
            const updatedOutwards = outwards.map(outward => (outward.id === updatedOutward.id ? response.data : outward));
            setOutwards(updatedOutwards);
        } catch (error) {
            console.error("Error updating outward:", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/outwards/${deleteOutward.id}`);
            const deletedOutward = { ...deleteOutward, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedOutward);
            setOutwards(prevOutwards => prevOutwards.filter(outward => outward.id !== deleteOutward.id));
            setIsDeleteModalOpen(false);
            console.log("Outward deleted successfully");
        } catch (error) {
            console.error("Error deleting outward:", error);
        }
    };

    const handleUpdateOutwards = () => {
        toast.success("Successfully uploaded");
        fetchOutwards();
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = outwards.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100 '>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {!showOutwardDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            Outward List
                                        </h6>
                                        <div className="d-flex gap-2">
                                        <button onClick={(e)=>window.print()} className="btn btn-outline-success">
                                                <i className="fa fa-download"></i> 
                                            </button>
                                            <button onClick={handleAddOutward} className="btn btn-outline-primary">
                                            Add New Outward
                                        </button>

                                        </div>

                                        
                                    </div>
                                    <div
                                        className="card-body"
                                        style={{ height: "calc(100% - 40px)" }}
                                    >
                                        <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>client Name</th>
                                                        <th>Consignment No.</th>
                                                        <th>courier Company</th>
                                                        <th>Received Date</th>
                                                        <th>Consignment Subject</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <style>
                                                        {`.hyperlink:hover {color: blue;}`}
                                                    </style>
                                                    {currentItems.map((outward, index) => (
                                                        <tr key={index}>
                                                            <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleOutwardDetails(outward)}> {outward.entityName}</td>
                                                            <td>{outward.consignmentNo}</td>
                                                            <td>{outward.courierCompany}</td>
                                                            <td>{formatDate(outward.consignmentdate)}</td>
                                                            <td>{outward.consignmentType}</td>
                                                            <td>
                                                                <div className="btn-group">
                                                                    <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu actionmenu" x-placement="bottom-start">
                                                                        <a className="dropdown-item" href="#" onClick={() => handleOutwardDetails(outward)}><i className="fa fa-file"></i> Detail</a>
                                                                        <a className="dropdown-item" href="#" onClick={() => handleEditOutwardClick(outward)}><i className="fas fa-edit"></i> Edit</a>
                                                                        <a className="dropdown-item" href="#" onClick={() => handleDeleteOutward(outward)}><i className="fa fa-trash"></i> Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(outwards.length / itemsPerPage) }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(outwards.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showOutwardDetails && selectedOutward && (
                        <OutwardDesc
                            outward={selectedOutward}
                            onClose={handleBackToTable}
                        />
                    )}
                    {selectedOutward && !showOutwardDetails && (
                        <EditOutward outward={selectedOutward} onClose={handleEditOutwardClose}  onUpdate={handleUpdateOutwards} />
                    )}
                    {isAddOutwardModalOpen && <AddOutward onClose={handleCloseOutwardModal} onUpdate={handleUpdateOutwards} />}
                    <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        itemName={deleteOutward ? deleteOutward.entityName : ""}
                        onDelete={handleDeleteConfirmation}
                        onClose={() => setIsDeleteModalOpen(false)}
                        deleteReason={deleteReason}
                        setDeleteReason={setDeleteReason}
                    />
                </div>
            </div>
        </div>
    );
}

export default OutwardList;
