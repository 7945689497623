import React, { useState } from 'react';
import axios from 'axios';

const AddCourier = ({ onClose, onUpdate }) => {
    const [courierCompanyName, setCourierCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [phoneWarning, setPhoneWarning] = useState("");
    


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "phone") {
            setPhoneWarning(value.length !== 10 ? "Phone number must be 10 digits" : "");
          }

        switch (name) {
            case 'courierCompanyName':
                setCourierCompanyName(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        const requiredFields = ["courierCompanyName", "address", "phone"];
        for (const field of requiredFields) {
            if (!eval(field)) {
                setError(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
                setIsLoading(false);
                return;
            }
        }

        setError("");

        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/courier`, {
                courierCompanyName,
                address,
                phone,
                username:localStorage.getItem('username'),
            });
            console.log('Courier added successfully:', response.data);
            onClose();
            onUpdate();
        } catch (error) {
            console.error('Error adding courier:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="addCourier" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Courier</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body">
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="form-group">
                                <label>Courier Company Name<span style={{ color: "red" }}>*</span></label>
                                <input name="courierCompanyName" value={courierCompanyName} onChange={handleChange} type="text" className="form-control" required placeholder="Courier Company Name" />
                            </div>
                            <div className="form-group">
                                <label>Address<span style={{ color: "red" }}>*</span></label>
                                <input name="address" value={address} onChange={handleChange} type="email" className="form-control" required placeholder="Address" />
                            </div>
                            <div className="form-group">
                                <label>Phone<span style={{ color: "red" }}>*</span></label>
                                <input name="phone"  value={phone} onChange={handleChange} type="number" className="form-control" required placeholder="Phone" />
                                {phoneWarning && <small className="text-danger">{phoneWarning}</small>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Save</button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
            {isLoading && <div className="loader">Loading...</div>}
        </div>
    );
};

export default AddCourier;
