import React, { useState, useEffect } from "react";
import axios from "axios";
import AddInquiry from "./AddInquiry";
import InquiryDesc from "./InquiryDesc";
import EditInquiry from './NextInquiry';
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ConvertedList({ handleLogout, username }) {
    const [inquiries, setInquiries] = useState([]);
    const [selectedInquiry, setSelectedInquiry] = useState(null);
    const [showInquiryDetails, setShowInquiryDetails] = useState(false);
    const [isAddInquiryModalOpen, setIsAddInquiryModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editInquiry, setEditInquiry] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [deleteInquiry, setDeleteInquiry] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    useEffect(() => {
        fetchInquiries();
    }, []);

    const fetchInquiries = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/inquiries`);
            const sortedInquiries = response.data.filter(item => item.status === "converted");
            setInquiries(sortedInquiries);
        } catch (error) {
            console.error("Error fetching inquiries:", error);
        }
    };

    const handleAddInquiry = () => {
        setIsAddInquiryModalOpen(true);
    };

    const handleCloseInquiryModal = () => {
        setIsAddInquiryModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleInquiryDetails = (inquiry) => {
        setSelectedInquiry(inquiry);
        setShowInquiryDetails(true);
    };

    const handleEditInquiryClick = (inquiry) => {
        setEditInquiry(inquiry);
        setSelectedInquiry(inquiry);
        setIsEditModalOpen(true);
    };

    const handleEditInquiryClose = () => {
        setSelectedInquiry(null);
    };

    const handleBackToTable = () => {
        setSelectedInquiry(null);
        setShowInquiryDetails(false);
    };

    const handleDeleteInquiry = (inquiry) => {
        setDeleteInquiry(inquiry);
        setIsDeleteModalOpen(true);
    };

    const handleUpdateInquiry = async (updatedInquiry) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/converted_inquiries/${updatedInquiry.id}`, updatedInquiry);
            console.log("Inquiry updated:", response.data);
            const updatedInquiryList = inquiries.map(inq => (inq.id === updatedInquiry.id ? response.data : inq));
            const sortedInquiries = updatedInquiryList.sort((a, b) => b.id - a.id);
            setInquiries(sortedInquiries);
        } catch (error) {
            console.error("Error updating inquiry:", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/converted_inquiries/${deleteInquiry.id}`);

            const deletedInquiry = { ...deleteInquiry, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedInquiry);

            setInquiries((prevInquiries) =>
                prevInquiries.filter((inq) => inq.id !== deleteInquiry.id)
            );
            setIsDeleteModalOpen(false);

            console.log("Inquiry deleted successfully");
        } catch (error) {
            console.error("Error deleting inquiry:", error);
        }
    };

    const handleUpdateInquiryList = async (newInquiry) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/converted_inquiries`, newInquiry);
            const newInquiryEntry = response.data;
            setInquiries((prevInquiries) => [newInquiryEntry, ...prevInquiries]);
            toast.success("Converted inquiry entry added successfully");
        } catch (error) {
            console.error("Error adding inquiry:", error);
        }
    };
    const onUpdateinquery =()=>{
        toast.success("Data Uploaded Successfully")
        fetchInquiries();
    }
    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = inquiries.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {!showInquiryDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            Converted Inquiries List
                                        </h6>
                                        <div className="d-flex gap-2">
                                            <button onClick={(e) => window.print()} className="btn btn-outline-success">
                                                <i className="fa fa-download"></i>
                                            </button>
                                            <button onClick={handleAddInquiry} className="btn btn-outline-primary">
                                                Add New Inquiry
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ height: "calc(100% - 40px)" }}>
                                        <table id="data" className="table table-striped table-bordered" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Customer Name</th>
                                                    <th>Conversion Date</th>
                                                    <th>Mobile No</th>
                                                    <th>Executive Name</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <style>
                                                    {`.hyperlink:hover {color: blue;}`}
                                                </style>
                                                {currentItems.map((inquiry, index) => (
                                                    <tr key={index}>
                                                        <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleInquiryDetails(inquiry)}>{inquiry.customerName}</td>
                                                        <td>{formatDate(inquiry.conversionDate)}</td>
                                                        <td>{inquiry.mobileNo}</td>
                                                        <td>{inquiry.callAttendExecutiveName}</td>
                                                        <td>{inquiry.amount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(inquiries.length / itemsPerPage) }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(inquiries.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showInquiryDetails && selectedInquiry && (
                        <InquiryDesc
                            inquiry={selectedInquiry}
                            onClose={handleBackToTable}
                        />
                    )}
                    {selectedInquiry && !showInquiryDetails && (
                        <EditInquiry inquiry={selectedInquiry} onClose={handleEditInquiryClose} onUpdate={handleUpdateInquiry} />
                    )}
                    {isAddInquiryModalOpen && <AddInquiry onClose={handleCloseInquiryModal} onUpdate={onUpdateinquery} />}
                    <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        itemName={deleteInquiry ? deleteInquiry.clientName : ""}
                        onDelete={handleDeleteConfirmation}
                        onClose={() => setIsDeleteModalOpen(false)}
                        deleteReason={deleteReason}
                        setDeleteReason={setDeleteReason}
                    />
                </div>
            </div>
        </div>
    );
}

export default ConvertedList;
