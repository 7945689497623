import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddServiceProductModal = ({ onClose, onUpdate }) => {
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [remark, setRemark] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [lastServiceProductId, setLastServiceProductId] = useState(0);

    useEffect(() => {
        fetchLastServiceProductId();
    }, []);

    const fetchLastServiceProductId = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/lastServiceProductId`);
            setLastServiceProductId(parseInt(response.data.lastServiceProductId));
        } catch (error) {
            console.error('Error fetching last Service/Product ID:', error);
        }
    };

    const generateCode = (type) => {
        const currentYear = new Date().getFullYear() % 100;
        const id = lastServiceProductId + 1;
        if (type === 'Service') {
            return `SERV${currentYear}${id.toString().padStart(3, '0')}`;
        } else if (type === 'Product') {
            return `PROD${currentYear}${id.toString().padStart(3, '0')}`;
        }
        return '';
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'remark':
                setRemark(value);
                break;
            case 'type':
                setType(value);
                setCode(generateCode(value)); // Generate code based on the selected type
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        const requiredFields = ["name", "code"];
        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/service_product`, {
                type,
                name,
                code,
                remark,
                username:localStorage.getItem('username'),
            });
            console.log('Added successfully:', response.data);
            onClose();
            onUpdate();
        } catch (error) {
            console.error('Error adding service/product:', error);
            setError('Failed to add service/product');
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="addServiceProductModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Service/Product</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="form-group">
                                <label>Choose Type<span style={{ color: "red" }}>*</span></label>
                                <select name="type" value={type} onChange={handleChange} className="form-control" required>
                                    <option value="" disabled>Select Type</option>
                                    <option value="Service">Service</option>
                                    <option value="Product">Product</option>
                                </select>
                            </div>
                            {type && (
                                <div>
                                    <div className="form-group">
                                        <label>{type === 'Service' ? 'Service Name' : 'Product Name'}<span style={{ color: "red" }}>*</span></label>
                                        <input name="name" value={name} onChange={handleChange} type="text" className="form-control" required placeholder={`${type} Name`} />
                                    </div>
                                    <div className="form-group">
                                        <label>{type === 'Service' ? 'Service Code' : 'Product Code'}<span style={{ color: "red" }}>*</span></label>
                                        <input name="code" value={code} onChange={handleChange} type="text" className="form-control" required placeholder={`${type} Code`} readOnly />
                                    </div>
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea name="remark" value={remark} onChange={handleChange} className="form-control" placeholder="Remark"></textarea>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" id="saveServiceProduct">Save</button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
            {isLoading && <div className="loader">Loading...</div>}
        </div>
    );
};

export default AddServiceProductModal;
