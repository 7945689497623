import React, { useState, useEffect } from "react";
import imageCompression from 'browser-image-compression';
import axios from "axios";

const EditEmployeeModal = ({ onClose, onUpdate, employee }) => {
    const [editedEmployee, setEditedEmployee] = useState(employee);
    const [errors, setErrors] = useState({});
    const [states, setStates] = useState([]);
    const apiUrl = process.env.REACT_APP_LOCAL_URL;

    useEffect(() => {
        fetchStates();
    }, []);

    useEffect(() => {
        if (employee) {
            setEditedEmployee(employee);
        }
    }, [employee]);

    const fetchStates = async () => {
        try {
            const response = await axios.get(`${apiUrl}/states`);
            setStates(response.data);
        } catch (error) {
            console.error("Error fetching states:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedEmployee({ ...editedEmployee, [name]: value });
    };

    const handleImageChange = async (e, fieldName) => {
        const file = e.target.files[0];
        try {
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };
            const compressedFile = await imageCompression(file, options);
            const fileWithMetaData = new File([compressedFile], file.name, { type: compressedFile.type });
            setEditedEmployee({ ...editedEmployee, [fieldName]: fileWithMetaData }); // Set the corresponding field to the compressed file
        } catch (error) {
            console.error("Error compressing image:", error);
        }
    };
    
    const validate = () => {
        let formErrors = {};
        // Validation for employee information
        // Implement your validation rules here
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validate()) {
            return;
        }
    
        const data = new FormData();
        Object.keys(editedEmployee).forEach(key => {
            // Check if the key is an image field and if its value is not null
            if (key === 'upload1' || key === 'upload2') {
                if (editedEmployee[key]) {
                    data.append(key, editedEmployee[key]);
                } else if (employee[key]) {
                    // Append the existing image data if no new image is uploaded
                    data.append(key, employee[key]);
                }
            } else {
                data.append(key, editedEmployee[key]);
            }
        });
    
        try {
            // Update existing employee
            const response = await axios.put(`${apiUrl}/employeeData/${editedEmployee.id}`, data);
            onClose();
            onUpdate();
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };
    
    const handleClose = () => {
        onClose();
    };

    return (
        <div className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form id="formAddEmployee" autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Employee</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            <div className="form-row">
                                {/* Input fields remain the same */}

                                {/* Employee Name */}
                                <div className="form-group col-md-6">
                                    <label>Employee Name <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="ename"
                                        type="text"
                                        className={`form-control ${errors.ename ? 'is-invalid' : ''}`}
                                        value={editedEmployee.ename}
                                        onChange={handleChange}
                                        placeholder="Employee Name"
                                    />
                                    {errors.ename && <small className="text-danger">{errors.ename}</small>}
                                </div>

                                {/* Employee Code */}
                                <div className="form-group col-md-6">
                                    <label>Employee Code <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="ecode"
                                        type="text"
                                        className={`form-control ${errors.ecode ? 'is-invalid' : ''}`}
                                        value={editedEmployee.ecode}
                                        onChange={handleChange}
                                        placeholder="Employee Code"
                                    />
                                    {errors.ecode && <small className="text-danger">{errors.ecode}</small>}
                                </div>

                                {/* Father's Name */}
                                <div className="form-group col-md-6">
                                    <label>Father's Name <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="fatherName"
                                        type="text"
                                        className={`form-control ${errors.fatherName ? 'is-invalid' : ''}`}
                                        value={editedEmployee.fatherName}
                                        onChange={handleChange}
                                        placeholder="Father's Name"
                                    />
                                    {errors.fatherName && <small className="text-danger">{errors.fatherName}</small>}
                                </div>

                                {/* Mother's Name */}
                                <div className="form-group col-md-6">
                                    <label>Mother's Name <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="motherName"
                                        type="text"
                                        className={`form-control ${errors.motherName ? 'is-invalid' : ''}`}
                                        value={editedEmployee.motherName}
                                        onChange={handleChange}
                                        placeholder="Mother's Name"
                                    />
                                    {errors.motherName && <small className="text-danger">{errors.motherName}</small>}
                                </div>

                                {/* Marital Status */}
                                <div className="form-group col-md-12">
                                    <label>Marital Status <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="maritalStatus"
                                        className={`form-control ${errors.maritalStatus ? 'is-invalid' : ''}`}
                                        value={editedEmployee.maritalStatus}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    {errors.maritalStatus && <small className="text-danger">{errors.maritalStatus}</small>}
                                </div>
                                {/* Spouse Name */}
                                {editedEmployee.maritalStatus === "Yes" && (
                                    <div className="form-group col-md-12">
                                        <label>Spouse Name <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="spouseName"
                                            type="text"
                                            className="form-control"
                                            value={editedEmployee.spouseName}
                                            onChange={handleChange}
                                            placeholder="Spouse Name"
                                        />
                                    </div>
                                )}

                                {/* Add more input fields here */}
                                {/* Mobile Number */}
                                <div className="form-group col-md-4">
                                    <label>Mobile Number <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="mobileNumber"
                                        type="tel"
                                        className={`form-control ${errors.mobileNumber ? 'is-invalid' : ''}`}
                                        value={editedEmployee.mobileNumber}
                                        onChange={handleChange}
                                        placeholder="Mobile Number"
                                    />
                                    {errors.mobileNumber && <small className="text-danger">{errors.mobileNumber}</small>}
                                </div>

                                {/* Email ID */}
                                <div className="form-group col-md-4">
                                    <label>Email ID <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="eemail"
                                        type="email"
                                        className={`form-control ${errors.eemail ? 'is-invalid' : ''}`}
                                        value={editedEmployee.eemail}
                                        onChange={handleChange}
                                        placeholder="Email ID"
                                    />
                                    {errors.eemail && <small className="text-danger">{errors.eemail}</small>}
                                </div>

                                {/* Date of Birth */}
                                <div className="form-group col-md-4">
                                    <label>Date of Birth <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="dateOfBirth"
                                        type="date"
                                        className={`form-control ${errors.dateOfBirth ? 'is-invalid' : ''}`}
                                        value={editedEmployee.dateOfBirth}
                                        onChange={handleChange}
                                    />
                                    {errors.dateOfBirth && <small className="text-danger">{errors.dateOfBirth}</small>}
                                </div>

                                {/* Blood Group */}
                                <div className="form-group col-md-4">
                                    <label>Blood Group</label>
                                    <select
                                        name="bloodGroup"
                                        className="form-control"
                                        value={editedEmployee.bloodGroup}
                                        onChange={handleChange}
                                    >
                                        <option value="" disabled hidden>Select Blood Group</option>
                                        <option value="A+">A+</option>
                                        <option value="A-">A-</option>
                                        <option value="B+">B+</option>
                                        <option value="B-">B-</option>
                                        <option value="AB+">AB+</option>
                                        <option value="AB-">AB-</option>
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                        <option value="DontKnow">Don't Know</option>
                                    </select>
                                </div>


                                {/* PAN No */}
                                <div className="form-group col-md-4">
                                    <label>PAN No <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="panNo"
                                        type="text"
                                        className={`form-control ${errors.panNo ? 'is-invalid' : ''}`}
                                        value={editedEmployee.panNo}
                                        onChange={handleChange}
                                        placeholder="PAN No"
                                    />
                                    {errors.panNo && <small className="text-danger">{errors.panNo}</small>}
                                </div>

                                {/* Aadhar Card Number */}
                                <div className="form-group col-md-4">
                                    <label>Aadhar Card Number <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="aadharCardNumber"
                                        type="text"
                                        className={`form-control ${errors.aadharCardNumber ? 'is-invalid' : ''}`}
                                        value={editedEmployee.aadharCardNumber}
                                        onChange={handleChange}
                                        placeholder="Aadhar Card Number"
                                    />
                                    {errors.aadharCardNumber && <small className="text-danger">{errors.aadharCardNumber}</small>}
                                </div>
                                <hr />

                                {/* Current Address */}

                                <div className="form-group col-md-12">
                                    <label>Current Address <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="currentAddress"
                                        type="text"
                                        className={`form-control ${errors.currentAddress ? 'is-invalid' : ''}`}
                                        value={editedEmployee.currentAddress}
                                        onChange={handleChange}
                                        placeholder="Current Address"
                                    />
                                    {errors.currentAddress && <small className="text-danger">{errors.currentAddress}</small>}
                                </div>

                                {/* Current State */}
                                <div className="form-group col-md-4">
                                    <label>Current State <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="currentState"
                                        className={`form-control ${errors.currentState ? 'is-invalid' : ''}`}
                                        value={editedEmployee.currentState}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select State</option>
                                        {states.map(state => (
                                            <option key={state.id} value={state.statename}>{state.statename}</option>
                                        ))}
                                    </select>
                                    {errors.currentState && <small className="text-danger">{errors.currentState}</small>}
                                </div>

                                {/* Current City */}
                                <div className="form-group col-md-4">
                                    <label>Current City <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="currentCity"
                                        type="text"
                                        className={`form-control ${errors.currentCity ? 'is-invalid' : ''}`}
                                        value={editedEmployee.currentCity}
                                        onChange={handleChange}
                                        placeholder="Current City"
                                    />
                                    {errors.currentCity && <small className="text-danger">{errors.currentCity}</small>}
                                </div>

                                {/* Current Pincode */}
                                <div className="form-group col-md-4">
                                    <label>Current Pincode <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="currentPincode"
                                        type="text"
                                        className={`form-control ${errors.currentPincode ? 'is-invalid' : ''}`}
                                        value={editedEmployee.currentPincode}
                                        onChange={handleChange}
                                        placeholder="Current Pincode" />
                                    {errors.currentPincode && <small className="text-danger">{errors.currentPincode}</small>}
                                </div>
                                <hr />

                                {/* Permanent Address */}

                                <div className="form-group col-md-12">
                                    <label>Permanent Address  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="permanentAddress"
                                        type="text"
                                        className={`form-control ${errors.permanentAddress ? 'is-invalid' : ''}`}
                                        value={editedEmployee.permanentAddress}
                                        onChange={handleChange}
                                        placeholder="Permanent Address"
                                    />
                                    {errors.permanentAddress && <small className="text-danger">{errors.permanentAddress}</small>}
                                </div>

                                {/* Permanent State */}
                                <div className="form-group col-md-4">
                                    <label>Permanent State  <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="permanentState"
                                        className={`form-control ${errors.permanentState ? 'is-invalid' : ''}`}
                                        value={editedEmployee.permanentState}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select State</option>
                                        {states.map(state => (
                                            <option key={state.id} value={state.statename}>{state.statename}</option>
                                        ))}
                                    </select>
                                    {errors.permanentState && <small className="text-danger">{errors.permanentState}</small>}
                                </div>

                                {/* Permanent City */}
                                <div className="form-group col-md-4">
                                    <label>Permanent City  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="permanentCity"
                                        type="text"
                                        className={`form-control ${errors.permanentCity ? 'is-invalid' : ''}`}
                                        value={editedEmployee.permanentCity}
                                        onChange={handleChange}
                                        placeholder="Permanent City"
                                    />
                                    {errors.permanentCity && <small className="text-danger">{errors.permanentCity}</small>}
                                </div>

                                {/* Permanent Pincode */}
                                <div className="form-group col-md-4">
                                    <label>Permanent Pincode  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="permanentPincode"
                                        type="text"
                                        className={`form-control ${errors.permanentPincode ? 'is-invalid' : ''}`}
                                        value={editedEmployee.permanentPincode}
                                        onChange={handleChange}
                                        placeholder="Permanent Pincode"
                                    />
                                    {errors.permanentPincode && <small className="text-danger">{errors.permanentPincode}</small>}
                                </div>

                                {/* Joining Date */}
                                <label htmlFor="">Joining Details :-   <span style={{ color: "red" }}>*</span></label>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Joining Date  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="joiningDate"
                                            type="date"
                                            className={`form-control ${errors.joiningDate ? 'is-invalid' : ''}`}
                                            value={editedEmployee.joiningDate}
                                            onChange={handleChange}
                                        />
                                        {errors.joiningDate && <small className="text-danger">{errors.joiningDate}</small>}
                                    </div>

                                    {/* Current Salary */}
                                    <div className="form-group col-md-6">
                                        <label>Current Salary  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="currentSalary"
                                            type="number"
                                            className={`form-control ${errors.currentSalary ? 'is-invalid' : ''}`}
                                            value={editedEmployee.currentSalary}
                                            onChange={handleChange}
                                            placeholder="Current Salary"
                                        />
                                        {errors.currentSalary && <small className="text-danger">{errors.currentSalary}</small>}
                                    </div>

                                    {/* Designation */}
                                    <div className="form-group col-md-6">
                                        <label>Designation  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="designation"
                                            type="text"
                                            className={`form-control ${errors.designation ? 'is-invalid' : ''}`}
                                            value={editedEmployee.designation}
                                            onChange={handleChange}
                                            placeholder="Designation"
                                        />
                                        {errors.designation && <small className="text-danger">{errors.designation}</small>}
                                    </div>

                                    {/* Department */}

                                    <div className="form-group col-md-6">
                                        <label>Department  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="department"
                                            type="text"
                                            className={`form-control ${errors.department ? 'is-invalid' : ''}`}
                                            value={editedEmployee.department}
                                            onChange={handleChange}
                                            placeholder="Department"
                                        />
                                        {errors.department && <small className="text-danger">{errors.department}</small>}
                                    </div>
                                </div>
                                <label htmlFor="">Bank Details :-  <span style={{ color: "red" }}>*</span></label>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Account Name  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="accountName"
                                            type="text"
                                            className={`form-control ${errors.accountName ? 'is-invalid' : ''}`}
                                            value={editedEmployee.accountName}
                                            onChange={handleChange}
                                            placeholder="Account Number"
                                        />
                                        {errors.accountName && <small className="text-danger">{errors.accountName}</small>}
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label>Bank Relation  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="accountRelation"
                                            type="text"
                                            className={`form-control ${errors.accountRelation ? 'is-invalid' : ''}`}
                                            value={editedEmployee.accountRelation}
                                            onChange={handleChange}
                                            placeholder="Account Number"
                                        />
                                        {errors.accountRelation && <small className="text-danger">{errors.accountRelation}</small>}
                                    </div>

                                    {/* Bank Name */}
                                    <div className="form-group col-md-6">
                                        <label>Bank Name  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="bankName"
                                            type="text"
                                            className={`form-control ${errors.bankName ? 'is-invalid' : ''}`}
                                            value={editedEmployee.bankName}
                                            onChange={handleChange}
                                            placeholder="Bank Name"
                                        />
                                        {errors.bankName && <small className="text-danger">{errors.bankName}</small>}
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label>Bank Account Number  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="accountNumber"
                                            type="number"
                                            className={`form-control ${errors.accountNumber ? 'is-invalid' : ''}`}
                                            value={editedEmployee.accountNumber}
                                            onChange={handleChange}
                                            placeholder="Account Number"
                                        />
                                        {errors.accountNumber && <small className="text-danger">{errors.accountNumber}</small>}
                                    </div>


                                    {/* Bank Branch */}
                                    <div className="form-group col-md-6">
                                        <label>Branch  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="branch"
                                            type="text"
                                            className={`form-control ${errors.branch ? 'is-invalid' : ''}`}
                                            value={editedEmployee.branch}
                                            onChange={handleChange}
                                            placeholder="Branch"
                                        />
                                        {errors.branch && <small className="text-danger">{errors.branch}</small>}
                                    </div>

                                    {/* IFSC Code */}
                                    <div className="form-group col-md-6">
                                        <label>IFSC Code  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="ifscCode"
                                            type="text"
                                            className={`form-control ${errors.ifscCode ? 'is-invalid' : ''}`}
                                            value={editedEmployee.ifscCode}
                                            onChange={handleChange}
                                            placeholder="IFSC Code"
                                        />
                                        {errors.ifscCode && <small className="text-danger">{errors.ifscCode}</small>}
                                    </div>
                                </div>

                                {/* Emergency Contact Name */}
                                <div className="form-group col-md-4">
                                    <label>Emergency Contact Name  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="emergencyContactName"
                                        type="text"
                                        className={`form-control ${errors.emergencyContactName ? 'is-invalid' : ''}`}
                                        value={editedEmployee.emergencyContactName}
                                        onChange={handleChange}
                                        placeholder="Emergency Contact Name"
                                    />
                                    {errors.emergencyContactName && <small className="text-danger">{errors.emergencyContactName}</small>}
                                </div>

                                {/* Emergency Contact Number */}
                                <div className="form-group col-md-4">
                                    <label>Emergency Contact Number  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="emergencyContactNumber"
                                        type="tel"
                                        className={`form-control ${errors.emergencyContactNumber ? 'is-invalid' : ''}`}
                                        value={editedEmployee.emergencyContactNumber}
                                        onChange={handleChange}
                                        placeholder="Emergency Contact Number"
                                    />
                                    {errors.emergencyContactNumber && <small className="text-danger">{errors.emergencyContactNumber}</small>}
                                </div>

                                {/* Emergency Contact Relation */}
                                <div className="form-group col-md-4">
                                    <label>Emergency Contact Relation  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="emergencyContactRelation"
                                        type="text"
                                        className={`form-control ${errors.emergencyContactRelation ? 'is-invalid' : ''}`}
                                        value={editedEmployee.emergencyContactRelation}
                                        onChange={handleChange}
                                        placeholder="Emergency Contact Relation"
                                    />
                                    {errors.emergencyContactRelation && <small className="text-danger">{errors.emergencyContactRelation}</small>}
                                </div>


                                {/* Additional Information */}
                                <div className="form-group col-md-12">
                                    <label>Additional Information</label>
                                    <textarea
                                        name="additionalInformation"
                                        className="form-control"
                                        value={editedEmployee.additionalInformation}
                                        onChange={handleChange}
                                        placeholder="Additional Information"
                                    />
                                </div>
                                {/* Upload */}
                                <div className="form-group col-md-6">
                                    <label>Upload image  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="upload1"
                                        type="file"
                                        className={`form-control-file ${errors.upload1 ? 'is-invalid' : ''}`}
                                        onChange={(e) => handleImageChange(e, "upload1")} // Pass the field name as a parameter
                                    />
                                    <small>Max Size: 200KB</small>
                                    {errors.upload1 && <small className="text-danger">{errors.upload1}</small>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Upload Document</label>
                                    <input
                                        name="upload2"
                                        type="file"
                                        className="form-control-file"
                                        onChange={(e) => handleImageChange(e, "upload2")} // Pass the field name as a parameter
                                    />
                                    <small>Max Size: 200KB</small>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                            <button type="submit" className="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditEmployeeModal;    
