import React, { useState, useEffect } from "react";
import axios from "axios";
import AddCourier from "./AddCourier";
import CourierDesc from "./CourierDesc";
import EditCourier from './EditCourier';
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CourierList({ handleLogout, username }) {
    const [couriers, setCouriers] = useState([]);
    const [selectedCourier, setSelectedCourier] = useState(null);
    const [showCourierDetails, setShowCourierDetails] = useState(false);
    const [isAddCourierModalOpen, setIsAddCourierModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editCourier, setEditCourier] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [deleteCourier, setDeleteCourier] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    useEffect(() => {
        fetchCouriers();
    }, []);

    const fetchCouriers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/couriers`);
            setCouriers(response.data);
        } catch (error) {
            console.error("Error fetching couriers:", error);
        }
    };

    const handleAddCourier = () => {
        setIsAddCourierModalOpen(true);
    };

    const handleCloseCourierModal = () => {
        setIsAddCourierModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleCourierDetails = (courier) => {
        setSelectedCourier(courier);
        setShowCourierDetails(true);
    };

    const handleEditCourierClick = (courier) => {
        setEditCourier(courier);
        setSelectedCourier(courier);
        setIsEditModalOpen(true);
    };

    const handleEditCourierClose = () => {
        setSelectedCourier(null);
    };

    const handleBackToTable = () => {
        setSelectedCourier(null);
        setShowCourierDetails(false);
    };

    const handleDeleteCourier = (courier) => {
        setDeleteCourier(courier);
        setIsDeleteModalOpen(true);
    };

    const handleUpdateCourier = async (updatedCourier) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/couriers/${updatedCourier.id}`, updatedCourier);
            console.log("Courier updated:", response.data);
            const updatedCouriers = couriers.map(courier => (courier.id === updatedCourier.id ? response.data : courier));
            setCouriers(updatedCouriers);
        } catch (error) {
            console.error("Error updating courier:", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/couriers/${deleteCourier.id}`);
            const deletedCourier = { ...deleteCourier, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedCourier);
            setCouriers(prevCouriers => prevCouriers.filter(courier => courier.id !== deleteCourier.id));
            setIsDeleteModalOpen(false);
            console.log("Courier deleted successfully");
        } catch (error) {
            console.error("Error deleting courier:", error);
        }
    };

    const handleUpdateCouriers = () => {
        toast.success("Successfully uploaded");
        fetchCouriers();
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = couriers.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100 '>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {!showCourierDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            Courier List
                                        </h6>
                                        <div className="d-flex gap-2">
                                        <button onClick={(e)=>window.print()} className="btn btn-outline-success">
                                                <i className="fa fa-download"></i> 
                                            </button>
                                            <button onClick={handleAddCourier} className="btn btn-outline-primary">
                                            Add New Courier
                                        </button>

                                        </div>
                                        
                                    </div>
                                    <div className="card-body">
                                        <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Courier Name</th>
                                                        <th>Address</th>
                                                        <th>Contact No.</th>
                                                        {/* <th>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentItems.map((courier, index) => (
                                                        <tr key={index}>
                                                            <td>{courier.courierCompanyName}</td>
                                                            <td>{courier.address}</td>
                                                            <td>{courier.phone}</td>
                                                            {/* <td>
                                                            <div className="btn-group">
                                                                <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                </button>
                                                                <div className="dropdown-menu actionmenu" x-placement="bottom-start">
                                                                    <a className="dropdown-item" href="#" onClick={() => handleCourierDetails(courier)}><i className="fa fa-file"></i> Detail</a>
                                                                    <a className="dropdown-item" href="#" onClick={() => handleEditCourierClick(courier)}><i className="fas fa-edit"></i> Edit</a>
                                                                    <a className="dropdown-item" href="#" onClick={() => handleDeleteCourier(courier)}><i className="fa fa-trash"></i> Delete</a>
                                                                </div>
                                                            </div>
                                                        </td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(couriers.length / itemsPerPage) }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(couriers.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showCourierDetails && selectedCourier && (
                        <CourierDesc
                            courier={selectedCourier}
                            onClose={handleBackToTable}
                        />
                    )}
                    {selectedCourier && !showCourierDetails && (
                        <EditCourier courier={selectedCourier} onClose={handleEditCourierClose} onUpdate={handleUpdateCourier} />
                    )}
                    {isAddCourierModalOpen && <AddCourier onClose={handleCloseCourierModal} onUpdate={handleUpdateCouriers} />}
                    <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        itemName={deleteCourier ? deleteCourier.courierName : ""}
                        onDelete={handleDeleteConfirmation}
                        onClose={() => setIsDeleteModalOpen(false)}
                        deleteReason={deleteReason}
                        setDeleteReason={setDeleteReason}
                    />
                </div>
            </div>
        </div>
    );
}

export default CourierList;
