import React, { useState } from "react";
import axios from "axios";

const NextInquiry = ({ onClose, onUpdate, inquiry }) => {
    const [formData, setFormData] = useState({
        callingDate: "",
        description: "",
        nextDate: "",
        username: localStorage.getItem('username'),
    });    

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validate = () => {
        let formErrors = {};
        if (!formData.callingDate) formErrors.callingDate = 'Calling Date is required';
        if (!formData.nextDate) formErrors.nextDate = 'Next Date is required';

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        try {
            // Posting new inquiry history
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/next_inquiry`, {
                ...formData,
                inquiryId: inquiry.id,
            });

            // Updating nextCallDate in inquiry_details
            await axios.put(`${process.env.REACT_APP_LOCAL_URL}/update/next_date/${inquiry.id}`, {
                nextCallDate: formData.nextDate,
            });

            console.log("Next inquiry details uploaded successfully");
           
            onClose();
            onUpdate();
        } catch (error) {
            console.error("Error uploading next inquiry details:", error);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div
            id="nextInquiry"
            className="modal fade show"
            role="dialog"
            style={{ display: "block", paddingRight: "17px" }}
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form
                        id="formNextInquiry"
                        autoComplete="off"
                        noValidate="novalidate"
                        onSubmit={handleSubmit}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Add Next Inquiry Details</h5>
                            <button type="button" className="close" onClick={handleClose}>
                                &times;
                            </button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            <div className="form-group">
                                <label>Calling Date <span style={{ color: "red" }}>*</span></label>
                                <input
                                    name="callingDate"
                                    type="date"
                                    className={`form-control ${errors.callingDate ? 'is-invalid' : ''}`}
                                    value={formData.callingDate}
                                    onChange={handleChange}
                                    required
                                    placeholder="Calling Date"
                                />
                                {errors.callingDate && <small className="text-danger">{errors.callingDate}</small>}
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <textarea
                                    name="description"
                                    className="form-control"
                                    rows="5"
                                    value={formData.description}
                                    onChange={handleChange}
                                    placeholder="Description"
                                ></textarea>
                            </div>
                            <div className="form-group">
                                <label>Next Date <span style={{ color: "red" }}>*</span></label>
                                <input
                                    name="nextDate"
                                    type="date"
                                    className={`form-control ${errors.nextDate ? 'is-invalid' : ''}`}
                                    value={formData.nextDate}
                                    onChange={handleChange}
                                    required
                                    placeholder="Next Date"
                                />
                                {errors.nextDate && <small className="text-danger">{errors.nextDate}</small>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" id="save">
                                Save
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NextInquiry;
