import React, { useState, useEffect } from "react";
import axios from "axios";
import AddInward from "./AddInward";
import InwardDesc from "./InwardDesc";
import EditInward from './EditInward';
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function InwardList({ handleLogout, username }) {
    const [inwards, setInwards] = useState([]);
    const [selectedInward, setSelectedInward] = useState(null);
    const [showInwardDetails, setShowInwardDetails] = useState(false);
    const [isAddInwardModalOpen, setIsAddInwardModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editInward, setEditInward] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteInward, setDeleteInward] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    useEffect(() => {
        fetchInwards();
    }, []);

    const fetchInwards = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/inwards`);
            setInwards(response.data);
        } catch (error) {
            console.error("Error fetching inwards:", error);
        }
    };

    const handleAddInward = () => {
        setIsAddInwardModalOpen(true);
    };

    const handleCloseInwardModal = () => {
        setIsAddInwardModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleInwardDetails = (inward) => {
        setSelectedInward(inward);
        setShowInwardDetails(true);
    };

    const handleEditInwardClick = (inward) => {
        setEditInward(inward);
        setSelectedInward(inward);
        setIsEditModalOpen(true);
    };

    const handleEditInwardClose = () => {
        setSelectedInward(null);
    };

    const handleBackToTable = () => {
        setSelectedInward(null);
        setShowInwardDetails(false);
    };

    const handleDeleteInward = (inward) => {
        setDeleteInward(inward);
        setIsDeleteModalOpen(true);
    };

    const handleUpdateInward = async (updatedInward) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/inwards/${updatedInward.id}`, updatedInward);
            console.log("Inward updated:", response.data);
            const updatedInwards = inwards.map(inward => (inward.id === updatedInward.id ? response.data : inward));
            setInwards(updatedInwards);
        } catch (error) {
            console.error("Error updating inward:", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/inwards/${deleteInward.id}`);
            const deletedInward = { ...deleteInward, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedInward);
            setInwards(prevInwards => prevInwards.filter(inward => inward.id !== deleteInward.id));
            setIsDeleteModalOpen(false);
            console.log("Inward deleted successfully");
        } catch (error) {
            console.error("Error deleting inward:", error);
        }
    };

    const handleUpdateInwards = () => {
        toast.success("Successfully uploaded");
        fetchInwards();
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = inwards.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100 '>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {!showInwardDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            Inward List
                                        </h6>
                                        <div className="d-flex gap-2">
                                            <button onClick={(e) => window.print()} className="btn btn-outline-success">
                                                <i className="fa fa-download"></i>
                                            </button>
                                            <button onClick={handleAddInward} className="btn btn-outline-primary">
                                                Add New Inward
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className="card-body"
                                        style={{ height: "calc(100% - 40px)" }}
                                    >
                                        <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>client Name</th>
                                                        <th>Consignment No.</th>
                                                        <th>courier Company</th>
                                                        <th>Received Date</th>
                                                        <th>Consignment Subject</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <style>
                                                        {`.hyperlink:hover {color: blue;}`}
                                                    </style>
                                                    {currentItems.map((inward, index) => (
                                                        <tr key={index}>
                                                            <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleInwardDetails(inward)}>{inward.entityName}</td>
                                                            <td>{inward.consignmentNo}</td>
                                                            <td>{inward.courierCompany}</td>
                                                            <td>{formatDate(inward.receiptDate)}</td>
                                                            <td>{inward.consignmentType}</td>
                                                            <td>
                                                                <div className="btn-group">
                                                                    <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu actionmenu" x-placement="bottom-start">
                                                                        <a className="dropdown-item" href="#" onClick={() => handleInwardDetails(inward)}><i className="fa fa-file"></i> Detail</a>
                                                                        <a className="dropdown-item" href="#" onClick={() => handleEditInwardClick(inward)}><i className="fas fa-edit"></i> Edit</a>
                                                                        <a className="dropdown-item" href="#" onClick={() => handleDeleteInward(inward)}><i className="fa fa-trash"></i> Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(inwards.length / itemsPerPage) }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(inwards.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showInwardDetails && selectedInward && (
                        <InwardDesc
                            inward={selectedInward}
                            onClose={handleBackToTable}
                        />
                    )}
                    {selectedInward && !showInwardDetails && (
                        <EditInward inward={selectedInward} onClose={handleEditInwardClose} onUpdate={handleUpdateInwards} />
                    )}
                    {isAddInwardModalOpen && <AddInward onClose={handleCloseInwardModal} onUpdate={handleUpdateInwards} />}
                    <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        itemName={deleteInward ? deleteInward.entityName : ""}
                        onDelete={handleDeleteConfirmation}
                        onClose={() => setIsDeleteModalOpen(false)}
                        deleteReason={deleteReason}
                        setDeleteReason={setDeleteReason}
                    />
                </div>
            </div>
        </div>
    );
}

export default InwardList;
