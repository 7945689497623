import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from 'react-select';
import imageCompression from 'browser-image-compression';

const AddSales = ({ onClose, onUpdate }) => {
    const [formData, setFormData] = useState({
        clientName: "",
        clientCode: "",
        address: "",
        companyName: "",
        salesDate: "",
        invoiceNumber: "",
        totalSalesAmount: "",
        salesDescription: "",
        selectedServiceProducts: [],
        salesExecutiveName: "",
        remark: "",
        uploadPicture: null,
        username:localStorage.getItem('username'),
    });

    const [clients, setClients] = useState([]);
    const [errors, setErrors] = useState({});
    const [companies, setCompanies] = useState([]);
    const [serviceProducts, setServiceProducts] = useState([]);
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const [clientsResponse, companiesResponse, serviceProductsResponse, employeesResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_LOCAL_URL}/clients`),
                    axios.get(`${process.env.REACT_APP_LOCAL_URL}/companies`),
                    axios.get(`${process.env.REACT_APP_LOCAL_URL}/service_product`),
                    axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`)
                ]);

                if (isMounted) {
                    setClients(clientsResponse.data);
                    setCompanies(companiesResponse.data);
                    setServiceProducts(serviceProductsResponse.data);
                    setEmployees(employeesResponse.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));

        if (name === "clientName") {
            const selectedClient = clients.find(client => client.clientName === value);
            console.log(selectedClient)
            console.log(selectedClient.address1)
            if (selectedClient) {
                setFormData(prevState => ({
                    ...prevState,
                    client_id: selectedClient.id,
                    clientCode: selectedClient.clientCode,
                    address: `${selectedClient.address1}, ${selectedClient.clientState1}, ${selectedClient.clientCity1}`,
                    mobileNo1: selectedClient.mobileNo1,
                    emailId1: selectedClient.emailId1,
                }));
            }
        } else if (name === "companyName") {
            const selectedCompany = companies.find(company => company.companyName === value);
            if (selectedCompany) {
                setFormData(prevState => ({
                    ...prevState,
                    companyName_id: selectedCompany.id,
                }));
            }
        } else if (name === "salesExecutiveName") {
            const selectedEmployee = employees.find(employee => employee.ename === value);
            if (selectedEmployee) {
                setFormData(prevState => ({
                    ...prevState,
                    employee_id: selectedEmployee.id,
                }));
            }
        }
    };

    const handleSupplierChange = (selectedOptions) => {
        setFormData(prevState => ({
            ...prevState,
            selectedServiceProducts: selectedOptions.map(option => ({ id: option.value, name: option.label })),
        }));
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        try {
            const options = {
                maxSizeMB: 0.2,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);

            const fileWithMetaData = new File([compressedFile], file.name, { type: compressedFile.type });

            setFormData(prevState => ({
                ...prevState,
                uploadPicture: fileWithMetaData,
            }));
        } catch (error) {
            console.error('Image compression error:', error);
        }
    };

    const validate = () => {
        let formErrors = {};
        if (!formData.clientName) formErrors.clientName = 'Client Name is required';
        if (!formData.clientCode) formErrors.clientCode = 'Client Code is required';
        if (!formData.address) formErrors.address = 'Address Name is required';
        if (!formData.companyName) formErrors.companyName = 'Mobile No. 1 is required';
        if (!formData.salesDate) formErrors.salesDate = 'Sales Date is required';
        if (!formData.totalSalesAmount) formErrors.totalSalesAmount = 'Total Amount is required';
        if (!formData.salesDescription) formErrors.salesDescription = 'Sales Description is required';
        if (!formData.salesExecutiveName) formErrors.salesExecutiveName = 'Sales Executive Name is required';
        if (!formData.selectedServiceProducts || formData.selectedServiceProducts.length === 0) {
            formErrors.selectedServiceProducts = 'Service/Product is required';
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation logic here
        if (!validate()) {
            return;
          }

        try {
            const formDataToSend = new FormData();

            for (const key in formData) {
                if (formData[key]) {
                    if (key === "uploadPicture" && formData[key]) {
                        formDataToSend.append(key, formData[key]);
                    } else if (key === "selectedServiceProducts") {
                        formDataToSend.append(key, JSON.stringify(formData[key]));
                    } else {
                        formDataToSend.append(key, formData[key]);
                    }
                }
            }
            console.log("formData", formData)
            console.log("formDatatosend", formDataToSend)

            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/addSales`, formDataToSend);
            console.log("Data uploaded successfully:", response.data);

            onClose();
            onUpdate();
        } catch (error) {

            console.error("Error uploading data:", error);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div
            id="addSales"
            className="modal fade show"
            role="dialog"
            style={{ display: "block", paddingRight: "17px" }}
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form
                        id="formAddSales"
                        autoComplete="off"
                        noValidate="novalidate"
                        onSubmit={handleSubmit}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Add Sales</h5>
                            <button type="button" className="close" onClick={handleClose}>
                                &times;
                            </button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-row">
                                        <div className="form-group col-md-8">
                                            <label htmlFor="clientName">Client Name<span style={{ color: "red" }}>*</span></label>
                                            <select
                                                name="clientName"
                                                id="clientName"
                                                className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                                                value={formData.clientName}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="" disabled hidden>Select Client</option>
                                                {clients.map((client) => (
                                                    <option key={client.id} value={client.clientName}>
                                                        {client.clientName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.clientName && <small className="text-danger">{errors.clientName}</small>}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label htmlFor="clientCode">Client Code<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                type="text"
                                                id="clientCode"
                                                name="clientCode"
                                                className={`form-control ${errors.clientCode ? 'is-invalid' : ''}`}
                                                value={formData.clientCode}
                                                placeholder="Client Code"
                                                readOnly
                                            />
                                            {errors.clientCode && <small className="text-danger">{errors.clientCode}</small>}
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Mobile No  <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="mobileNo1"
                                                type="number"
                                                className="form-control"
                                                value={formData.mobileNo1}
                                                onChange={handleChange}
                                                required
                                                placeholder="Mobile No."
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Email Id <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="emailId1"
                                                type="email"
                                                className="form-control"
                                                value={formData.emailId1}
                                                onChange={handleChange}
                                                required
                                                placeholder="Email Id "
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="address">Address <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            type="text"
                                            id="address"
                                            name="address"
                                            className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                            value={formData.address}
                                            onChange={handleChange}
                                            placeholder="Address"
                                            readOnly
                                        />
                                        {errors.address && <small className="text-danger">{errors.address}</small>}
                                    </div>
                                    <hr />
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="companyName">Sales From Company <span style={{ color: "red" }}>*</span></label>
                                            <select
                                                name="companyName"
                                                id="companyName"
                                                className={`form-control ${errors.companyName ? 'is-invalid' : ''}`}
                                                value={formData.companyName}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="" disabled hidden>Select Company</option>
                                                {companies.map((company) => (
                                                    <option key={company.id} value={company.companyName}>
                                                        {company.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.companyName && <small className="text-danger">{errors.companyName}</small>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Services / Products : - <span style={{ color: "red" }}>*</span></label>
                                            <Select
                                                isMulti
                                                options={serviceProducts.map(product => ({ value: product.id, label: product.name }))}
                                                onChange={handleSupplierChange}
                                            />
                                            {errors.selectedServiceProducts && <small className="text-danger">{errors.selectedServiceProducts}</small>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Sales Date <span style={{ color: "red" }}>*</span> </label>
                                            <input
                                                name="salesDate"
                                                type="date"
                                                className={`form-control ${errors.salesDate ? 'is-invalid' : ''}`}
                                                value={formData.salesDate}
                                                onChange={handleChange}
                                                required
                                            />
                                            {errors.salesDate && <small className="text-danger">{errors.salesDate}</small>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Total Sales Amount <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="totalSalesAmount"
                                                type="number"
                                                className={`form-control ${errors.totalSalesAmount ? 'is-invalid' : ''}`}
                                                value={formData.totalSalesAmount}
                                                onChange={handleChange}
                                                placeholder="Sales Amount"
                                                required
                                            />
                                            {errors.totalSalesAmount && <small className="text-danger">{errors.totalSalesAmount}</small>}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Invoice Number</label>
                                            <input
                                                name="invoiceNumber"
                                                type="text"
                                                className="form-control"
                                                value={formData.invoiceNumber}
                                                onChange={handleChange}
                                                placeholder="Invoice No."
                                                required
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="salesExecutiveName">Sales Executive Name <span style={{ color: "red" }}>*</span></label>
                                            <select
                                                name="salesExecutiveName"
                                                id="salesExecutiveName"
                                                className={`form-control ${errors.salesExecutiveName ? 'is-invalid' : ''}`}
                                                value={formData.salesExecutiveName}
                                                onChange={handleChange}
                                                // placeholder="Sales Executive Name"
                                                required
                                            >
                                                <option value="" disabled hidden>Select</option>
                                                {employees.map((employee) => (
                                                    <option key={employee.id} value={employee.ename}>
                                                        {employee.ename}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.salesExecutiveName && <small className="text-danger">{errors.salesExecutiveName}</small>}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Sales Description <span style={{ color: "red" }}>*</span></label>
                                        <textarea
                                            name="salesDescription"
                                            className={`form-control ${errors.salesDescription ? 'is-invalid' : ''}`}
                                            value={formData.salesDescription}
                                            onChange={handleChange}
                                            placeholder="Description"
                                            required
                                        />
                                        {errors.salesDescription && <small className="text-danger">{errors.salesDescription}</small>}
                                    </div>
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea
                                            name="remark"
                                            className="form-control"
                                            value={formData.remark}
                                            onChange={handleChange}
                                            placeholder="Remark"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Upload Picture</label>
                                        <input
                                            name="uploadPicture"
                                            type="file"
                                            className="form-control-file"
                                            onChange={handleImageChange}
                                            required
                                        />
                                        <small>Max size: 200KB</small>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" id="save">
                                    Save
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    data-dismiss="modal"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddSales;
