import React, { useEffect, useState, } from "react";
import { Link, useLocation, Route, Routes, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import AddDataModal from "../../pages/AssetMaster/AddDataModal";
import AddEmployeeTable from "../../pages/EmployeeMaster/AddEmployeeTable";
import AddVendor from "../../pages/VendorMaster/AddVendor";
import AddCategory from "../../pages/CategoryMaster/AddCategory";
import AddSiteModal from "../../pages/SiteMaster/AddSiteModal";
import AddClientModal from "../../pages/ClientMaster/AddClientModal";
import AssetLost from "../../pages/AssetMaster/AssetLost";
import axios from "axios";
import AddBrandModal from "../../pages/BrandMaster/AddBrandModal";
import AddComponent from "../../pages/ComponentMaster/AddComponent";
import AddComponentList from "../../pages/ComponentMaster/AddComponentList";
import AddInsuranceModal from "../../pages/AssetMaster/AddAssetInsuranceModal";
import AddMaintenanceData from "../../pages/AssetMaster/AddMaintenanceData";
import AddAsset from "../../pages/AssetMaster/AddAsset";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddTransporterModal from "../../pages/AssetMaster/AddTransporterModal";
import AddInward from "../../pages/Inword/AddInward";
import AddOutward from "../../pages/Outward/AddOutWard";
import AddCourier from "../../pages/CourierMaster/AddCourier";
import AddServiceProductModal from "../../pages/Services_Products/AddServiceProductModal";
import AddOfficeForm from "../../pages/OurOfficeMaster/AddOfficeForm";
import AddCompany from "../../pages/Company Master/AddCompany";
import AddSales from "../../pages/SalesMaster/AddSales";
import AddPurchase from "../../pages/PurchaseMaster/AddPurchase";
import AddInquiry from "../../pages/Salesfunal/AddInquiry";
// list import  

// import { Dashboard } from "@mui/icons-material";

function Sidebar({ handleLogout }) {
  // Form Modal open 
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false); // State to manage modal open/close
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const [isVendorModalOpen, setIsVendorModalOpen] = useState(false);
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
  // addinward modal 
  const [isAddInwardModalopen, setIsAddInwardModalopen] = useState(false);
  // add outwardModal 
  const [isAddOutwardModalOpen, setIsAddOutwardModalOpen] = useState(false);
  // add Courier 
  const [isAddCourierModalOpen, setIsAddCourierModalOpen] = useState(false);
  // Service and product Modal 
  const [isAddServicesProductsModalOpen, setIsAddServicesProductsModalOpen] = useState(false);
  // Office Modal 
  const [isAddOfficeModalOpen, setIsAddOfficeModalOpen] = useState(false);
  // Company Modal 
  const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = useState(false);
  // Sales Modal 
  const [isAddSalesModalOpen, setIsAddSalesModalOpen] = useState(false);
// purchase Modal 
const [isAddPurchaseModalOpen, setIsAddPurchaseModalOpen] = useState(false);
// AddInquiry 
const [isAddInquiryModalOpen, setIsAddInquiryModalOpen] = useState(false);

  
  const location = useLocation();
  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  );

  const changeStyle = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };
  const changeStyle1 = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };
  const navigate = useNavigate(); // Place the hook outside of the component


  // Handle 
  const handleAddAsset = () => {
    setIsModalOpen(true); // Open the modal when "Add new Asset" button is clicked
  };
  // const handleAddAsset = (event) => {
  //   event.preventDefault(); // Prevent default behavior of the anchor tag
  //   navigate("/assetlist"); // Navigate to the asset list page
  //   setIsModalOpen(true); // Open the modal
  // };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };
  const handleAddEmployee = () => {
    setIsEmployeeModalOpen(true);
  };

  const handleAddVendor = () => {
    setIsVendorModalOpen(true);
  };

  const handleAddAddInquiry = () => {
    setIsAddInquiryModalOpen(true);
  };

  const handleCloseAddInquiryModal = () => {
    setIsAddInquiryModalOpen(false);
  };

  const handleCloseEmployeeModal = () => {
    setIsEmployeeModalOpen(false);
  };

  const handleCloseVendorModal = () => {
    setIsVendorModalOpen(false);
  };

  // clientmodal 

  const handleAddClient = () => {
    setIsAddClientModalOpen(true);
  };

  const handleCloseClientModal = () => {
    setIsAddClientModalOpen(false);
  };

  // Asset Lost 

  const handleAddOffice = () => {
    setIsAddOfficeModalOpen(true);
  };

  const handleCloseOfficeModal = () => {
    setIsAddOfficeModalOpen(false);
  };

  // Purchase Modal 

  const handleAddPurchase = () => {
    setIsAddPurchaseModalOpen(true)
  };

  const handleClosePurchaseModal = () => {
    setIsAddPurchaseModalOpen(false);
  };

  // Brand  Modal 

  const handleAddServicesProducts = () => {
    setIsAddServicesProductsModalOpen(true);
  };

  const handleCloseServicesProductsModal = () => {
    setIsAddServicesProductsModalOpen(false);
  };

  // Add Inward Modal 

  const handleAddInward = () => {
    setIsAddInwardModalopen(true);
  };

  const handleCloseInwardModal = () => {
    setIsAddInwardModalopen(false);
  };

  // Sales List Modal 

  const handleAddSalesModal = () => {
    setIsAddSalesModalOpen(true);
  };

  const handleCloseSalesModal = () => {
    setIsAddSalesModalOpen(false);
  };

  // Asset Insurence Modal 

  const handleAddCompanyModal = () => {
    setIsAddCompanyModalOpen(true);
  };

  const handleCloseCompanyModal = () => {
    setIsAddCompanyModalOpen(false);
  };
  // Asser Maintenance Modal 

  const handleAddCourierModal = () => {
    setIsAddCourierModalOpen(true);
  };

  const handleCloseCourierModal = () => {
    setIsAddCourierModalOpen(false);
  };

  // Out Ward Modal 

  const handleAddOutWardModal = () => {
    setIsAddOutwardModalOpen(true);
  };

  const handleCloseOutWardModal = () => {
    setIsAddOutwardModalOpen(false);
  };

  // Add Asset Modal 

  const handleAddAssetModal = () => {
    setIsAssetModalOpen(true);
  };

  const handleCloseAssetModal = () => {
    setIsAssetModalOpen(false);
  };

  const handleListClick = (path) => {
    if (window.location.pathname === path) {
      window.location.reload();
    }
  };

  const [dashboardLogo, setDashboardLogo] = useState([]);

  useEffect(() => {
    const fetchDashboardLogo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
        setDashboardLogo(response.data);
      } catch (error) {
        console.error('Error fetching Dashboard Logo', error);
      }
    };

    fetchDashboardLogo();
  }, []);

  const handleUpdate = () => {
    toast.success("successfully uploaded");
  }

  return (
    <div  >
      <div id="page-top">
        {/*  <!-- Page Wrapper --> */}
        <ToastContainer />
        <div id="wrapper">
          {/*  <!-- Sidebar --> */}
          <ul style={{ width: "50%" }} className={style} id="accordionSidebar">
            {/*  <!-- Sidebar - Brand --> */}
            <a
              className="sidebar-brand d-flex align-items-center justify-content-center"
              href="#"
            >
              <div className="sidebar-brand-icon rotate-n-15"></div>
              <div className="sidebar-brand-text mx-3">
                {/* Prospect Digital */}
                <img src={`${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${dashboardLogo.landingPageLogo}`} style={{ width: "120px" }} alt="LOGO" />
              </div>
              <div className="text-center d-none d-md-inline">
                <button
                  className="rounded-circle border-0"
                  id="sidebarToggle"
                  onClick={changeStyle}
                ></button>
              </div>
            </a>

            {/*   <!-- Divider --> */}
            <hr className="sidebar-divider my-0" />

            {/*  <!-- Nav Item - Dashboard --> */}

            <li className="nav-item active">
              <Link to="/dashboard" className="nav-link">
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            {/*  <!-- Divider --> */}
            <hr className="sidebar-divider" />

            {/*   <!-- Heading --> */}
            {/* <!-- Nav Item - Asset Master --> */}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAsset"
                aria-expanded="true" aria-controls="collapseAsset">

                <i className="fas fa-users"></i>
                <span>Asset Master</span>
              </a>
              <div id="collapseAsset" className="collapse" aria-labelledby="headingAsset" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 style={{color:"#670060"}} className="collapse-header">Assets:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddAssetModal}>Add Asset master</a>
                  <a className="collapse-item" href="#" onClick={handleAddAsset}>Add new Asset</a>
                  <Link to="/assetlist" className="collapse-item" onClick={() => handleListClick("/assetlist")}>
                    <span>Asset List</span>
                  </Link>
                  <Link to="/assetmasterlist" className="collapse-item"><span>Asset Master List</span></Link>
                  <Link to="/assettransfer" className="collapse-item"><span>Asset Transfer</span></Link>
                  
                  <h6 style={{color:"#670060"}} className="collapse-header">Asset on Maintenance:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddCourierModal}>Add new Maintenance</a>
                  <Link className="collapse-item" to="/assetMaintenance" ><span>Asset Maintenance</span>
                  </Link>
                  <Link className="collapse-item" to="/finishedmaintenance" ><span>Finished Maintenance</span>
                  </Link>
                  <Link className="collapse-item" to="/UnfinishedMaintenance" ><span>Unfinished Maintenance</span>
                  </Link>
                  <h6 style={{color:"#670060"}} className="collapse-header">Asset on Insurance:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddCompanyModal}>Add Insurance</a>
                  <Link className="collapse-item" to="/AssetInsurance" >
                    <span>Asset Insurance</span>
                  </Link>
                  <h6 style={{color:"#670060"}} className="collapse-header">Asset Lost:</h6>
                  <a className="collapse-item" onClick={handleAddOffice}>
                    Add Asset Lost
                  </a>
                  <Link className="collapse-item" to="/assetlostlist" >
                    <span>Asset Lost List</span>
                  </Link>
                </div>
              </div>
            </li> */}
            {/* <!-- Nav Item - Inquiry Master --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAddInquiry"
                aria-expanded="true" aria-controls="collapseAddInquiry">
                <i className="fas fa-users"></i>
                <span>sales funnel</span>
              </a>
              <div id="collapseAddInquiry" className="collapse" aria-labelledby="headingClient" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className="collapse-item" href="#" onClick={handleAddAddInquiry}>Add new Inquiry</a>
                  <Link to="/InquiryList" className="collapse-item" onClick={() => handleListClick("/InquiryList")}>
                    <span>List of Inquiry </span>
                  </Link>
                  <Link to="/convertedinquirylist" className="collapse-item" onClick={() => handleListClick("/convertedinquirylist")}>
                    <span>List of Converted </span>
                  </Link>
                  <Link to="/unconvertedinquirylist" className="collapse-item" onClick={() => handleListClick("/unconvertedinquirylist")}>
                    <span>List of Unconverted </span>
                  </Link>
                </div>
              </div>
            </li>
            {/* <!-- Nav Item - Client Master --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseClient"
                aria-expanded="true" aria-controls="collapseClient">
                <i className="fas fa-users"></i>
                <span>Client Master</span>
              </a>
              <div id="collapseClient" className="collapse" aria-labelledby="headingClient" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className="collapse-item" href="#" onClick={handleAddClient}>Add new Client </a>
                  <Link to="/clientList" className="collapse-item" onClick={() => handleListClick("/clientList")}>
                    <span>List of Clients </span>
                  </Link>
                </div>
              </div>
            </li>
            {/* Nav Item - Total Component */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsevendor"
                aria-expanded="true" aria-controls="collapsevendor">
                <i className="fas fa-fw fa-chart-area"></i>
                <span>Vendor master</span>
              </a>
              <div id="collapsevendor" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  
                  <a className="collapse-item" href="#" onClick={handleAddVendor}>Add new vendor </a>
                  <Link to="/vendorlist" className="collapse-item" onClick={() => handleListClick("/vendorlist")}>
                    <span>List of Vendors</span>
                  </Link>
                </div>
              </div>
            </li>
            {/* <!-- Nav Item - Purchase Master --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsepurchase"
                aria-expanded="true" aria-controls="collapsepurchase">
                <i className="fas fa-users"></i>
                <span>Sales/Purchase Master</span>
              </a>
              <div id="collapsepurchase" className="collapse" aria-labelledby="headingClient" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                <h6 style={{color:"#670060"}} className="collapse-header">Sales :</h6>
                  <a className="collapse-item" href="#" onClick={handleAddSalesModal} >Add new Sales </a>
                  <Link  to="/saleslist" className="collapse-item" onClick={() => handleListClick("/saleslist")}>
                    <span>List of Sales</span>
                  </Link>
                  <h6 style={{ color: "#670060" }} className="collapse-header">Purchase :</h6>
                  <a className="collapse-item" href="#" onClick={handleAddPurchase}>Add new Purchase</a>
                  <Link to="/purchaselist" className="collapse-item" onClick={() => handleListClick("/purchaselist")}>
                    <span>List of Purchase</span>
                  </Link>
                </div>
              </div>
            </li>
             {/* <!-- Nav Item - In / Out word master --> */}
             <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseinoutward"
                aria-expanded="true" aria-controls="collapseinoutward">

                <i className="fas fa-users"></i>
                <span>In/Outward master</span>
              </a>
              <div id="collapseinoutward" className="collapse" aria-labelledby="headingAsset" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 style={{ color: "#670060" }} className="collapse-header">Inward:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddInward}>Add Inward</a>
                  <Link to="/inwardlist" className="collapse-item" onClick={() => handleListClick("/inwardlist")}>
                    <span>Inward List</span>
                  </Link>
                  <h6 style={{ color: "#670060" }} className="collapse-header">Outward:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddOutWardModal}>Add Outward</a>
                  <Link to="/outwardlist" className="collapse-item" onClick={() => handleListClick("/outwardlist")}>
                    <span>Outward List</span>
                  </Link>
                  <h6 style={{ color: "#670060" }} className="collapse-header">Courier Company :</h6>
                  <a className="collapse-item" href="#" onClick={handleAddCourierModal}>Add Courier Company </a>
                  <Link to="/courierlist" className="collapse-item" onClick={() => handleListClick("/courierlist")}>
                    <span>List of Courier Company</span>
                  </Link>
                </div>
              </div>
            </li>
            {/* Service Product  */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseserviceproduct"
                aria-expanded="true" aria-controls="collapseserviceproduct">
                <i className="fas fa-fw fa-chart-area"></i>
                <span>Service/Product Master</span>
              </a>
              <div id="collapseserviceproduct" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className="collapse-item" href="#" onClick={handleAddServicesProducts}>Add Service/Product </a>
                  <Link to="/servicelist" className="collapse-item" onClick={() => handleListClick("/servicelist")}>
                    <span>List of Services</span>
                  </Link>
                 
                  <Link to="/productlist" className="collapse-item" onClick={() => handleListClick("/productlist")}>
                    <span>List of Products</span>
                  </Link>
                </div>
              </div>
            </li>
            {/* <!-- Nav Item - Office Master --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseOffice"
                aria-expanded="true" aria-controls="collapseOffice">
                <i className="fas fa-users"></i>
                <span>Company/Office Master</span>
              </a>
              <div id="collapseOffice" className="collapse" aria-labelledby="headingClient" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                <h6 style={{color:"#670060"}} className="collapse-header">Company :</h6>
                <a className="collapse-item" href="#" onClick={handleAddCompanyModal}>Add new Company</a>
                <Link to="/companylist" className="collapse-item" onClick={() => handleListClick("/companylist")}>
                    <span>List of Company</span>
                  </Link>
                  <h6 style={{color:"#670060"}} className="collapse-header">Office :</h6>
                  <a className="collapse-item" href="#" onClick={handleAddOffice}>Add new Office</a>
                  
                  <Link to="/OfficeList" className="collapse-item" onClick={() => handleListClick("/OfficeList")}>
                    <span>List of Office</span>
                  </Link>
                </div>
              </div>
            </li>
            
            {/* Nav Item - Total Component */}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsevendor"
                aria-expanded="true" aria-controls="collapsevendor">
                <i className="fas fa-fw fa-chart-area"></i>
                <span>Vendor master</span>
              </a>
              <div id="collapsevendor" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 style={{ color: "#670060" }} className="collapse-header">Vendor Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddVendor}>Add new vendor </a>
                  <Link to="/vendorlist" className="collapse-item" onClick={() => handleListClick("/vendorlist")}>
                    <span>Vendor List</span>
                  </Link>
                </div>
              </div>
            </li> */}
            {/* <!-- Nav Item - Employee --> */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseEmployee"
                aria-expanded="true" aria-controls="collapseEmployee">

                <i className="fas fa-users"></i>
                <span>Employee Master</span>
              </a>
              <div id="collapseEmployee" className="collapse" aria-labelledby="headingEmployee" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">

                  <a className="collapse-item" href="#" onClick={handleAddEmployee} >Add new Employee </a>
                  <Link  to="/employeelist" className="collapse-item" onClick={() => handleListClick("/employeelist")}>
                    <span>List of Employees</span>
                  </Link>
                </div>
              </div>
            </li>

            {/* <!-- Nav Item - Sales --> */}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSales"
                aria-expanded="true" aria-controls="collapseSales">

                <i className="fas fa-users"></i>
                <span>Sales Master</span>
              </a>
              <div id="collapseSales" className="collapse" aria-labelledby="headingEmployee" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 style={{color:"#670060"}} className="collapse-header">Sales :</h6>
                  <a className="collapse-item" href="#" onClick={handleAddSalesModal} >Add new Sales </a>
                  <Link  to="/saleslist" className="collapse-item" onClick={() => handleListClick("/saleslist")}>
                    <span>Sales List</span>
                  </Link>
                </div>
              </div>
            </li> */}
        
            {/* Nav Item - Inward and outward*/}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseserviceproduct"
                aria-expanded="true" aria-controls="collapseserviceproduct">
                <i className="fas fa-fw fa-chart-area"></i>
                <span>Service/Product Master</span>
              </a>
              <div id="collapseserviceproduct" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 style={{ color: "#670060" }} className="collapse-header">Services / Product :</h6>
                  <a className="collapse-item" href="#" onClick={handleAddServicesProducts}>Add Service / Product </a>
                  <Link to="/servicelist" className="collapse-item" onClick={() => handleListClick("/servicelist")}>
                    <span>Service List</span>
                  </Link>
                  <Link to="/productlist" className="collapse-item" onClick={() => handleListClick("/productlist")}>
                    <span>Product List</span>
                  </Link>
                </div>
              </div>
            </li> */}
            {/* Nav Item - Procuduct Master */}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseproduct"
                aria-expanded="true" aria-controls="collapseproduct">
                <i className="fas fa-fw fa-chart-area"></i>
                <span>Product Master</span>
              </a>
              <div id="collapseproduct" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 style={{ color: "#670060" }} className="collapse-header">Products :</h6>
                  <a className="collapse-item" href="#" onClick={handleAddServicesProducts}>Add new Product </a>
                  <Link to="/vendorlist" className="collapse-item" onClick={() => handleListClick("/vendorlist")}>
                    <span>Product List</span>
                  </Link>
                </div>
              </div>
            </li> */}
            {/* Nav Item - courier Master*/}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsecourier"
                aria-expanded="true" aria-controls="collapsecourier">
                <i className="fas fa-fw fa-chart-area"></i>
                <span>Courier Master</span>
              </a>
              <div id="collapsecourier" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 style={{ color: "#670060" }} className="collapse-header">Courier :</h6>
                  <a className="collapse-item" href="#" onClick={handleAddCourierModal}>Add Courier </a>
                  <Link to="/courierlist" className="collapse-item" onClick={() => handleListClick("/courierlist")}>
                    <span>Courier List</span>
                  </Link>
                </div>
              </div>
            </li> */}
           
            {/* <!-- Nav Item - All Master --> */}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseMaster"
                aria-expanded="true" aria-controls="collapseMaster">
                <i className="fas fa-users"></i>
                <span>All Masters</span>
              </a>
              <div id="collapseMaster" className="collapse" aria-labelledby="headingEmployee" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded"> */}
            {/* <h6 style={{color:"#670060"}} className="collapse-header">Asset Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddAsset}>Add new Asset</a>
                  <Link to="/assetlist" className="collapse-item"><span>Asset List</span></Link> */}
            {/* <h6 style={{color:"#670060"}} className="collapse-header">Employees:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddEmployee} >Add new Employee </a> */}
            {/* <Link className="collapse-item" to="/Employeelist" >
                    <span>Employee List</span>
                  </Link> */}
            {/* <Link to="/Employeelist" className="collapse-item" onClick={() => handleListClick("/Employeelist")}>
                    <span>Employee List</span>
                  </Link>
                  <h6 style={{color:"#670060"}} className="collapse-header">Category Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddAddInquiry}>Add new category</a>
                  <Link className="collapse-item" to="/categoryList" >
                    <span>Category List</span>
                  </Link>
                  <h6 style={{color:"#670060"}} className="collapse-header">Vendor Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddVendor}>Add new vendor </a>
                  <Link to="/vendorlist" className="collapse-item" onClick={() => handleListClick("/vendorlist")}>
                    <span>Vendor List</span>
                  </Link> */}
            {/* <Link className="collapse-item" to="/vendorlist">
                    <span>Vendor List</span>
                  </Link> */}
            {/* <h6 style={{color:"#670060"}} className="collapse-header">Brand Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddServicesProducts}>Add new Brand</a>
                  <Link className="collapse-item" to="/brandlist">
                    <span>Brand List</span>
                  </Link>
                  <h6 style={{color:"#670060"}} className="collapse-header">site Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddPurchase}>Add new Site </a> */}
            {/* <Link className="collapse-item" to="/sitelist">
                    <span>Site List</span>
                  </Link> */}
            {/* <Link to="/sitelist" className="collapse-item" onClick={() => handleListClick("/sitelist")}>
                    <span>Site List</span>
                  </Link>
                  <h6 style={{color:"#670060"}} className="collapse-header">Transporter Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddOutWardModal}>Add Transporter</a>
                  <Link className="collapse-item" to="/transporterlist">
                    <span>Transporter List</span>
                  </Link> */}
            {/* <h6 style={{color:"#670060"}} className="collapse-header">Employee Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddEmployee} >Add new Employee </a>
                  <Link className="collapse-item" to="/Employeelist" >
                    <span>Employee List</span>
                  </Link>
                  <h6 style={{color:"#670060"}} className="collapse-header">Client Master:</h6>
                  <a className="collapse-item" onClick={handleAddClient}>Add new Client </a>
                  <Link className="collapse-item" to="/clientList" >
                    <span>Client List</span>
                  </Link>
                  <h6 style={{color:"#670060"}} className="collapse-header">Component Master:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddInward}>Add Head Component</a>
                  <a className="collapse-item" href="#" onClick={handleAddSalesModal}>Add Component List</a>
                  <Link className="collapse-item" to="/componentlist" >
                    <span>Component List</span>
                  </Link>
                  <Link className="collapse-item" to="/fullcomponentList">
                    <span>Full Component List</span>
                  </Link>
                  <h6 style={{color:"#670060"}} className="collapse-header">Asset Insurence:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddCompanyModal}>Add Insurance</a>
                  <Link className="collapse-item" to="/AssetInsurance" >
                    <span>Asset Insurance</span>
                  </Link>
                  <h6 style={{color:"#670060"}} className="collapse-header">Asset Maintenance:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddCourierModal}>Add new Maintenance</a>
                  <Link className="collapse-item" to="/assetMaintenance" ><span>Asset Maintenance</span>
                  </Link>
                  <Link className="collapse-item" to="/finishedmaintenance" ><span>Finished Maintenance</span>
                  </Link>
                  <Link className="collapse-item" to="/UnfinishedMaintenance" ><span>Unfinished Maintenance</span>
                  </Link> */}
            {/* </div>
              </div>
            </li> */}
            {/* <!-- Nav Item - Employee --> */}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseEmployee"
                aria-expanded="true" aria-controls="collapseEmployee">

                <i className="fas fa-users"></i>
                <span>Employee</span>
              </a>
              <div id="collapseEmployee" className="collapse" aria-labelledby="headingEmployee" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 style={{color:"#670060"}} className="collapse-header">Employees:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddEmployee} >Add new Employee </a>
                  <Link className="collapse-item" to="/Employeelist" >
                    <span>Employee List</span>
                  </Link>
                </div>
              </div>
            </li> */}
            {/* Nav Item - vendor Master */}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsevendor"
                aria-expanded="true" aria-controls="collapsevendor">
                <i className="fa fa-industry"></i>
                <span>vendor master</span>
              </a>
              <div id="collapsevendor" className="collapse" aria-labelledby="headingvendor" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 style={{color:"#670060"}} className="collapse-header">vendors:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddVendor}>Add new vendor </a>
                  <Link className="collapse-item" to="/vendorlist">
                    <span>Vendor List</span>
                  </Link>
                </div>
              </div>
            </li> */}
            {/*  <!-- Nav Item - Site Master --> */}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSite"
                aria-expanded="true" aria-controls="collapseSite">
                <i className="fas fa-users"></i>
                <span>Site Master</span>
              </a>
              <div id="collapseSite" className="collapse" aria-labelledby="headingSite" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 style={{color:"#670060"}} className="collapse-header">Sites:</h6>
                  <a className="collapse-item" onClick={handleAddPurchase}>Add new Site </a>
                  <Link className="collapse-item" to="/sitelist">
                    <span>Site List</span>
                  </Link>

                </div>
              </div>
            </li> */}
            {/* Nav Item - Brand Master */}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsebrand"
                aria-expanded="true" aria-controls="collapsebrand">
                <i className="fa fa-industry"></i>
                <span>Brand master</span>
              </a>
              <div id="collapsebrand" className="collapse" aria-labelledby="headingvendor" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 style={{color:"#670060"}} className="collapse-header">Brand:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddServicesProducts}>Add new Brand</a>
                  <Link className="collapse-item" to="/brandlist">
                    <span>Brand List</span>
                  </Link>
                </div>
              </div>
            </li> */}
            {/* Nav Item - Category Master */}
            {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsecategory"
                aria-expanded="true" aria-controls="collapsecategory">
                <i className="fa fa-list-alt"></i>
                <span>Category master</span>
              </a>
              <div id="collapsecategory" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 style={{color:"#670060"}} className="collapse-header">Category:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddAddInquiry}>Add new category</a>
                  <Link className="collapse-item" to="/categoryList" >
                    <span>Category List</span>
                  </Link>
                </div>
              </div>
            </li> */}
            {/* Nav Item - Total Setting */}
            <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsesetting"
                aria-expanded="true" aria-controls="collapsesetting">
                <i className="fa fa-cog"></i>
                <span>Setting master</span>
              </a>
              <div id="collapsesetting" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <Link className="collapse-item" to="/profile" >
                    <span>Profile Setting</span>
                  </Link>
                  <Link className="collapse-item" to="/applicationsetting" >
                    <span>Application Setting</span>
                  </Link>
                </div>
              </div>
            </li>
            {/* Nav Item - Total Delete */}
            {/* <li className="nav-item">
              <Link className="nav-link" to="/deletelist" >
                <i className="fa fa-trash"></i>
                <span>Delete List</span>
              </Link>
            </li> */}
            {/* <!-- Divider --> */}
            <hr className="sidebar-divider d-none d-md-block" />
          </ul>
        </div>
      </div>
      {/* Add Modal Tables*/}
      {isModalOpen && <AddDataModal onClose={handleCloseModal} onUpdateAssets={handleUpdate} />}
      {isAssetModalOpen && <AddAsset onClose={handleCloseAssetModal} onUpdate={handleUpdate} />}
      {isEmployeeModalOpen && <AddEmployeeTable onClose={handleCloseEmployeeModal} onUpdate={handleUpdate} />}
      {isVendorModalOpen && <AddVendor onClose={handleCloseVendorModal} onUpdateVendors={handleUpdate} />}
      {isAddInquiryModalOpen && <AddInquiry onClose={handleCloseAddInquiryModal} onUpdate={handleUpdate} />}
      {isAddPurchaseModalOpen && <AddPurchase onClose={handleClosePurchaseModal} onUpdate={handleUpdate} />}
      {isAddClientModalOpen && <AddClientModal onClose={handleCloseClientModal} onUpdate={handleUpdate} />}
      {isAddOfficeModalOpen && <AddOfficeForm onClose={handleCloseOfficeModal} onUpdate={handleUpdate} />}
      {isAddServicesProductsModalOpen && <AddServiceProductModal onClose={handleCloseServicesProductsModal} onUpdate={handleUpdate} />}
      {isAddInwardModalopen && <AddInward onClose={handleCloseInwardModal} onUpdate={handleUpdate} />}
      {isAddSalesModalOpen && <AddSales onClose={handleCloseSalesModal} onUpdate={handleUpdate} />}
      {isAddCompanyModalOpen && <AddCompany onClose={handleCloseCompanyModal} onUpdate={handleUpdate} />}
      {isAddCourierModalOpen && <AddCourier onClose={handleCloseCourierModal} onUpdate={handleUpdate} />}
      {isAddOutwardModalOpen && <AddOutward onClose={handleCloseOutWardModal} onUpdate={handleUpdate} />}
    </div>
  );
}

export default Sidebar;
