import React, { useState, useEffect } from "react";
import axios from "axios";
import ServiceDesc from "./ServiceDesc";
import EditService from "./EditService";
import DeleteConfirmationModal from "../DeleteConfirmationModal"; // Import the new component
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddServiceProductModal from "../Services_Products/AddServiceProductModal";

function Servicelist({ handleLogout, username }) {
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [showServiceDetails, setShowServiceDetails] = useState(false);
    const [isAddServiceModalOpen, setIsAddServiceModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editService, setEditService] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteService, setDeleteService] = useState([]); // State to store data of service being deleted
    useEffect(() => {
        fetchServices();
    }, []);

    const fetchServices = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/service_product`);
            const services = response.data.filter(item => item.type === "Service");
            setServices(services);
        } catch (error) {
            console.error('Error fetching Services:', error);
        }
    };

    const handleAddService = () => {
        setIsAddServiceModalOpen(true);
    };

    const handleCloseServiceModal = () => {
        setIsAddServiceModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleServiceDetails = (service) => {
        setSelectedService(service);
        setShowServiceDetails(true);
    };

    const handleEditServiceClick = (service) => {
        setEditService(service);
        setSelectedService(service); // Update selectedService state
        setIsEditModalOpen(true);
    };

    const handleEditServiceClose = () => {
        setSelectedService(null);
    };

    const handleBackToTable = () => {
        setSelectedService(null);
        setShowServiceDetails(false);
    };


    const handleUpdateService = async (updatedService) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/services/${updatedService.id}`, updatedService);
            console.log("Service updated:", response.data);
            const updatedServices = services.map(service => (service.id === updatedService.id ? response.data : service));
            setServices(updatedServices);
        } catch (error) {
            console.error("Error updating service:", error);
        }
    };

    const handleUpdateServices = () => {
        toast.success("successfully uploaded");
        fetchServices();
    };

    const handleDelete = async (service) => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/services/${service.id}`);
            setDeleteService(prevServices => prevServices.filter(item => item.id !== service.id));
            toast.success("service deleted successfully");
            fetchServices();
        } catch (error) {
            console.error("Error deleting sales:", error);
        }
    };


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = services.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100 '>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} /> {/* Pass username and handleLogout props */}
                <div className="container-fluid">
                    <ToastContainer />
                    {!showServiceDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            Service List
                                        </h6>
                                        <div className="d-flex gap-2">
                                        <button onClick={(e)=>window.print()} className="btn btn-outline-success">
                                                <i className="fa fa-download"></i> 
                                            </button>
                                            <button onClick={handleAddService} className="btn btn-outline-primary">
                                            Add New Service
                                        </button>
                                        </div>                                        
                                    </div>
                                    <div
                                        className="card-body"                                    >
                                        <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Service Name</th>
                                                        <th>Service Code</th>
                                                        <th>Remark</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <style>
                                                        {`.hyperlink:hover {color: blue;}`}
                                                    </style>
                                                    {currentItems.map((service, index) => (
                                                        <tr key={index}>
                                                            <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleServiceDetails(service)}>{service.name}</td>
                                                            <td>{service.code}</td>
                                                            <td>{service.remark || "-"}</td>
                                                            <td>
                                                                <div className="btn-group">
                                                                    <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu actionmenu" x-placement="bottom-start">
                                                                        {/* <a className="dropdown-item" href="#" onClick={() => handleServiceDetails(service)}><i className="fa fa-file"></i> Detail</a>
                                                                    <a className="dropdown-item" href="#" onClick={() => handleEditServiceClick(service)}><i className="fas fa-edit"></i> Edit</a> */}
                                                                        <a className="dropdown-item" href="#" onClick={() => handleDelete(service)}><i className="fa fa-trash"></i> Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(services.length / itemsPerPage) }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(services.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showServiceDetails && selectedService && (
                        <ServiceDesc
                            service={selectedService}
                            onClose={handleBackToTable}
                        />
                    )}
                    {selectedService && !showServiceDetails && (
                        <EditService service={selectedService} onClose={handleEditServiceClose} onUpdate={handleUpdateService} />
                    )}
                    {isAddServiceModalOpen && <AddServiceProductModal onClose={handleCloseServiceModal} onUpdateServices={handleUpdateServices} />}
                </div>
            </div>
        </div>
    );
}

export default Servicelist;
