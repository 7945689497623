// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import AddInquiry from "./AddInquiry";
// import InquiryDesc from "./InquiryDesc";
// import EditInquiry from './NextInquiry';
// import DeleteConfirmationModal from "../DeleteConfirmationModal";
// import SearchBar from "../../components/sidebar/SearchBar";
// import Sidebar from "../../components/sidebar/Sidebar";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { debounce } from "lodash"; // Import debounce function

// function InquiryList({ handleLogout, username }) {
//     const [inquiries, setInquiries] = useState([]);
//     const [selectedInquiry, setSelectedInquiry] = useState(null);
//     const [showInquiryDetails, setShowInquiryDetails] = useState(false);
//     const [isAddInquiryModalOpen, setIsAddInquiryModalOpen] = useState(false);
//     const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//     const [editInquiry, setEditInquiry] = useState(null);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage, setItemsPerPage] = useState(25);
//     const [deleteInquiry, setDeleteInquiry] = useState(null);
//     const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
//     const [deleteReason, setDeleteReason] = useState("");
//     const [searchTerm, setSearchTerm] = useState(""); // search term

//     useEffect(() => {
//         fetchInquiries();
//     }, []);

//     const fetchInquiries = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/inquiries`);
//             const sortedInquiries = response.data.filter(item => item.status === "neutral");
//             setInquiries(sortedInquiries);
//         } catch (error) {
//             console.error("Error fetching inquiries:", error);
//         }
//     };

//     const handleAddInquiry = () => {
//         setIsAddInquiryModalOpen(true);
//     };

//     const handleCloseInquiryModal = () => {
//         setIsAddInquiryModalOpen(false);
//         setIsEditModalOpen(false);
//     };

//     const handleInquiryDetails = (inquiry) => {
//         setSelectedInquiry(inquiry);
//         setShowInquiryDetails(true);
//     };

//     const handleEditInquiryClick = (inquiry) => {
//         setEditInquiry(inquiry);
//         setSelectedInquiry(inquiry);
//         setIsEditModalOpen(true);
//     };

//     const handleEditInquiryClose = () => {
//         setSelectedInquiry(null);
//     };

//     const handleBackToTable = () => {
//         setSelectedInquiry(null);
//         setShowInquiryDetails(false);
//     };

//     const handleDeleteInquiry = (inquiry) => {
//         setDeleteInquiry(inquiry);
//         setIsDeleteModalOpen(true);
//     };

//     const handleUpdateInquiry = async (updatedInquiry) => {
//         try {
//             const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/inquiries/${updatedInquiry.id}`, updatedInquiry);
//             console.log("Inquiry updated:", response.data);
//             const updatedInquiryList = inquiries.map(inq => (inq.id === updatedInquiry.id ? response.data : inq));
//             const sortedInquiries = updatedInquiryList.sort((a, b) => b.id - a.id);
//             setInquiries(sortedInquiries);
//         } catch (error) {
//             console.error("Error updating inquiry:", error);
//         }
//     };

//     const handleDeleteConfirmation = async () => {
//         try {
//             await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/inquiries/${deleteInquiry.id}`);

//             const deletedInquiry = { ...deleteInquiry, reason: deleteReason };
//             await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedInquiry);

//             setInquiries((prevInquiries) =>
//                 prevInquiries.filter((inq) => inq.id !== deleteInquiry.id)
//             );
//             setIsDeleteModalOpen(false);

//             console.log("Inquiry deleted successfully");
//         } catch (error) {
//             console.error("Error deleting inquiry:", error);
//         }
//     };

//     const handleUpdateInquiryList = async (newInquiry) => {
//         try {
//             const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/inquiries`, newInquiry);
//             const newInquiryEntry = response.data;
//             setInquiries((prevInquiries) => [newInquiryEntry, ...prevInquiries]);
//             toast.success("Inquiry entry added successfully");
//         } catch (error) {
//             console.error("Error adding inquiry:", error);
//         }
//     };
//     const formatDate = (dateString) => {
//         return new Date(dateString).toLocaleDateString('en-US', {
//             year: 'numeric',
//             month: '2-digit',
//             day: '2-digit'
//         });
//     };

//     const onUpdateinquery = () => {
//         toast.success("Data Uploaded Successfully")
//         fetchInquiries();
//     }
//     // Search 
//     const handleSearchChange = debounce((event) => {
//         setSearchTerm(event.target.value);
//       }, 300);

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems =  inquiries.filter(inquiry => inquiry.customerName.toLowerCase().includes(searchTerm.toLowerCase())).slice(indexOfFirstItem, indexOfLastItem);
   

//     const paginate = (pageNumber) => setCurrentPage(pageNumber);

//     return (
//         <div className='d-flex w-100 h-100'>
//             <Sidebar />
//             <div className='w-100'>
//                 <SearchBar username={username} handleLogout={handleLogout} />
//                 <div className="container-fluid">
//                     <ToastContainer />
//                     {!showInquiryDetails && (
//                         <div className="row">
//                             <div className="col-xl-12">
//                                 <div className="card shadow mb-4">
//                                     <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                                         <h6 className="m-0 font-weight-bold text-primary">
//                                             Inquiry List
//                                         </h6>
//                                         <div className="d-flex gap-2">
//                                             <div className="input-group " style={{ width: '50%' }}>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control bg-gray-300 border-0 small"
//                                                     placeholder="Search for clients..."
//                                                     aria-label="Search"
//                                                     aria-describedby="basic-addon2"
//                                                     onChange={handleSearchChange}
//                                                 />
//                                                 <div className="input-group-append">
//                                                     <button className="btn btn-primary" type="button">
//                                                         <i className="fas fa-search fa-sm"></i>
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                             <button onClick={(e) => window.print()} className="btn btn-outline-success">
//                                                 <i className="fa fa-download"></i>
//                                             </button>
//                                             <button onClick={handleAddInquiry} className="btn btn-outline-primary">
//                                                 Add New Inquiry
//                                             </button>
//                                         </div>
//                                     </div>
//                                     <div className="card-body" >
//                                         <div style={{ maxHeight: "450px", overflowY: "auto" }}>
//                                             <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                                                 <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
//                                                     <tr>
//                                                         <th>Customer Name</th>
//                                                         <th>Call Date</th>
//                                                         <th>Mobile No</th>
//                                                         <th>Executive Name</th>
//                                                         <th>Amount</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     <style>
//                                                         {`.hyperlink:hover {color: blue;}`}
//                                                     </style>
//                                                     {currentItems.map((inquiry, index) => (
//                                                         <tr key={index}>
//                                                             <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleInquiryDetails(inquiry)}>{inquiry.customerName}</td>
//                                                             <td>{formatDate(inquiry.callDate)}</td>
//                                                             <td>{inquiry.mobileNo}</td>
//                                                             <td>{inquiry.callAttendExecutiveName}</td>
//                                                             <td>{inquiry.amount}</td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </table>
//                                         </div>

//                                         <ul className="pagination">
//                                             <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
//                                                 <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
//                                             </li>
//                                             {Array.from({ length: Math.ceil(inquiries.length / itemsPerPage) }, (_, i) => (
//                                                 <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
//                                                     <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
//                                                 </li>
//                                             ))}
//                                             <li className={`page-item ${currentPage === Math.ceil(inquiries.length / itemsPerPage) && 'disabled'}`}>
//                                                 <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
//                                             </li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                     {showInquiryDetails && selectedInquiry && (
//                         <InquiryDesc
//                             inquiry={selectedInquiry}
//                             onClose={handleBackToTable}
//                         />
//                     )}
//                     {selectedInquiry && !showInquiryDetails && (
//                         <EditInquiry inquiry={selectedInquiry} onClose={handleEditInquiryClose} onUpdate={handleUpdateInquiry} />
//                     )}
//                     {isAddInquiryModalOpen && <AddInquiry onClose={handleCloseInquiryModal} onUpdate={onUpdateinquery} />}
//                     <DeleteConfirmationModal
//                         isOpen={isDeleteModalOpen}
//                         itemName={deleteInquiry ? deleteInquiry.clientName : ""}
//                         onDelete={handleDeleteConfirmation}
//                         onClose={() => setIsDeleteModalOpen(false)}
//                         deleteReason={deleteReason}
//                         setDeleteReason={setDeleteReason}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default InquiryList;



import React, { useState, useEffect } from "react";
import axios from "axios";
import AddInquiry from "./AddInquiry";
import InquiryDesc from "./InquiryDesc";
import EditInquiry from './NextInquiry';
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { debounce } from "lodash"; // Import debounce function

function InquiryList({ handleLogout, username }) {
    const [inquiries, setInquiries] = useState([]);
    const [employees, setEmployees] = useState([]); // New state for employees
    const [selectedInquiry, setSelectedInquiry] = useState(null);
    const [showInquiryDetails, setShowInquiryDetails] = useState(false);
    const [isAddInquiryModalOpen, setIsAddInquiryModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editInquiry, setEditInquiry] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteInquiry, setDeleteInquiry] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");
    const [searchTerm, setSearchTerm] = useState(""); // search term
    const [selectedEmployee, setSelectedEmployee] = useState(""); // selected employee

    useEffect(() => {
        fetchInquiries();
        fetchEmployees(); // Fetch employees when component mounts
    }, []);

    const fetchInquiries = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/inquiries`);
            const sortedInquiries = response.data.filter(item => item.status === "neutral");
            setInquiries(sortedInquiries);
        } catch (error) {
            console.error("Error fetching inquiries:", error);
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            setEmployees(response.data);
        } catch (error) {
            console.error("Error fetching employees:", error);
        }
    };

    const handleAddInquiry = () => {
        setIsAddInquiryModalOpen(true);
    };

    const handleCloseInquiryModal = () => {
        setIsAddInquiryModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleInquiryDetails = (inquiry) => {
        setSelectedInquiry(inquiry);
        setShowInquiryDetails(true);
    };

    const handleEditInquiryClick = (inquiry) => {
        setEditInquiry(inquiry);
        setSelectedInquiry(inquiry);
        setIsEditModalOpen(true);
    };

    const handleEditInquiryClose = () => {
        setSelectedInquiry(null);
    };

    const handleBackToTable = () => {
        setSelectedInquiry(null);
        setShowInquiryDetails(false);
    };

    const handleDeleteInquiry = (inquiry) => {
        setDeleteInquiry(inquiry);
        setIsDeleteModalOpen(true);
    };

    const handleUpdateInquiry = async (updatedInquiry) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/inquiries/${updatedInquiry.id}`, updatedInquiry);
            console.log("Inquiry updated:", response.data);
            const updatedInquiryList = inquiries.map(inq => (inq.id === updatedInquiry.id ? response.data : inq));
            const sortedInquiries = updatedInquiryList.sort((a, b) => b.id - a.id);
            setInquiries(sortedInquiries);
        } catch (error) {
            console.error("Error updating inquiry:", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/inquiries/${deleteInquiry.id}`);

            const deletedInquiry = { ...deleteInquiry, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedInquiry);

            setInquiries((prevInquiries) =>
                prevInquiries.filter((inq) => inq.id !== deleteInquiry.id)
            );
            setIsDeleteModalOpen(false);

            console.log("Inquiry deleted successfully");
        } catch (error) {
            console.error("Error deleting inquiry:", error);
        }
    };

    const handleUpdateInquiryList = async (newInquiry) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/inquiries`, newInquiry);
            const newInquiryEntry = response.data;
            setInquiries((prevInquiries) => [newInquiryEntry, ...prevInquiries]);
            toast.success("Inquiry entry added successfully");
        } catch (error) {
            console.error("Error adding inquiry:", error);
        }
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    const onUpdateinquery = () => {
        toast.success("Data Uploaded Successfully")
        fetchInquiries();
    };

    // Search 
    const handleSearchChange = debounce((event) => {
        setSearchTerm(event.target.value);
    }, 300);

    // Filter inquiries based on the search term and selected employee
    const filteredInquiries = inquiries.filter(inquiry => 
        inquiry.customerName.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedEmployee ? inquiry.callAttendExecutiveName === selectedEmployee : true)
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredInquiries.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className='d-flex w-100 h-100'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {!showInquiryDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            Inquiry List
                                        </h6>
                                        <div className="d-flex gap-2">
                                        <label htmlFor="" className="m-0 pt-1 font-weight-bold text-black">Filter:- </label>
                                            <select 
                                                className="form-control"
                                                value={selectedEmployee}
                                                onChange={(e) => setSelectedEmployee(e.target.value)}
                                                style={{ width: '25%' }}
                                            >
                                                <option value="">Executive Names</option>
                                                {employees.map((employee) => (
                                                    <option key={employee.id} value={employee.ename}>
                                                        {employee.ename}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className="input-group " style={{ width: '50%' }}>
                                                <input
                                                    type="text"
                                                    className="form-control bg-gray-300 border-0 small"
                                                    placeholder="Search for clients..."
                                                    aria-label="Search"
                                                    aria-describedby="basic-addon2"
                                                    onChange={handleSearchChange}
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="button">
                                                        <i className="fas fa-search fa-sm"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <button onClick={(e) => window.print()} className="btn btn-outline-success">
                                                <i className="fa fa-download"></i>
                                            </button>
                                            <button onClick={handleAddInquiry} style={{width:"12vw"}}  className="btn btn-outline-primary">
                                                Add New Inquiry
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body" >
                                        <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Customer Name</th>
                                                        <th>Call Date</th>
                                                        <th>Mobile No</th>
                                                        <th>Executive Name</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <style>
                                                        {`.hyperlink:hover {color: blue;}`}
                                                    </style>
                                                    {currentItems.map((inquiry, index) => (
                                                        <tr key={index}>
                                                            <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleInquiryDetails(inquiry)}>{inquiry.customerName}</td>
                                                            <td>{formatDate(inquiry.callDate)}</td>
                                                            <td>{inquiry.mobileNo}</td>
                                                            <td>{inquiry.callAttendExecutiveName}</td>
                                                            <td>{inquiry.amount}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(filteredInquiries.length / itemsPerPage) }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(filteredInquiries.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showInquiryDetails && selectedInquiry && (
                        <InquiryDesc
                            inquiry={selectedInquiry}
                            onClose={handleBackToTable}
                        />
                    )}
                    {selectedInquiry && !showInquiryDetails && (
                        <EditInquiry inquiry={selectedInquiry} onClose={handleEditInquiryClose} onUpdate={handleUpdateInquiry} />
                    )}
                    {isAddInquiryModalOpen && <AddInquiry onClose={handleCloseInquiryModal} onUpdate={onUpdateinquery} />}
                    <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        itemName={deleteInquiry ? deleteInquiry.clientName : ""}
                        onDelete={handleDeleteConfirmation}
                        onClose={() => setIsDeleteModalOpen(false)}
                        deleteReason={deleteReason}
                        setDeleteReason={setDeleteReason}
                    />
                </div>
            </div>
        </div>
    );
}

export default InquiryList;
