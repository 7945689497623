import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from 'react-select';

const AddInquiry = ({ onClose, onUpdate }) => {
    const [formData, setFormData] = useState({
        customerName: "",
        callDate: "",
        mobileNo: "",
        emailId: "",
        notListedProductService: "",
        callAttendExecutiveName: "",
        amount: "",
        description: "",
        nextCallDate: "",
        vendorCode: "", // Added vendorCode to formData
        username: localStorage.getItem('username'),
        status: "neutral",
    });

    const [services, setServices] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchServices();
        fetchEmployees();

    }, []);

    const fetchServices = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/service_product`);
            setServices(response.data);
        } catch (error) {
            console.error('Error fetching Services:', error);
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            setEmployees(response.data);
        } catch (error) {
            console.error("Error fetching Employees:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "callAttendExecutiveName") {
            const selectedEmployee = employees.find(employee => employee.ename === value);
            setFormData({
                ...formData,
                [name]: value,
                employee_id: selectedEmployee ? selectedEmployee.id : "",
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        };
    };

    const handleSupplierChange = (selectedOptions) => {
        setFormData({
            ...formData,
            selectedServices: selectedOptions.map(option => ({ id: option.value, name: option.label })),
        });
    };

    const validate = () => {
        let formErrors = {};
        if (!formData.customerName) formErrors.customerName = 'Customer Name is required';

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        try {
            const formDataToSend = new FormData();

            for (const key in formData) {
                if (formData.hasOwnProperty(key)) {
                    if (key.startsWith("upload") && formData[key]) {
                        formDataToSend.append(key, formData[key]);
                    } else if (key === "selectedServices") {
                        formDataToSend.append(key, JSON.stringify(formData[key]));
                    } else {
                        formDataToSend.append(key, formData[key]);
                    }
                }
            }

            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/inquirydata`, formDataToSend);
            console.log("Data uploaded successfully:", response.data);

            onClose();
            onUpdate();
        } catch (error) {
            console.error("Error uploading data:", error);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div
            id="addInquiry"
            className="modal fade show"
            role="dialog"
            style={{ display: "block", paddingRight: "17px" }}
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form
                        action=""
                        id="formAddInquiry"
                        autoComplete="off"
                        noValidate="novalidate"
                        onSubmit={handleSubmit}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Add Inquiry</h5>
                            <button type="button" className="close" onClick={handleClose}>
                                &times;
                            </button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Customer Name <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="customerName"
                                                type="text"
                                                className={`form-control ${errors.customerName ? 'is-invalid' : ''}`}
                                                value={formData.customerName}
                                                onChange={handleChange}
                                                required
                                                placeholder="Customer Name"
                                            />
                                            {errors.customerName && <small className="text-danger">{errors.customerName}</small>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Call Date <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="callDate"
                                                type="date"
                                                className="form-control"
                                                value={formData.callDate}
                                                onChange={handleChange}
                                                required
                                                placeholder="Call Date"
                                            />
                                           
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Mobile Number </label>
                                            <input
                                                name="mobileNo"
                                                type="number"
                                                className="form-control"
                                                value={formData.mobileNo}
                                                onChange={handleChange}
                                                required
                                                placeholder="Mobile Number"
                                            />

                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Email Id</label>
                                            <input
                                                name="emailId"
                                                type="email"
                                                className="form-control"
                                                value={formData.emailId}
                                                onChange={handleChange}
                                                required
                                                placeholder="Email Id"
                                            />

                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Supplier of Service/Product </label>
                                            <Select
                                                isMulti
                                                options={services.map(service => ({ value: service.id, label: service.name }))}
                                                onChange={handleSupplierChange}
                                            />

                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Not Listed Product/Service</label>
                                            <input
                                                name="notListedProductService"
                                                type="text"
                                                className="form-control"
                                                value={formData.notListedProductService}
                                                onChange={handleChange}
                                                placeholder="Not Listed Product/Service"
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Call Attend Executive Name <span style={{ color: "red" }}>*</span></label>
                                            <select
                                                name="callAttendExecutiveName"
                                                id="callAttendExecutiveName"
                                                className="form-control"
                                                value={formData.callAttendExecutiveName}
                                                onChange={handleChange}
                                                required
                                                placeholder="Call Attend Executive Name"
                                            >
                                                <option value="">Select</option>
                                                {employees.map((employee) => (
                                                    <option key={employee.id} value={employee.ename}>
                                                        {employee.ename}
                                                    </option>
                                                ))}
                                            </select>
                                            
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Amount </label>
                                            <input
                                                name="amount"
                                                type="number"
                                                className="form-control"
                                                value={formData.amount}
                                                onChange={handleChange}
                                                required
                                                placeholder="Amount"
                                            />

                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>Next Call Date </label>
                                            <input
                                                name="nextCallDate"
                                                type="date"
                                                className="form-control"
                                                value={formData.nextCallDate}
                                                onChange={handleChange}
                                                required
                                                placeholder="Next Call Date"
                                            />

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="form-group">
                                <label>Description</label>
                                <textarea
                                    name="description"
                                    className="form-control"
                                    rows="5"
                                    value={formData.description}
                                    onChange={handleChange}
                                    placeholder="Description"
                                ></textarea>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" id="save">
                                Save
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddInquiry;

