// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import AddSales from "./AddSales";
// import SalesDesc from "./SalesDesc";
// import EditSales from './EditSales';
// import DeleteConfirmationModal from "../DeleteConfirmationModal";
// import SearchBar from "../../components/sidebar/SearchBar";
// import Sidebar from "../../components/sidebar/Sidebar";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function SalesList({ handleLogout, username }) {
//     const [sales, setSales] = useState([]);
//     const [selectedSales, setSelectedSales] = useState(null);
//     const [showSalesDetails, setShowSalesDetails] = useState(false);
//     const [isAddSalesModalOpen, setIsAddSalesModalOpen] = useState(false);
//     const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//     const [editSales, setEditSales] = useState(null);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage, setItemsPerPage] = useState(25);
//     const [deleteSales, setDeleteSales] = useState(null);
//     const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
//     const [deleteReason, setDeleteReason] = useState("");

//     useEffect(() => {
//         fetchSales();
//     }, []);

//     const fetchSales = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/sales`);
//             const sortedSales = response.data.sort((a, b) => b.id - a.id);
//             setSales(sortedSales);
//         } catch (error) {
//             console.error("Error fetching sales:", error);
//         }
//     };

//     const handleAddSales = () => {
//         setIsAddSalesModalOpen(true);
//     };

//     const handleCloseSalesModal = () => {
//         setIsAddSalesModalOpen(false);
//         setIsEditModalOpen(false);
//     };

//     const handleSalesDetails = (sales) => {
//         setSelectedSales(sales);
//         setShowSalesDetails(true);
//     };

//     const handleEditSalesClick = (sales) => {
//         setEditSales(sales);
//         setSelectedSales(sales);
//         setIsEditModalOpen(true);
//     };

//     const handleEditSalesClose = () => {
//         setSelectedSales(null);
//     };

//     const handleBackToTable = () => {
//         setSelectedSales(null);
//         setShowSalesDetails(false);
//     };

//     const handleDeleteSales = (sales) => {
//         setDeleteSales(sales);
//         setIsDeleteModalOpen(true);
//     };


//     const handleDeleteConfirmation = async () => {
//         try {
//             await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/sales/${deleteSales.id}`);

//             const deletedSales = { ...deleteSales, reason: deleteReason };
//             await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedSales);

//             setSales((prevSales) =>
//                 prevSales.filter((sale) => sale.id !== deleteSales.id)
//             );
//             setIsDeleteModalOpen(false);

//             console.log("Sales deleted successfully");
//         } catch (error) {
//             console.error("Error deleting sales:", error);
//         }
//     };

//     const handleUpdateSalesList = () => {
//         fetchSales();
//         toast.success("Data saved Successfully")
//     };

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = sales.slice(indexOfFirstItem, indexOfLastItem);

//     const paginate = (pageNumber) => setCurrentPage(pageNumber);

//     return (
//         <div className='d-flex w-100 h-100'>
//             <Sidebar />
//             <div className='w-100'>
//                 <SearchBar username={username} handleLogout={handleLogout} />
//                 <div className="container-fluid">
//                     <ToastContainer />
//                     {!showSalesDetails && (
//                         <div className="row">
//                             <div className="col-xl-12">
//                                 <div className="card shadow mb-4">
//                                     <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                                         <h6 className="m-0 font-weight-bold text-primary">
//                                             Sales List
//                                         </h6>
//                                         <div className="d-flex gap-2">
//                                             <button onClick={(e) => window.print()} className="btn btn-outline-success">
//                                                 <i className="fa fa-download"></i>
//                                             </button>
//                                             <button onClick={handleAddSales} className="btn btn-outline-primary">
//                                                 Add New Sales
//                                             </button>
//                                         </div>
//                                     </div>
//                                     <div className="card-body" >
//                                         <div style={{ maxHeight: "450px", overflowY: "auto" }}>
//                                             <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                                                 <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
//                                                     <tr>

//                                                         <th>Client Name</th>
//                                                         <th>Client Code</th>
//                                                         <th>Selling Company </th>
//                                                         <th>Sales Date</th>
//                                                         <th>Sales Amount</th>
//                                                         <th>Action</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     <style>
//                                                         {`.hyperlink:hover {color: blue;}`}
//                                                     </style>
//                                                     {currentItems.map((sale, index) => (
//                                                         <tr key={index}>
//                                                             <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleSalesDetails(sale)}>{sale.clientName}</td>
//                                                             <td>{sale.clientCode}</td>
//                                                             <td>{sale.companyName}</td>
//                                                             <td>{new Date(sale.salesDate).toLocaleDateString()}</td>
//                                                             <td>{sale.totalSalesAmount}</td>
//                                                             <td>
//                                                                 <div className="btn-group">
//                                                                     <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                                                                         <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
//                                                                     </button>
//                                                                     <div className="dropdown-menu actionmenu" x-placement="bottom-start">
//                                                                         {/* <a className="dropdown-item" href="#" onClick={() => handleSalesDetails(sale)}><i className="fa fa-file"></i> Detail</a> */}
//                                                                         <a className="dropdown-item" href="#" onClick={() => handleEditSalesClick(sale)}><i className="fas fa-edit"></i> Edit</a>
//                                                                         {/* <a className="dropdown-item" href="#" onClick={() => handleDeleteSales(sale)}><i className="fa fa-trash"></i> Delete</a> */}
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </table>
//                                         </div>

//                                         <ul className="pagination">
//                                             <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
//                                                 <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
//                                             </li>
//                                             {Array.from({ length: Math.ceil(sales.length / itemsPerPage) }, (_, i) => (
//                                                 <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
//                                                     <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
//                                                 </li>
//                                             ))}
//                                             <li className={`page-item ${currentPage === Math.ceil(sales.length / itemsPerPage) && 'disabled'}`}>
//                                                 <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
//                                             </li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                     {showSalesDetails && selectedSales && (
//                         <SalesDesc
//                             sales={selectedSales}
//                             onClose={handleBackToTable}
//                         />
//                     )}
//                     {selectedSales && !showSalesDetails && (
//                         <EditSales sales={selectedSales} onClose={handleEditSalesClose} onUpdate={handleUpdateSalesList} />
//                     )}
//                     {isAddSalesModalOpen && <AddSales onClose={handleCloseSalesModal} onUpdate={handleUpdateSalesList} />}
//                     <DeleteConfirmationModal
//                         isOpen={isDeleteModalOpen}
//                         itemName={deleteSales ? deleteSales.companyName : ""}
//                         onDelete={handleDeleteConfirmation}
//                         onClose={() => setIsDeleteModalOpen(false)}
//                         deleteReason={deleteReason}
//                         setDeleteReason={setDeleteReason}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default SalesList;




// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import AddSales from "./AddSales";
// import SalesDesc from "./SalesDesc";
// import EditSales from './EditSales';
// import DeleteConfirmationModal from "../DeleteConfirmationModal";
// import SearchBar from "../../components/sidebar/SearchBar";
// import Sidebar from "../../components/sidebar/Sidebar";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// function SalesList({ handleLogout, username }) {
//     const [sales, setSales] = useState([]);
//     const [filteredSales, setFilteredSales] = useState([]);
//     const [selectedSales, setSelectedSales] = useState(null);
//     const [showSalesDetails, setShowSalesDetails] = useState(false);
//     const [isAddSalesModalOpen, setIsAddSalesModalOpen] = useState(false);
//     const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//     const [editSales, setEditSales] = useState(null);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage, setItemsPerPage] = useState(25);
//     const [deleteSales, setDeleteSales] = useState(null);
//     const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
//     const [deleteReason, setDeleteReason] = useState("");
//     const [startDate, setStartDate] = useState(null);
//     const [endDate, setEndDate] = useState(null);

//     useEffect(() => {
//         fetchSales();
//     }, []);

//     useEffect(() => {
//         filterSalesByDate();
//     }, [sales, startDate, endDate]);

//     const fetchSales = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/sales`);
//             const sortedSales = response.data.sort((a, b) => b.id - a.id);
//             setSales(sortedSales);
//         } catch (error) {
//             console.error("Error fetching sales:", error);
//         }
//     };

//     const filterSalesByDate = () => {
//         if (startDate && endDate) {
//             const filtered = sales.filter(sale => {
//                 const saleDate = new Date(sale.salesDate);
//                 return saleDate >= startDate && saleDate <= endDate;
//             });
//             setFilteredSales(filtered);
//         } else {
//             setFilteredSales(sales);
//         }
//     };

//     const handleAddSales = () => {
//         setIsAddSalesModalOpen(true);
//     };

//     const handleCloseSalesModal = () => {
//         setIsAddSalesModalOpen(false);
//         setIsEditModalOpen(false);
//     };

//     const handleSalesDetails = (sales) => {
//         setSelectedSales(sales);
//         setShowSalesDetails(true);
//     };

//     const handleEditSalesClick = (sales) => {
//         setEditSales(sales);
//         setSelectedSales(sales);
//         setIsEditModalOpen(true);
//     };

//     const handleEditSalesClose = () => {
//         setSelectedSales(null);
//     };

//     const handleBackToTable = () => {
//         setSelectedSales(null);
//         setShowSalesDetails(false);
//     };

//     const handleDeleteSales = (sales) => {
//         setDeleteSales(sales);
//         setIsDeleteModalOpen(true);
//     };

//     const handleDeleteConfirmation = async () => {
//         try {
//             await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/sales/${deleteSales.id}`);

//             const deletedSales = { ...deleteSales, reason: deleteReason };
//             await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedSales);

//             setSales((prevSales) =>
//                 prevSales.filter((sale) => sale.id !== deleteSales.id)
//             );
//             setIsDeleteModalOpen(false);

//             console.log("Sales deleted successfully");
//         } catch (error) {
//             console.error("Error deleting sales:", error);
//         }
//     };

//     const handleUpdateSalesList = () => {
//         fetchSales();
//         toast.success("Data saved Successfully")
//     };

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = filteredSales.slice(indexOfFirstItem, indexOfLastItem);

//     const paginate = (pageNumber) => setCurrentPage(pageNumber);

//     return (
//         <div className='d-flex w-100 h-100'>
//             <Sidebar />
//             <div className='w-100'>
//                 <SearchBar username={username} handleLogout={handleLogout} />
//                 <div className="container-fluid">
//                     <ToastContainer />
//                     {!showSalesDetails && (
//                         <div className="row">
//                             <div className="col-xl-12">
//                                 <div className="card shadow mb-4">
//                                     <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                                         <h6 className="m-0 font-weight-bold text-primary">
//                                             Sales List
//                                         </h6>
//                                         <div className="d-flex align-items-center justify-content-center gap-2">
//                                             <label htmlFor="" className="m-0 font-weight-bold text-black">Filter :- </label>
//                                             <DatePicker
//                                                 selected={startDate}
//                                                 onChange={(date) => setStartDate(date)}
//                                                 selectsStart
//                                                 startDate={startDate}
//                                                 endDate={endDate}
//                                                 placeholderText="Start Date"
//                                                 className="form-control datepicker"
//                                             />
//                                             <DatePicker
//                                                 selected={endDate}
//                                                 onChange={(date) => setEndDate(date)}
//                                                 selectsEnd
//                                                 startDate={startDate}
//                                                 endDate={endDate}
//                                                 minDate={startDate}
//                                                 placeholderText="End Date"
//                                                 className="form-control datepicker"
//                                             />
//                                             <button onClick={(e) => window.print()} className="btn btn-outline-success">
//                                                 <i className="fa fa-download"></i>
//                                             </button>
//                                             <button onClick={handleAddSales} className="btn btn-outline-primary">
//                                                 Add New Sales
//                                             </button>
//                                         </div>
//                                     </div>
//                                     <div className="card-body" >
//                                         <div style={{ maxHeight: "450px", overflowY: "auto" }}>
//                                             <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                                                 <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
//                                                     <tr>
//                                                         <th>Client Name</th>
//                                                         <th>Client Code</th>
//                                                         <th>Selling Company </th>
//                                                         <th>Sales Date</th>
//                                                         <th>Sales Amount</th>
//                                                         <th>Action</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     <style>
//                                                         {`.hyperlink:hover {color: blue;}`}
//                                                     </style>
//                                                     {currentItems.map((sale, index) => (
//                                                         <tr key={index}>
//                                                             <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleSalesDetails(sale)}>{sale.clientName}</td>
//                                                             <td>{sale.clientCode}</td>
//                                                             <td>{sale.companyName}</td>
//                                                             <td>{new Date(sale.salesDate).toLocaleDateString()}</td>
//                                                             <td>{sale.totalSalesAmount}</td>
//                                                             <td>
//                                                                 <div className="btn-group">
//                                                                     <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                                                                         <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
//                                                                     </button>
//                                                                     <div className="dropdown-menu actionmenu" x-placement="bottom-start">
//                                                                         <a className="dropdown-item" href="#" onClick={() => handleEditSalesClick(sale)}><i className="fas fa-edit"></i> Edit</a>
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </table>
//                                         </div>

//                                         <ul className="pagination">
//                                             <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
//                                                 <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
//                                             </li>
//                                             {Array.from({ length: Math.ceil(filteredSales.length / itemsPerPage) }, (_, i) => (
//                                                 <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
//                                                     <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
//                                                 </li>
//                                             ))}
//                                             <li className={`page-item ${currentPage === Math.ceil(filteredSales.length / itemsPerPage) && 'disabled'}`}>
//                                                 <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
//                                             </li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                     {showSalesDetails && selectedSales && (
//                         <SalesDesc
//                             sales={selectedSales}
//                             onClose={handleBackToTable}
//                         />
//                     )}
//                     {selectedSales && !showSalesDetails && (
//                         <EditSales sales={selectedSales} onClose={handleEditSalesClose} onUpdate={handleUpdateSalesList} />
//                     )}
//                     {isAddSalesModalOpen && <AddSales onClose={handleCloseSalesModal} onUpdate={handleUpdateSalesList} />}
//                     <DeleteConfirmationModal
//                         isOpen={isDeleteModalOpen}
//                         itemName={deleteSales ? deleteSales.companyName : ""}
//                         onDelete={handleDeleteConfirmation}
//                         onClose={() => setIsDeleteModalOpen(false)}
//                         deleteReason={deleteReason}
//                         setDeleteReason={setDeleteReason}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default SalesList;


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import AddSales from "./AddSales";
// import SalesDesc from "./SalesDesc";
// import EditSales from './EditSales';
// import DeleteConfirmationModal from "../DeleteConfirmationModal";
// import SearchBar from "../../components/sidebar/SearchBar";
// import Sidebar from "../../components/sidebar/Sidebar";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function SalesList({ handleLogout, username }) {
//     const [sales, setSales] = useState([]);
//     const [filteredSales, setFilteredSales] = useState([]);
//     const [selectedSales, setSelectedSales] = useState(null);
//     const [showSalesDetails, setShowSalesDetails] = useState(false);
//     const [isAddSalesModalOpen, setIsAddSalesModalOpen] = useState(false);
//     const [isEditModalOpen, setIsEditModalOpen] = useState(false);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage, setItemsPerPage] = useState(25);
//     const [deleteSales, setDeleteSales] = useState(null);
//     const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
//     const [deleteReason, setDeleteReason] = useState("");
//     const [startDate, setStartDate] = useState(null);
//     const [endDate, setEndDate] = useState(null);

//     useEffect(() => {
//         fetchSales();
//     }, []);

//     useEffect(() => {
//         filterSalesByDate();
//     }, [sales, startDate, endDate]);

//     const fetchSales = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/sales`);
//             const sortedSales = response.data.sort((a, b) => b.id - a.id);
//             setSales(sortedSales);
//         } catch (error) {
//             console.error("Error fetching sales:", error);
//         }
//     };

//     const filterSalesByDate = () => {
//         if (startDate && endDate) {
//             const filtered = sales.filter(sale => {
//                 const saleDate = new Date(sale.salesDate);
//                 const start = new Date(startDate);
//                 const end = new Date(endDate);
//                 return saleDate >= start && saleDate <= end;
//             });
//             setFilteredSales(filtered);
//         } else {
//             setFilteredSales(sales);
//         }
//     };

//     const handleAddSales = () => {
//         setIsAddSalesModalOpen(true);
//     };

//     const handleCloseSalesModal = () => {
//         setIsAddSalesModalOpen(false);
//         setIsEditModalOpen(false);
//     };

//     const handleSalesDetails = (sales) => {
//         setSelectedSales(sales);
//         setShowSalesDetails(true);
//     };

//     const handleEditSalesClick = (sales) => {
//         setSelectedSales(sales);
//         setIsEditModalOpen(true);
//     };

//     const handleEditSalesClose = () => {
//         setSelectedSales(null);
//     };

//     const handleBackToTable = () => {
//         setSelectedSales(null);
//         setShowSalesDetails(false);
//     };

//     const handleDeleteSales = (sales) => {
//         setDeleteSales(sales);
//         setIsDeleteModalOpen(true);
//     };

//     const handleDeleteConfirmation = async () => {
//         try {
//             await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/sales/${deleteSales.id}`);

//             const deletedSales = { ...deleteSales, reason: deleteReason };
//             await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedSales);

//             setSales(prevSales => prevSales.filter(sale => sale.id !== deleteSales.id));
//             setIsDeleteModalOpen(false);

//             toast.success("Sales deleted successfully");
//         } catch (error) {
//             console.error("Error deleting sales:", error);
//             toast.error("Error deleting sales");
//         }
//     };

//     const paginate = (pageNumber) => setCurrentPage(pageNumber);

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = filteredSales.slice(indexOfFirstItem, indexOfLastItem);

//     return (
//         <div className='d-flex w-100 h-100'>
//             <Sidebar />
//             <div className='w-100'>
//                 <SearchBar username={username} handleLogout={handleLogout} />
//                 <div className="container-fluid">
//                     <ToastContainer />
//                     {!showSalesDetails && (
//                         <div className="row">
//                             <div className="col-xl-12">
//                                 <div className="card shadow mb-4">
//                                     <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                                         <h6 className="m-0 font-weight-bold text-primary">
//                                             Sales List
//                                         </h6>
//                                         <div className="d-flex align-items-center justify-content-center gap-2" >
//                                             <label htmlFor="" className="m-0 font-weight-bold text-black">Filter:- </label>
//                                             <input
//                                                 type="date"
//                                                 value={startDate}
//                                                 onChange={(e) => setStartDate(e.target.value)}
//                                                 className="form-control datepicker"
//                                             />
//                                             <input
//                                                 type="date"
//                                                 value={endDate}
//                                                 onChange={(e) => setEndDate(e.target.value)}
//                                                 className="form-control datepicker"
//                                             />
//                                             <button onClick={() => window.print()} className="btn btn-outline-success">
//                                                 <i className="fa fa-download"></i>
//                                             </button>
//                                             <button onClick={handleAddSales} style={{width:"30vw"}} className=" btn btn-outline-primary">
//                                                 Add New Sale
//                                             </button>
//                                         </div>
//                                     </div>
//                                     <div className="card-body" >
//                                         <div style={{ maxHeight: "450px", overflowY: "auto" }}>
//                                             <table className="table table-striped table-bordered" style={{ width: "100%" }}>
//                                                 <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
//                                                     <tr>
//                                                         <th>Client Name</th>
//                                                         <th>Client Code</th>
//                                                         <th>Selling Company </th>
//                                                         <th>Sales Date</th>
//                                                         <th>Sales Amount</th>
//                                                         <th>Action</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     <style>
//                                                         {`.hyperlink:hover {color: blue;}`}
//                                                     </style>
//                                                     {currentItems.map((sale, index) => (
//                                                         <tr key={index}>
//                                                             <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleSalesDetails(sale)}>{sale.clientName}</td>
//                                                             <td>{sale.clientCode}</td>
//                                                             <td>{sale.companyName}</td>
//                                                             <td>{new Date(sale.salesDate).toLocaleDateString()}</td>
//                                                             <td>{sale.totalSalesAmount}</td>
//                                                             <td>
//                                                                 <div className="btn-group">
//                                                                     <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                                                                         <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
//                                                                     </button>
//                                                                     <div className="dropdown-menu actionmenu" x-placement="bottom-start">
//                                                                         <a className="dropdown-item" href="#" onClick={() => handleEditSalesClick(sale)}><i className="fas fa-edit"></i> Edit</a>
//                                                                         {/* <a className="dropdown-item" href="#" onClick={() => handleDeleteSales(sale)}><i className="fas fa-trash"></i> Delete</a> */}
//                                                                     </div>
//                                                                 </div>
//                                                             </td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </table>
//                                         </div>

//                                         <ul className="pagination">
//                                             <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
//                                                 <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
//                                             </li>
//                                             {Array.from({ length: Math.ceil(filteredSales.length / itemsPerPage) }, (_, i) => (
//                                                 <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
//                                                     <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
//                                                 </li>
//                                             ))}
//                                             <li className={`page-item ${currentPage === Math.ceil(filteredSales.length / itemsPerPage) && 'disabled'}`}>
//                                                 <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
//                                             </li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                     {showSalesDetails && selectedSales && (
//                         <SalesDesc
//                             sales={selectedSales}
//                             onClose={handleBackToTable}
//                         />
//                     )}
//                     {selectedSales && !showSalesDetails && (
//                         <EditSales sales={selectedSales} onClose={handleEditSalesClose} onUpdate={fetchSales} />
//                     )}
//                     {isAddSalesModalOpen && <AddSales onClose={handleCloseSalesModal} onUpdate={fetchSales} />}
//                     <DeleteConfirmationModal
//                         isOpen={isDeleteModalOpen}
//                         itemName={deleteSales ? deleteSales.companyName : ""}
//                         onDelete={handleDeleteConfirmation}
//                         onClose={() => setIsDeleteModalOpen(false)}
//                         deleteReason={deleteReason}
//                         setDeleteReason={setDeleteReason}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default SalesList;

// new 
import React, { useState, useEffect } from "react";
import axios from "axios";
import AddSales from "./AddSales";
import SalesDesc from "./SalesDesc";
import EditSales from './EditSales';
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SalesList({ handleLogout, username }) {
    const [sales, setSales] = useState([]);
    const [filteredSales, setFilteredSales] = useState([]);
    const [selectedSales, setSelectedSales] = useState(null);
    const [showSalesDetails, setShowSalesDetails] = useState(false);
    const [isAddSalesModalOpen, setIsAddSalesModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteSales, setDeleteSales] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        fetchSales();
    }, []);

    useEffect(() => {
        filterSalesByDate();
    }, [sales, startDate, endDate]);

    const fetchSales = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/sales`);
            const sortedSales = response.data.sort((a, b) => b.id - a.id);
            setSales(sortedSales);
        } catch (error) {
            console.error("Error fetching sales:", error);
        }
    };

    const filterSalesByDate = () => {
        if (startDate && endDate) {
            const filtered = sales.filter(sale => {
                const saleDate = new Date(sale.salesDate).setHours(0, 0, 0, 0);
                const start = new Date(startDate).setHours(0, 0, 0, 0);
                const end = new Date(endDate).setHours(0, 0, 0, 0);
                return saleDate >= start && saleDate <= end;
            });
            setFilteredSales(filtered);
        } else {
            setFilteredSales(sales);
        }
    };

    const handleAddSales = () => {
        setIsAddSalesModalOpen(true);
    };

    const handleCloseSalesModal = () => {
        setIsAddSalesModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleSalesDetails = (sales) => {
        setSelectedSales(sales);
        setShowSalesDetails(true);
    };

    const handleEditSalesClick = (sales) => {
        setSelectedSales(sales);
        setIsEditModalOpen(true);
    };

    const handleEditSalesClose = () => {
        setSelectedSales(null);
    };

    const handleBackToTable = () => {
        setSelectedSales(null);
        setShowSalesDetails(false);
    };

    const handleDeleteSales = (sales) => {
        setDeleteSales(sales);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteConfirmation = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/sales/${deleteSales.id}`);

            const deletedSales = { ...deleteSales, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedSales);

            setSales(prevSales => prevSales.filter(sale => sale.id !== deleteSales.id));
            setIsDeleteModalOpen(false);

            toast.success("Sales deleted successfully");
        } catch (error) {
            console.error("Error deleting sales:", error);
            toast.error("Error deleting sales");
        }
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSales.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className='d-flex w-100 h-100'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    {!showSalesDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            Sales List
                                        </h6>
                                        <div className="d-flex align-items-center justify-content-center gap-2" >
                                            <label htmlFor="" className="m-0 font-weight-bold text-black">Filter:- </label>
                                            <input
                                                type="date"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                className="form-control datepicker"
                                            />
                                            <input
                                                type="date"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                className="form-control datepicker"
                                            />
                                            <button onClick={() => window.print()} className="btn btn-outline-success">
                                                <i className="fa fa-download"></i>
                                            </button>
                                            <button onClick={handleAddSales} style={{width:"30vw"}} className=" btn btn-outline-primary">
                                                Add New Sale
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body" >
                                        <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Client Name</th>
                                                        <th>Client Code</th>
                                                        <th>Selling Company </th>
                                                        <th>Sales Date</th>
                                                        <th>Sales Amount</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <style>
                                                        {`.hyperlink:hover {color: blue;}`}
                                                    </style>
                                                    {currentItems.map((sale, index) => (
                                                        <tr key={index}>
                                                            <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleSalesDetails(sale)}>{sale.clientName}</td>
                                                            <td>{sale.clientCode}</td>
                                                            <td>{sale.companyName}</td>
                                                            <td>{new Date(sale.salesDate).toLocaleDateString()}</td>
                                                            <td>{sale.totalSalesAmount}</td>
                                                            <td>
                                                                <div className="btn-group">
                                                                    <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                    </button>
                                                                    <div className="dropdown-menu actionmenu" x-placement="bottom-start">
                                                                        <a className="dropdown-item" href="#" onClick={() => handleEditSalesClick(sale)}><i className="fas fa-edit"></i> Edit</a>
                                                                        <a className="dropdown-item" href="#" onClick={() => handleDeleteSales(sale)}><i className="fas fa-trash"></i> Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                            </li>
                                            {Array.from({ length: Math.ceil(filteredSales.length / itemsPerPage) }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(filteredSales.length / itemsPerPage) && 'disabled'}`}>
                                                <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showSalesDetails && selectedSales && (
                        <SalesDesc
                            sales={selectedSales}
                            onClose={handleBackToTable}
                        />
                    )}
                    {selectedSales && !showSalesDetails && (
                        <EditSales sales={selectedSales} onClose={handleEditSalesClose} onUpdate={fetchSales} />
                    )}
                    {isAddSalesModalOpen && <AddSales onClose={handleCloseSalesModal} onUpdate={fetchSales} />}
                    <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        itemName={deleteSales ? deleteSales.clientName : ""}
                        onDelete={handleDeleteConfirmation}
                        onClose={() => setIsDeleteModalOpen(false)}
                        deleteReason={deleteReason}
                        setDeleteReason={setDeleteReason}
                    />
                </div>
            </div>
        </div>
    );
}

export default SalesList;
